import api from './api';

const prefix = 'enrollment';

export const apiEnrollment = {
    getPrices: function (idCareer, idCAU, cauStrapiCode, idAttendanceMode, idCourseModeDB, idCourseMode, reference, coupon) {
        return api.get(`${prefix}`, {
            params: {
                idCareer,
                idCAU,
                cauStrapiCode,
                idAttendanceMode,
                idCourseModeDB,
                idCourseMode,
                reference,
                coupon
            }
        });
    },

    createEnrollment: function (enrollmentData) {
        return api.post(`${prefix}`, enrollmentData);
    },
    validateCoupon: function(params, body){
        return api.post(`${prefix}/reservation-coupon?careerName=${params.careerName}&cauId=${params.cauId}&coupon=${params.coupon}`,
            body
        );
    }
};
