import React from 'react';
import { Card, Input, Button, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { Checkout } from "../../../../i18n/locales/es/text";
import "./bonus-card.scss";

const { Text } = Typography;

export const BonusCard = ({action, activeButton, isLoading}) => {
  const { t } = useTranslation();
  const [ code, setCode ] = React.useState();
  const saveCode=()=>{
    action(code)
}
  return (
    <Card className={"bonusCard"}>
      <Row gutter={[32, 0]} justify="center" align="middle">
        <Col span={11}>
          <Col span={24}>
            <Text className={"title"}>
              {t(Checkout.bonusCard.referTitle)}
            </Text>
          </Col>
          <Col span={24}>
            <Text className={"subTitle"}>
              {t(Checkout.bonusCard.referSubtitle)}
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Row>
              <Input className={"input"} placeholder="Indicar Legajo" />
              <Button className={"applyButton"}>
                <Text>{t(Checkout.bonusCard.button)}</Text>
              </Button>
            </Row>
          </Col>
        </Col>
        <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              background: "#CED4DA",
              height: "120px",
              width: "1px",
            }}
          ></div>
        </Col>

        <Col span={11}>
          <Col span={24}>
            <Text className={"title"}>
              {t(Checkout.bonusCard.couponTitle)}
            </Text>
          </Col>
          <Col span={24}>
            <Text className={"subTitle"}>
              {t(Checkout.bonusCard.couponSubtitle)}
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Row>
              {/* DESACTIVATE COUPONS */}
              <Input className={"input"} placeholder="Indicar código" value={code} onChange={(e)=>setCode(e.target.value)}/>
              <Button className={"applyButton"} onClick={saveCode} disabled={!activeButton} loading={isLoading}>
                <Text>{t(Checkout.bonusCard.button)}</Text>
              </Button>
            </Row>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export const BonusCardMobile = ({action, activeButton, isLoading}) => {
  const { t } = useTranslation();
  const [ code, setCode ] = React.useState();
  const saveCode=()=>{
    action(code)
}
  return (
    <Card className={"bonusCard"}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Col className="title-container-coupon" span={24}>
            <Text className={"title"}>
              {t(Checkout.bonusCard.referTitle)}
            </Text>
          </Col>
          <Col span={24}>
            <Text className={"subTitle"}>
              {t(Checkout.bonusCard.referSubtitle)}
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Row>
              <Input className={"input"} placeholder="Indicar Legajo" />

              <Button
                style={{ marginBottom: "1em" }}
                className={"applyButton"}
              >
                <Text>{t(Checkout.bonusCard.button)}</Text>
              </Button>
            </Row>
          </Col>
        </Col>

        <Col span={24}>
          <Col className="title-container-coupon" span={24}>
            <Text className={"title"}>
              {t(Checkout.bonusCard.couponTitle)}
            </Text>
          </Col>
          <Col span={24}>
            <Text className={"subTitle"}>
              {t(Checkout.bonusCard.couponSubtitle)}
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Row>
              {/* DESACTIVATE COUPONS */}
              <Input className={"input"} placeholder="Indicar código" value={code} onChange={(e)=>setCode(e.target.value)}/>
              <Button className={"applyButton"} onClick={saveCode} disabled={!activeButton} loading={isLoading}>
                <Text>{t(Checkout.bonusCard.button)}</Text>
              </Button>
            </Row>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};
