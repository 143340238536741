import React from "react";
import { Col, Row } from "antd";
import "./index.header.scss";
import { useMediaQuery } from "react-responsive";

const HeaderComponent = (props) => {
  const isSimilSmartphone = useMediaQuery({
    query: "(min-width: 600px)",
  });
  return (
    <Row className={"background"}>
      <div
        style={{
          backgroundImage: `url(${props.imgBg})`,
          width: !isSimilSmartphone ? "1500px" : "100%",
          left: !isSimilSmartphone && `${props.left}`,
          right: !isSimilSmartphone && `${props.right}`,
          top: !isSimilSmartphone && `${props.top}`,
          bottom: !isSimilSmartphone && `${props.bottom}`,
        }}
        className={"backgroundOL"}
      ></div>
      <Col
        style={{ padding: !isSimilSmartphone && "2em" }}
        className={"titleContainer"}
      >
        {props.children}
      </Col>
    </Row>
  );
};

export default HeaderComponent;
