import React from "react";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { Courses } from "../../../../i18n/locales/es/text";

import style from "../../index.module.scss";
import "../../courses.scss";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

const CoursesOrder = ({ resultSetCount, loading }) => {
  const { t } = useTranslation();

  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const nameSearch = query.get("name");

  return (
    <Row className={style.resultsBar}>
      <Col>
        {loading ? (
          <Title level={4}>{t(Courses.loadingResources)}</Title>
        ) : (
          <Title level={4}>
            {resultSetCount} {t(Courses.resourcesFound)}
            {nameSearch ? ` con la palabra "${nameSearch}"` : null}
          </Title>
        )}
      </Col>
    </Row>
  );
};

export default React.memo(CoursesOrder);
