import { Suspense } from "react";
import { Spin } from "antd";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { LocaleProvider } from "antd";
import es_ES from "antd/lib/locale-provider/es_ES";
/* import "./App.scss"; */
import "./App.less";
import "antd/dist/antd.css";
import configureStore from "./redux/store";
import AppRouter from "./AppRouter";

import { AuthProvider } from "./common/auth/auth.context";

const store = configureStore();

function App() {
  
  return (
    <LocaleProvider locale={es_ES}>
      <AuthProvider>
        <Suspense fallback={<Spin />}>
          <Provider store={store}>
            <CookiesProvider>
              <AppRouter />
            </CookiesProvider>
          </Provider>
        </Suspense>
      </AuthProvider>
    </LocaleProvider>
  );
}

export default App;