import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faqsTexts } from "../../i18n/locales/es/text";
import { Row, Col, Typography } from "antd";
import style from "./Faqs.module.scss";
import HeaderComponent from "../../components/header";
import bg from "../../assets/faqsBg.png";
import { useMediaQuery } from "react-responsive";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Faqs } from "../../components/faqs/Faqs";

const { Title, Paragraph, Text } = Typography;

export default function FaqsSite() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const { t } = useTranslation();
  const history = useHistory();

  const goToHome = () => {
    history.push(`/`);
  };

  return (
    <div className={style.cartContainer}>
      <HeaderComponent imgBg={bg} right="-18em">
        <Title className={style.title}>{t(faqsTexts.faqsTitle)}</Title>
        <Paragraph className={style.map}>{t(faqsTexts.map)}</Paragraph>
      </HeaderComponent>
      <div
        style={{
          padding: isSimilTablet && "0 90px",
          width: isBigScreen ? "1300px" : "100%",
          margin: isSimilTablet ? "100px auto" : "50px auto",
        }}
        className={style.itemsContainer}
      >
        <Row justify="start" style={{width:'90%'}}>
          <Col>
            <div onClick={() => goToHome()} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined />
              <Text
                style={{
                  marginLeft: "0.5em",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {t(faqsTexts.returnToHome)}
              </Text>
            </div>
          </Col>
        </Row>
        <Faqs />
      </div>
    </div>
  );
}
