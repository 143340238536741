import api from "./api";

const prefix = "address-name";

export const apiAddress = {
  getAddressByLocation: function (lat, lon) {
    return api.get(`${prefix}`, {
      params: {
        lat,
        lon,
      },
    });
  },
};
