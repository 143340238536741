import React, { useEffect, useState } from "react";
import "./termsModal.scss";
import { useCookies } from "react-cookie";
import { Checkbox, Col, Image, Modal, Row, Skeleton, Typography } from "antd";
import { apiPrograms } from "../../api";
import { apiCart } from "../../api/cartItems";
import { useAuth } from "../../common/auth/auth.context";
import { CustomCheckbox } from "../CustomCheckbox";
import CloseOutlined from "../../assets/times-solid.svg";
import { useHistory } from "react-router-dom";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../common/notifications";
import { getErrorMessage } from "../../api/api";
import { CustomButton } from "../CustomButton";
const { Title, Text } = Typography;

const TermsComponent = ({ id, isVisible, setIsVisible }) => {
  const [cookies, setCookies, removeCookie] = useCookies(["programs"]);
  const [meetAllRequirements, setMeetAllRequirements] = useState(false);
  const [meetAllConditions, setMeetAllConditions] = useState(false);
  const { isAuthenticated } = useAuth();
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true);
  let history = useHistory();

  const handleInscription = async () => {
    if (isAuthenticated) {
      await apiCart.updateTermsAccepted(id);
      return history.push(`/completeInscription/${id}`);
    } else {
      if (cookies.programs) {
        let cookiePrograms = [...cookies.programs];
        let programIndex = cookiePrograms.findIndex(
          (program) => program.program.id === id
        );

        const programCopy = { ...cookiePrograms[programIndex] };
        programCopy.termsAccepted = true;
        cookiePrograms.splice(programIndex, 1, programCopy);
        removeCookie("programs");
        setCookies("programs", cookiePrograms, { path: "/" });
        return history.push(`/register/${id}`);
      }
    }
  };

  useEffect(() => {
    apiPrograms
      .getTermsByProgram(id)
      .then((response) => {
        setTerms(response.data);
        if(response.data.conditions.length === 0) {setMeetAllConditions(true)};
        if(response.data.requirements.length === 0) {setMeetAllRequirements(true)}; 
        setLoading(false);
      })
      .catch((error) => {
        showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRequirements = (selectedCheckboxes) => {
    if (selectedCheckboxes.length === terms.requirements.length) {
      setMeetAllRequirements(true);
    } else if(terms.requirements.length){
      setMeetAllRequirements(false);
    }
  };

  const onChangeConditions = (selectedCheckboxes) => {
    if (selectedCheckboxes.length === terms.conditions.length) {
      setMeetAllConditions(true);
    } else if(terms.conditions.length) {
      setMeetAllConditions(false);
    }
  };

  const handleCloseTermsModal = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      closeIcon={
        <Image
          preview={false}
          style={{ position: "absolute", right: "1em" }}
          width={17}
          src={CloseOutlined}
        />
      }
      visible={isVisible}
      centered
      onCancel={handleCloseTermsModal}
      footer={[<div></div>]}
    >
      <div>
        <Title style={{ textAlign: "center" }} level={2}>
          Requisitos y <span style={{ color: "#197E68" }}>condiciones</span>
        </Title>
      </div>
      <div>
        <div className="declarated-text">
          <Text style={{ fontWeight: "normal", fontSize: "16px" }}>
            Declaro que cumplo con los siguientes requisitos de ingreso para acceder a este programa o
            carrera:
          </Text>
        </div>
        {loading ? (
          <Skeleton active />
        ) : (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChangeRequirements}>
            <Row>
              <div style={{ maxHeight: "170px", overflowY: "auto" }}>
                {terms?.requirements?.map((term) => (
                  <Col span={24} key={term.id}>
                    <CustomCheckbox value={term.id}>
                      <Text
                        style={{
                          fontSize: "16px",
                          color: "#464646",
                          fontWeight: "normal",
                        }}
                      >
                        {term.description}
                      </Text>
                    </CustomCheckbox>
                  </Col>
                ))}
              </div>
            </Row>
          </Checkbox.Group>
        )}
      </div>
      {terms?.conditions?.length > 0 && (
      <div style={{marginTop: "2em"}}>
        <div className="declarated-text">
          <Text style={{ fontWeight: "normal", fontSize: "16px" }}>
            Acepto las siguientes condiciones de cursado:
          </Text>
        </div>
        {loading ? (
          <Skeleton active />
        ) : (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChangeConditions}>
            <Row>
              <div style={{ maxHeight: "170px", overflowY: "auto" }}>
                {terms?.conditions?.map((term) => (
                  <Col span={24} key={term.id}>
                    <CustomCheckbox value={term.id}>
                      <Text
                        style={{
                          fontSize: "16px",
                          color: "#464646",
                          fontWeight: "normal",
                        }}
                      >
                        {term.description}
                      </Text>
                    </CustomCheckbox>
                  </Col>
                ))}
              </div>
            </Row>
          </Checkbox.Group>
        )}
      </div>)}
      <div className="button-properties">
        <CustomButton
          disabled={!(meetAllRequirements && meetAllConditions)}
          onClick={() => handleInscription()}
          type="primary"
          block
        >
          Continuar
        </CustomButton>
      </div>
    </Modal>
  );
};

export default TermsComponent;
