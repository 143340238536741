import React from "react";
import { Col } from "antd";
import {
  FaTiktok,
  FaLinkedin,
  FaYoutube,
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { IconContext } from "react-icons";

import style from "./index.module.scss";
import { useMediaQuery } from "react-responsive";

const SocialMedia = () => {

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 })
    return isNotMobile ? children : null
  }
  
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 })
    return isMobile ? children : null
  }

  return (
    <>
      <Default>
        <IconContext.Provider
          value={{
            color: "white",
            className: "icn",
            style: { width: "20px", height: "20px" },
          }}
        >
          <Col className={style.socialMediaIcons}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ar.linkedin.com/school/lasiglo21/"
            >
              <FaLinkedin />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@lasiglo21"
            >
              <FaTiktok />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/universidadsiglo21"
            >
              <FaYoutube />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/lasiglo21"
            >
              <FaFacebookSquare />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/LaSiglo21"
            >
              <FaTwitter />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lasiglo21/"
            >
              <FaInstagram />
            </a>
          </Col>
        </IconContext.Provider>
      </Default>
      <Mobile>
        <IconContext.Provider
          value={{
            color: "white",
            className: "icn",
            style: { width: "20px", height: "20px" },
          }}
        >
          <Col 
            className={style.socialMediaIcons} 
            style={{ width: '100%', justifyContent: "space-around", marginTop: "13px"}}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ar.linkedin.com/school/lasiglo21/"
            >
              <FaLinkedin />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@lasiglo21"
            >
              <FaTiktok />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/universidadsiglo21"
            >
              <FaYoutube />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/lasiglo21"
            >
              <FaFacebookSquare />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/LaSiglo21"
            >
              <FaTwitter />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lasiglo21/"
            >
              <FaInstagram />
            </a>
          </Col>
        </IconContext.Provider>
      </Mobile>
    </>
  );
};

export default SocialMedia;
