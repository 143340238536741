import imageCompleteDatabg from "../../assets/complete-data-banner.jpg";
import { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Spin, Alert } from "antd";
import "./../completeInscription/completeInscription.scss";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { CompletePage } from "../../i18n/locales/es/text";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CustomButton } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import HeaderComponent from "../../components/header";
import { priceHelper } from "../../helpers/priceHelper";
import { useAuth } from "../../common/auth/auth.context";
import { useMediaQuery } from "react-responsive";
import React from "react";
import { apiCart } from "../../api/cartItems";
import { apiUser } from "../../api/user";
import { apiEnrollment } from "../../api/enrollment";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../common/notifications";
const { Title, Text } = Typography;

export const BannerCompletePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderComponent left="-15em" imgBg={imageCompleteDatabg}>
        <Title style={{ color: "white" }}>{t(CompletePage.bannerTitle)}</Title>
        <Text style={{ color: "white", marginBottom: "20px" }}>
          {t(CompletePage.bannerSubtitle)}
        </Text>
      </HeaderComponent>
    </>
  );
};

const CompleteInscriptionPage = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const { isAuthenticated, getUser } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [cartItemData, setCartItemData] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [loadingEnrollmentCreation, setLoadingEnrollmentCreation] =
    useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetail = async () => {
    const { username } = getUser();
    try {
      const { data } = await apiUser.getUserDetail(username);
      setUserDetail(data);
    } catch (error) {
      setUserDetail(null);
    }
  };

  useEffect(() => {
    if (id) {
      const setEnrollmentData = () => {
        apiCart
          .getItemByProgram(id)
          .then((response) => {
            setCartItemData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      };
      setEnrollmentData();
    }
  }, [id]);

  const StudentFormComplete = () => {
    return (
      <>
        <Text
          style={{
            color: "#000000",
            fontSize: isSimilTablet ? "24px" : "20px",
            fontWeight: "bold",
            margin: "20px 0",
          }}
        >
          Datos particulares del alumno
        </Text>
        <Card className="detailsCard">
          {userDetail ? (
            <Row>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Nombre completo:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {`${
                      userDetail?.name ? userDetail?.name.toUpperCase() : ""
                    } ${
                      userDetail?.lastName
                        ? userDetail?.lastName.toUpperCase()
                        : ""
                    }`}
                  </label>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Numero de DNI:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {userDetail?.documentNumber}
                  </label>
                </Col>
              </Col>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Correo electrónico:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {userDetail?.primaryEmail}
                  </label>
                </Col>
                {userDetail?.addresses?.[0]?.telephoneNumber1 && (
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "2em 1.5em",
                    }}
                  >
                    <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Número de Teléfono:
                    </label>
                    <label style={{ fontWeight: "normal" }}>
                      {userDetail?.addresses?.[0]?.telephoneNumber1}
                    </label>
                  </Col>
                )}
              </Col>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Fecha de nacimiento:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {userDetail?.birthDate && dateFormat(userDetail?.birthDate)}
                  </label>
                </Col>

                {userDetail?.addresses?.[0]?.location?.name && (
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "2em 1.5em",
                    }}
                  >
                    <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Localidad:
                    </label>
                    <label style={{ fontWeight: "normal" }}>
                      {userDetail?.addresses?.[0]?.location?.name}
                    </label>
                  </Col>
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24} className="empty-set-container">
                <Alert
                  className="caus-alert"
                  message={
                    <Text className="caus-alert-content">
                      No se pudieron obtener los datos del usuario, pero
                      igualmente podés continuar con la Pre Inscripción.
                    </Text>
                  }
                  type="info"
                />
              </Col>
            </Row>
          )}
        </Card>
      </>
    );
  };

  const LoadingModal = ({ loadingEnrollmentCreation }) => {
    // const handleClose = () => {
    //   setOpenModal(false);
    // };

    // const handleOk = () => {
    //   setOpenModal(false);
    //   history.push(`/signin/${id !== undefined ? id : ""}`);
    // };

    return (
      <Modal
        style={{ textAlign: "center" }}
        title={<div></div>}
        centered
        visible={loadingEnrollmentCreation}
        closable={false}
        width={400}
        footer={<div></div>}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ transform: "scale(1.5)" }} size="large" />
          <Title style={{ marginTop: "1.3em" }} level={3}>
            Estamos creando tu legajo
          </Title>
        </div>
      </Modal>
    );
  };

  const goToCheckout = () => {
    history.push(`/checkout/${id}`);
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      history.push(`/courses/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createEnrollment = async () => {
    setLoadingEnrollmentCreation(true);
    try {
      const { data } = await apiEnrollment.createEnrollment(
        cartItemData.enrollment
      );

      setLoadingEnrollmentCreation(false);

      history.push(`/finish-inscription/admission/${id}/`, {
        studentRecord: data.studentRecord.id,
      });
    } catch (e) {
      console.error(e);
      if (e?.response?.status === 409) {
        showNotification(
          NOTIFICATION_TYPES.ERROR,
          `No podés inscribirte a esta carrera porque ya tenés un legajo: ${e.response.data.message}`
        );
      } else {
        showNotification(
          NOTIFICATION_TYPES.ERROR,
          "No se pudo generar el legajo, intenta nuevamente"
        );
      }

      setLoadingEnrollmentCreation(false);
    }
  };

  const dateFormat = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  };

  return (
    <>
      <BannerCompletePage setValue={() => {}} />

      <div
        style={{
          width: isBigScreen ? "1300px" : "100%",
          margin: "0 auto",
          padding: isSimilTablet ? "50px 90px 0 90px" : "50px 2em 0 2em",
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col pull={"left"}>
            <div onClick={() => goToCheckout()} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined />
              <Text
                style={{
                  fontSize: "16px",
                  color: "#006755",
                  fontWeight: "bold",
                }}
              >
                {t(CompletePage.returnButtonText)}
              </Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Text
            style={{
              marginTop: "30px",
              color: "#000000",
              fontSize: isSimilTablet ? "24px" : "20px",
              fontWeight: "bold",
            }}
          >
            Preinscripción a carrera
          </Text>
        </Row>
        <Row style={{ margin: "20px 0" }}>
          <Card className="detailsCard">
            <Row justify={isSimilTablet && "center"}>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Curso:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {cartItemData?.program.name}
                  </label>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Modalidad:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {cartItemData?.enrollment?.attendance?.description}
                  </label>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Total a pagar:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {priceHelper(cartItemData?.enrollment?.totalToPay)}
                  </label>
                </Col>
              </Col>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Turno:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {cartItemData?.enrollment?.courseMode.description
                      ? cartItemData?.enrollment?.courseMode.description
                      : "Sin Turno"}
                  </label>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Centro de Aprendizaje Universitario:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {cartItemData?.enrollment?.cau.name
                      ? cartItemData?.enrollment?.cau.name
                      : "-"}
                  </label>
                </Col>
              </Col>
              <Col span={isSimilTablet && 8}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Fecha de inicio
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    {cartItemData?.enrollment?.startDate &&
                      dateFormat(cartItemData?.enrollment?.startDate)}
                  </label>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2em 1.5em",
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Descuento sólo por hoy:
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    { cartItemData?.enrollment?.discounts &&
                      priceHelper(-cartItemData?.enrollment?.discounts)}
                  </label>
                </Col>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row style={{ margin: "20px 0" }}>
          {isAuthenticated && <StudentFormComplete userDetail={userDetail} />}
        </Row>
        <Row style={{ margin: "20px 0" }}>
          {isSimilTablet ? (
            <Col span={6} offset={18}>
              {/* <Link to={`/finish-inscription/${id}`}> */}
              <CustomButton
                onClick={createEnrollment}
                loading={false}
                type="primary"
              >
                Confirmar mi inscripción
              </CustomButton>
              {/* </Link> */}
              {loadingEnrollmentCreation && (
                <LoadingModal
                  loadingEnrollmentCreation={loadingEnrollmentCreation}
                />
              )}
            </Col>
          ) : (
            <Col span={24}>
              {/* <Link to={`/finish-inscription/${id}`}> */}
              <CustomButton
                onClick={createEnrollment}
                loading={false}
                type="primary"
              >
                Confirmar mi inscripción
              </CustomButton>
              {/* </Link> */}
              {loadingEnrollmentCreation && (
                <LoadingModal
                  loadingEnrollmentCreation={loadingEnrollmentCreation}
                />
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};
export default CompleteInscriptionPage;
