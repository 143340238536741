import React, { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import {
  CloseOutlined,
  MenuOutlined,
  LoginOutlined,
  UserAddOutlined,
  BookOutlined,
  UpOutlined,
  TeamOutlined,
  CalendarOutlined,
  IdcardOutlined
} from "@ant-design/icons";
import SocialMedia from "../../socialMedia/index";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Image,
  Layout,
  Row,
  Button,
  Typography,
  Badge,
  Menu,
  Dropdown,
  Drawer,
  Divider,
} from "antd";
import { AiOutlineUser, AiOutlineSearch } from "react-icons/ai";

import { FiHeart } from "react-icons/fi";
import { IconContext } from "react-icons";
import logo from "../../../assets/logotipo.png";
import style from "./index.module.scss";
import { CustomButton, SearchInput } from "../../../components";
import { useAuth } from "../../auth/auth.context";
import { useCookies } from "react-cookie";
import { apiCart } from "../../../api/cartItems";
import { useDispatch, useSelector } from "react-redux";
import { setCartItemsLenght } from "../../../redux/cart/action";

const { Header } = Layout;
const { Text } = Typography;

const OwnHeader = () => {
  const { cartItemsLength } = useSelector((state) => state.Cart);
  const dispatch = useDispatch();
  const isSmartphone = useMediaQuery({
    query: "(max-width: 415px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });

  let history = useHistory();

  const [cookies] = useCookies(["programs"]);

  const [scroll, setScroll] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 })
    return isNotMobile ? children : null
  }
  
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 })
    return isMobile ? children : null
  }

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setCartItemsLenght(cookies?.length ? cookies.length : 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      apiCart
        .getItems()
        .then((response) => {
          dispatch(setCartItemsLenght(response.data.length));
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (!isAuthenticated) {
      if (cookies.programs) {
        dispatch(setCartItemsLenght(cookies.programs.length));
      } else {
        dispatch(setCartItemsLenght(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleClick = (event) => {
    setShowSearch(!showSearch);
  };

  const getRegister = () => {
    history.push("/register");
  };

  const clickToCart = () => {
    history.push("/cart");
  };

  const handleLogin = async () => {
    login();
  };

  window.onscroll = () => {
    let sc = window.scrollY;
    setScroll(sc);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const iconValues = useMemo(() => ({
    height: "25px",
    width: "25px",
    className: "headerICN",
  }),[]);

  const CartBadge = () => {
    return (
      <Badge count={cartItemsLength} size="default">
        <FiHeart />
      </Badge>
    );
  };

  const toggleMenuOnMobile = () => {
    setShowDropdownMenu((prev) => !prev);
  };

  const menu = (
    <Menu style={{ padding: "0.5em 1em" }}>
      <CustomButton
        type="primary"
        block
        style={{ padding: "0 3em", margin: "0.5em 0" }}
        onClick={() => handleLogin()}
      >
        Iniciar Sesión
      </CustomButton>
      <CustomButton
        type="link"
        block
        style={{ padding: "0 3em", margin: "0.5em 0" }}
        onClick={() => getRegister()}
      >
        Registrarme
      </CustomButton>
    </Menu>
  );

  return (
    <>
      <Default>
        <Header
          style={scroll > 50 ? { marginBottom: "95px" } : { marginBottom: "0" }}
          className={style.header}
        >
          {!isSmartphone && (
            <Row className={style.upperBar}>
              <div
                style={{
                  width: isBigScreen ? "1300px" : "100%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col className={style.textContainer}>
                  <Text className={style.ues21}>
                    Universidad Siglo 21, La Universidad Privada más elegida de
                    Argentina
                  </Text>
                </Col>
                <SocialMedia />
              </div>
            </Row>
          )}
          <Row
            style={
              scroll > 50 ? { position: "fixed" } : { position: "relative" }
            }
            gutter={16}
            className={style.navContainer}
          >
            <div
              style={{
                width: isBigScreen ? "1300px" : "100%",
                margin: "0 auto",
              }}
              className={style.navContainerSon}
            >
              <Col className={style.logoContainer}>
                <Link to="/">
                  <Image className={style.logo} src={logo} preview={false} />
                </Link>
              </Col>
              <Col className={style.menuContainer}>
                <a
                  className={style.menuLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://21.edu.ar/la-universidad/quienes-somos"
                >
                  Quiénes Somos
                </a>
                <a
                  className={style.menuLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://21.edu.ar/noticias/"
                >
                  Noticias
                </a>
                <a
                  className={style.menuLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://21.edu.ar/consulta-alumnos"
                >
                  Soy Estudiante
                </a>
              </Col>
              <Col className={style.actionsContainer}>
                <IconContext.Provider
                  value={iconValues}
                >
                  <Button
                    className={style.actionsButton}
                    size="large"
                    type="text"
                    icon={showSearch ? <CloseOutlined /> : <AiOutlineSearch />}
                    onClick={handleClick}
                  />
                  {!isAuthenticated ? (
                    <Dropdown
                      overlay={menu}
                      placement="bottomCenter"
                      trigger={["hover"]}
                    >
                      <Button
                        className={style.actionsButton}
                        size="large"
                        type="text"
                        icon={<AiOutlineUser />}
                      />
                    </Dropdown>
                  ) : (
                    <Link to="/profile">
                      <Button
                        className={style.actionsButton}
                        size="large"
                        type="text"
                      >
                        <Badge dot color="green">
                          <AiOutlineUser style={{ fontSize: 18 }} />
                        </Badge>
                      </Button>
                    </Link>
                  )}
                    <Button
                      className={style.actionsButton}
                      size="large"
                      type="text"
                      onClick={clickToCart}
                      icon={<CartBadge />}
                    ></Button>
                </IconContext.Provider>
              </Col>
            </div>
          </Row>

          {showSearch && (
            <div style={{ width: "100%", display: "flex" }}>
              <Row
                style={
                  scroll > 50
                    ? { top: "95px", position: "fixed" }
                    : { top: "145px", position: "absolute" }
                }
                justify="center"
                className={style.searchBar}
              >
                <SearchInput setshowsearch={setShowSearch} />
              </Row>
              <div className={style.shadowSearch}></div>
            </div>
          )}

          {scroll >= 200 && (
            <div className={style.scrollTop}>
              <Button
                type="primary"
                size="large"
                style={{ boxShadow: "0px 0px 20px #00675540" }}
                shape="circle"
                icon={<UpOutlined />}
                onClick={scrollTop}
              />
            </div>
          )}
        </Header>
      </Default>
      <Mobile>
        <Header
          style={scroll > 50 ? { marginBottom: "95px" } : { marginBottom: "0" }}
          className={style.headerMobile}
        >
          <Row
            style={
              scroll > 50 ? { position: "fixed" } : { position: "relative" }
            }
            gutter={16}
            className={style.navContainer}
          >
            <Col span={6} className={style.logoContainer}>
              <Link to="/">
                <Image className={style.logo} src={logo} preview={false} />
              </Link>
            </Col>
            <Col span={18} className={style.actionsContainer}>
              <IconContext.Provider
                value={iconValues}
              >
                <Button
                  className={style.actionsButton}
                  size="large"
                  type="text"
                  icon={showSearch ? <CloseOutlined /> : <AiOutlineSearch />}
                  onClick={handleClick}
                />
                <Link to="/cart">
                  <Button
                    className={style.actionsButton}
                    size="large"
                    type="text"
                    icon={<CartBadge />}
                  ></Button>
                </Link>
                <Button
                  onClick={toggleMenuOnMobile}
                  className={style.actionsButton}
                  size="large"
                  type="text"
                  icon={<MenuOutlined />}
                ></Button>
              </IconContext.Provider>
            </Col>
          </Row>

          {showSearch && (
            <div style={{ width: "100%", display: "flex" }}>
              <Row
                style={
                  scroll > 50
                    ? { top: "95px", position: "fixed" }
                    : { top: "95px", position: "absolute" }
                }
                justify="center"
                className={style.searchBar}
              >
                <SearchInput setShowSearch={setShowSearch}/>
              </Row>
              <div className={style.shadowSearch}></div>
            </div>
          )}

          {scroll >= 200 && (
            <div className={style.scrollTop}>
              <Button
                type="primary"
                size="large"
                style={{ boxShadow: "0px 0px 20px #00675540" }}
                shape="circle"
                icon={<UpOutlined />}
                onClick={scrollTop}
              />
            </div>
          )}
          <Drawer
            title={
              <Row>
                <Col span={14}>
                  <Link to="/">
                    <Image className={style.logo} src={logo} preview={false} />
                  </Link>
                </Col>
              </Row>
            }
            placement="left"
            closable={false}
            onClose={() => setShowDropdownMenu(false)}
            visible={showDropdownMenu}
          >
            <Row>
              <Col span={24}>
                <TeamOutlined />
                <a
                  className={style.menuLinkMobile}
                  href="https://21.edu.ar/la-universidad/quienes-somos"
                >
                  Quiénes Somos
                </a>
              </Col>
              <Col span={24}>
                <CalendarOutlined />
                <a
                  className={style.menuLinkMobile}
                  href="https://21.edu.ar/noticias/"
                >
                  Noticias
                </a>
              </Col>
              <Col span={24}>
                <BookOutlined />
                <a
                  className={style.menuLinkMobile}
                  href="https://21.edu.ar/consulta-alumnos"
                >
                  Soy Estudiante
                </a>
              </Col>
              <Divider className={style.divider} />
              {!isAuthenticated ? (
                <>
                  <Col span={24}>
                    <LoginOutlined />
                    <Link onClick={handleLogin}>
                      <span className={style.menuLinkMobile}>Iniciar Sesión</span>
                    </Link>
                  </Col>
                  <Col span={24}>
                    <UserAddOutlined />
                    <Link to="/register">
                      <span className={style.menuLinkMobile}>Registrarme</span>
                    </Link>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <IdcardOutlined />
                  <Link to="/profile">
                    <span className={style.menuLinkMobile}>Mi Cuenta</span>
                  </Link>
                </Col>
              )}
            </Row>
          </Drawer>
        </Header>
      </Mobile>
    </>
  );
};

export default React.memo(OwnHeader);
