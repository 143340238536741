import createReducer from "../store/createReducer";
import * as types from "./types";

const initialState = {
  cartItemsLength: 0,
};

const CartReducer = createReducer(initialState, {
  [types.SET_CART_ITEMS_LENGHT](state, action) {
    return {
      ...state,
      cartItemsLength: action.payload,
    };
  },
});

export default CartReducer;
