import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Home } from "../../i18n/locales/es/text";
import { Carousel, Row, Col, Typography } from "antd";

import style from "./CarouselMobile.module.scss";
import { apiPrograms } from "../../api";
import { AbstractCardCarousel } from "../cards";

const { Title, Text } = Typography;

export const CarouselMobile = ({ q, text = true, bgColor }) => {
  const [loading, setLoading] = useState(true);
  const [muestra, setMuestra] = useState(null);

  useEffect(() => {
    apiPrograms
      .getProgramsByCareerIds(['8802','8738','8720','8722','3682','4002'])
      .then((response) => {
        setMuestra(response.data.programs);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const { t } = useTranslation();

  const carouselElement = (v) => {

    return muestra.length > v && (
      <div>
        <div className={style.contentStyleCard}>
          {muestra.slice(q * v, q * v+1).map((program) => {
            return (
              <AbstractCardCarousel key={program.id} {...program} />
            );
          })}
        </div>
      </div>
    );
    
  }

  return (
    <Row justify="center">
      <Col style={{ background: bgColor }} className={style.interestsContainer}>
        {text ? (
          <Title className={style.title}>
            {t(Home.Home.knowOurs)}
            <Text className={style.interest}>
              {t(Home.Home.coursesAndCareers)}
            </Text>
          </Title>
        ) : null}
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <Carousel className={style.carousel} dots={{ className: style.dots }}>
            {carouselElement(0)}
            {carouselElement(1)}
            {carouselElement(2)}
            {carouselElement(3)}
            {carouselElement(4)}
            {carouselElement(5)}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};
