import imageSigninbg from "../../assets/signin-banner.jpg";
import { Row, Col, Button, Card } from "antd";
import "./../signin/signin.scss";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Signin } from "../../i18n/locales/es/text";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CustomButton } from "../../components";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderComponent from "../../components/header";
import { useAuth } from "../../common/auth/auth.context";
import React from "react";
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;

export const BannerSignin = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderComponent imgBg={imageSigninbg}>
        <Title style={{ color: "white" }}>{t(Signin.bannerTitle)}</Title>
        <Text style={{ color: "white" }}>{t(Signin.bannerSubtitle)}</Text>
      </HeaderComponent>
    </>
  );
};

const SigninPage = () => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const isExtraSmallScreen = useMediaQuery({ maxWidth: 575 });

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 });
    return isNotMobile ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 });
    return isMobile ? children : null;
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleLogin = async () => {
    login();
  };

  const LoginForm = () => {
    const Default = ({ children }) => {
      const isNotMobile = useMediaQuery({ minWidth: 1224 });
      return isNotMobile ? children : null;
    };

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1223 });
      return isMobile ? children : null;
    };

    return (
      <>
        <Default>
          <Row style={{ justifyContent: "center", marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "35px",
                color: "#006755",
                fontWeight: "bold",
              }}
            >
              {t(Signin.loginForm.loginFormTitle)}
            </Text>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Text
              style={{
                textAlign: "center",
                maxWidth: "473px",
                fontSize: "16px",
                color: "#464646",
              }}
            >
              {t(Signin.loginForm.loginFormSubtitle)}
            </Text>
          </Row>
          <Row justify="center" style={{ marginTop: "20px" }}>
            <Card className="card-signin">
              <Col span={10} offset={7} className="col-signin-card">
                <Title style={{ textAlign: "center" }} level={2}>
                  Haz clic para dirigirte a nuestra pantalla de autenticación
                </Title>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    type="primary"
                    onClick={() => handleLogin()}
                    style={{
                      height: "47px",
                      float: "right",
                      width: "300px",
                      marginTop: "15px",
                    }}
                  >
                    {t(Signin.loginForm.submitButton)}
                  </CustomButton>
                </div>
              </Col>
            </Card>
          </Row>
        </Default>
        <Mobile>
          <Row style={{ justifyContent: "center", marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "35px",
                color: "#006755",
                fontWeight: "bold",
              }}
            >
              {t(Signin.loginForm.loginFormTitle)}
            </Text>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Text
              style={{
                textAlign: "center",
                maxWidth: "473px",
                fontSize: "16px",
                color: "#464646",
              }}
            >
              {t(Signin.loginForm.loginFormSubtitle)}
            </Text>
          </Row>
          <Row justify="center" style={{ marginTop: "20px" }}>
            <Card className="card-signin-mobile">
              <Col span={12} offset={5} className="col-signin-card">
                <Title style={{ textAlign: "center" }} level={4}>
                  Haz clic para dirigirte a nuestra pantalla de autenticación
                </Title>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    type="primary"
                    onClick={() => handleLogin()}
                    style={{
                      height: "47px",
                      float: "right",
                      width: "300px",
                      marginTop: "15px",
                    }}
                  >
                    {t(Signin.loginForm.submitButton)}
                  </CustomButton>
                </div>
              </Col>
            </Card>
          </Row>
        </Mobile>
      </>
    );
  };

  const goToCheckout = () => {
    history.push(`/checkout/${id !== undefined ? id : ""}`);
  };

  const goToRegister = () => {
    history.push(`/register/${id !== undefined ? id : ""}`);
  };

  return (
    <>
      <Default>
        <BannerSignin />

        <div className="container">
          <Row style={{ alignItems: "center" }}>
            {id && (
              <Col pull={"left"}>
                <div
                  onClick={() => goToCheckout()}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowLeftOutlined />
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#006755",
                      fontWeight: "bold",
                    }}
                  >
                    {t(Signin.returnButtonText)}
                  </Text>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "20px" }}>
            <Button
              style={{
                borderRadius: "5px 0px 0px 5px",
                width: "182px",
                height: "58px",
                background: "#006755",
                border: "#006755",
              }}
              type="primary"
            >
              {t(Signin.loginButton)}
            </Button>
            <Button
              style={{
                borderRadius: "0px 5px 5px 0px",
                width: "182px",
                height: "58px",
                background: "#0000000D",
                color: "#909090",
                border: "#0000000D",
              }}
              onClick={() => goToRegister()}
              type="primary"
            >
              {t(Signin.registerButton)}
            </Button>
          </Row>
          <LoginForm />
        </div>
      </Default>
      <Mobile>
        <BannerSignin />

        <div className="containerMobile">
          <Row style={{ alignItems: "center" }}>
            {id && (
              <Col pull={"left"}>
                <div
                  onClick={() => goToCheckout()}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowLeftOutlined />
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#006755",
                      fontWeight: "bold",
                    }}
                  >
                    {t(Signin.returnButtonText)}
                  </Text>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "20px" }}>
            <Button
              style={{
                borderRadius: "5px 0px 0px 5px",
                width: isExtraSmallScreen ? "152px" : "182px",
                height: "58px",
                background: "#006755",
                border: "#006755",
              }}
              type="primary"
            >
              {t(Signin.loginButton)}
            </Button>
            <Button
              style={{
                borderRadius: "0px 5px 5px 0px",
                width: isExtraSmallScreen ? "152px" : "182px",
                height: "58px",
                background: "#0000000D",
                color: "#909090",
                border: "#0000000D",
              }}
              onClick={() => goToRegister()}
              type="primary"
            >
              {t(Signin.registerButton)}
            </Button>
          </Row>
          <LoginForm />
        </div>
      </Mobile>
    </>
  );
};
export default SigninPage;
