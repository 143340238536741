import { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { apiCart } from "../../api/cartItems";
import { useTranslation } from "react-i18next";
import { cart } from "../../i18n/locales/es/text";
import { CartItems, CartItemsMobile } from "./cartItems/CartItems";
import Empty from "./empty/Empty";
import { Row, Col, Typography, Space, Spin, Button } from "antd";
import style from "./Cart.module.scss";
import { useAuth } from "../../common/auth/auth.context";
import HeaderComponent from "../../components/header";
import bg from "../../assets/interestBg.png";
import { setCartItemsLenght } from "../../redux/cart/action";
import { showMessageDeletedFromCart } from "./messageCart";
import { useMediaQuery } from "react-responsive";

const { Title, Paragraph, Text } = Typography;

export default function Cart() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["programs"]);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    checkPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (cookies.programs?.length > 0) {
      setItems(cookies.programs);
    }
    if (cookies.programs?.length === 0) {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.programs]);

  useEffect(() => {
    dispatch(setCartItemsLenght(items.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const checkPaymentStatus = async () => {
    try {
      const { data } = isAuthenticated && (await apiCart.checkPaymentStatus());
      console.log(data);
    } catch (error) {
      console.log(Error);
    }

    showItems();
  };

  const showItems = async () => {
    if (isAuthenticated) {
      if (cookies.programs?.length > 0) {
        await persistCookiesToDatabase();
        blankCookies();
      }
      await setShowingItemsFromDatabase();
      setIsLoading(false);
    } else {
      setShowingItemsFromCookies();
      setIsLoading(false);
    }
  };

  const setShowingItemsFromDatabase = async () => {
    const { data } = await apiCart.getItems();

    setItems(data);
  };

  const setShowingItemsFromCookies = () => {
    let itemsArray = [];
    if (cookies.programs?.length > 0) {
      itemsArray.push(...cookies.programs);
      setItems(itemsArray);
    }
  };

  const persistCookiesToDatabase = async () => {
    const { data: savedItems } = await apiCart.getItems();

    let savingPrograms = [];
    let savingSimulations = [];

    cookies.programs
      .filter(
        (program) =>
          !savedItems.find((item) => program.program.id === item.program.id)
      )
      .forEach((item) => {
        if (item.enrollment) {
          savingSimulations.push({
            programId: item.program.id,
            status: item.status,
            enrollment: item.enrollment,
          });
        } else {
          savingPrograms.push({
            programId: item.program.id,
            status: item.status,
          });
        }
      });

    await apiCart.sendItemsToDB(savingPrograms);

    await apiCart.sendSimulationsToDB(savingSimulations);
  };

  const blankCookies = async () => {
    setCookie("programs", [], { path: "/" });
  };

  const deleteItem = async (data) => {
    if (isAuthenticated) {
      const item = items.filter((item) => item.program.id === data);
      await apiCart.deleteItemsFromDB([`${item?.[0].id}`]);
      setShowingItemsFromDatabase();

      showMessageDeletedFromCart();
    } else {
      let cookie = [...cookies.programs];

      let filtered = cookie.filter((item) => {
        return item.program.id !== data;
      });
      removeCookie("programs");
      setCookie("programs", filtered);

      setShowingItemsFromCookies();
      showMessageDeletedFromCart();
    }
  };

  const Spinner = () => {
    return (
      <Space>
        <Row>
          <Col justify="center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Spin style={{ transform: "scale(1.5)" }} size="large" />
              <Title style={{ marginTop: "1.3em" }} level={3}>
                Estamos buscando tus intereses
              </Title>
            </div>
          </Col>
        </Row>
      </Space>
    );
  };

  const ItemsContainer = ({ items }) => {
    return (
      <Fragment>
        <Col className={style.itemsTitle}>
          <Title className={style.title}>
            <Text className={style.colored}>
              {` ${items?.length} ${t(cart.colored)}`}
            </Text>
            {t(cart.title)}
          </Title>
          <Paragraph className={style.subtitle}>{t(cart.suscribe)}</Paragraph>
        </Col>
        <Col className={style.cartItems}>
          {items?.map((program) => {
            return (
              <>
                {isSimilTablet ? (
                  <CartItems
                    key={program.program.id}
                    status={program.status}
                    program={{
                      id: program.program.id,
                      linkImage:
                        program.program.linkImage1 ||
                        program.program.linkImage2,
                      name: program.program.name,
                    }}
                    click={deleteItem}
                  />
                ) : (
                  <CartItemsMobile
                    key={program.program.id}
                    status={program.status}
                    program={{
                      id: program.program.id,
                      linkImage:
                        program.program.linkImage1 ||
                        program.program.linkImage2,
                      name: program.program.name,
                    }}
                    click={deleteItem}
                  />
                )}
              </>
            );
          })}
        </Col>
        <Link to="/courses">
          <Button className={style.cta}>Ver más cursos</Button>
        </Link>
      </Fragment>
    );
  };

  return (
    <div className={style.cartContainer}>
      <HeaderComponent imgBg={bg}>
        <Title className={style.title}>{t(cart.cartTitle)}</Title>
        <Paragraph className={style.map}>{t(cart.map)}</Paragraph>
      </HeaderComponent>

      {isLoading ? (
        <Spinner />
      ) : (
        <Row
          style={{
            padding: isSimilTablet && "0 90px",
            width: isBigScreen ? "1300px" : "100%",
            margin: isSimilTablet ? "100px auto" : "50px auto",
          }}
          className={style.itemsContainer}
        >
          {isAuthenticated ? (
            items?.length <= 0 ? (
              <Row style={{ padding: !isSimilTablet && "0 1em" }}>
                <Empty />
              </Row>
            ) : (
              <ItemsContainer items={items} />
            )
          ) : cookies.programs === undefined || cookies.programs.length <= 0 ? (
            <Row style={{ padding: !isSimilTablet && "0 1em" }}>
              <Empty />
            </Row>
          ) : (
            <ItemsContainer items={cookies.programs} />
          )}
        </Row>
      )}
    </div>
  );
}
