import React, { useEffect, useState } from "react";
import check from "../../assets/check.svg";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Image, Row, Spin, Typography, Alert } from "antd";
import "./finishInscription.scss";
import { CustomButton } from "../../components";
import { useAuth } from "../../common/auth/auth.context";
import { apiCart } from "../../api/cartItems";
import { apiPayment } from "../../api/paymentLinks";
import logoSiglo from "../../assets/logo-siglo21_blanco.png";

//Paypertic
import nacion from "../../assets/pay/banco-nacion.png";
import cordobesa from "../../assets/pay/banco-cordobesa.png";
import visa from "../../assets/pay/visa.png";
import mastercard from "../../assets/pay/mastercard.png";
import cabal from "../../assets/pay/cabal.png";
import amExpress from "../../assets/pay/american-express.png";
import maestro from "../../assets/pay/maestro.png";
import visaDebito from "../../assets/pay/visadebito.png";
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;

const FinishInscriptionPage = () => {
  const { scope, id, studentRecordButton } = useParams();
  const { getUser } = useAuth();
  const [studentRecord, setStudentRecord] = useState("");
  const [usrPortal, setUsrPortal] = useState("");
  const [isUsrLoggedEqualUsrPortal, setIsUsrLoggedEqualUsrPortal] =
    useState(true);
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const [payperticUrl, setPayperticUrl] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeData = async () => {
    let provisionalPayperticUrl = "";

    let studentData;

    try {
      const { data } = await apiCart.getStudentRecord(id);

      studentData = data;

      setStudentRecord(studentData.studentRecord);
      setUsrPortal(studentData.usrPortal);

      provisionalPayperticUrl = studentData.payperticUrl;

      console.log(studentData.usrPortal,getUser().username);

      setIsUsrLoggedEqualUsrPortal(
        studentData.usrPortal === getUser().username
      );
    } catch (error) {
      console.log(error);
    }

    try {
      const { data: paymentData } = await apiPayment.getPaymentLinks(
        studentData.usrPortal,
        studentData.studentRecord
      );

      setPayperticUrl(paymentData.payperticUrl);

      await setPaymentUrlLinks(
        paymentData.payperticUrl
      );
    } catch (error) {
      setPayperticUrl(provisionalPayperticUrl);

      if (error?.response?.status === 400) {
        setErrorMessage(
          "No encontramos tickets disponibles. Si ya pagaste recordá que tu pago puede demorar en impactar."
        );
      } else {
        setErrorMessage(
          "Ocurrió un error al generar los medios de pago, por favor intenta de nuevo más tarde."
        );
      }
    }

    setLoading(false);
  };

  const setPaymentUrlLinks = async ( payperticUrl ) => {
    try {
      await apiCart.savePaymentLinks(id, payperticUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const ConfirmModal = () => {
    return (
      <>
        <Card className="card-finish">
          <>
            {scope !== "cart" && (
              <div className="item-card-finish">
                {!isSimilTablet ? (
                  <>
                    {" "}
                    <Image
                      className="image-finish-check"
                      preview={false}
                      src={check}
                    />
                    <Title className="title-finish" level={3}>
                      La preinscripción ha sido creada exitosamente
                    </Title>
                  </>
                ) : (
                  <>
                    <Title className="title-finish" level={3}>
                      La preinscripción ha sido creada exitosamente
                    </Title>
                    <Image
                      className="image-finish-check"
                      preview={false}
                      src={check}
                    />
                  </>
                )}
              </div>
            )}
            {!studentRecordButton && scope === "cart" && (
              <div className={`item-card-finish`}>
                <Title className="title-finish" level={3}>
                  Realizá el pago de tu inscripción
                </Title>
              </div>
            )}
            {studentRecordButton && (
              <div className={`item-card-finish`}>
                <Title className="title-finish" level={3}>
                  Estos son tus datos:
                </Title>
              </div>
            )}
          </>

          <Row className="legajo-info" justify="center">
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
              span={24}
            >
              <Col
                style={{
                  display: "flex",
                  flexDirection: isSimilTablet ? "row" : "column",
                  justifyContent: isSimilTablet ? "space-around" : "center",
                  alignItems: !isSimilTablet && "center",
                }}
                span={12}
              >
                <p className="p-legajo-info-title">
                  Legajo:{" "}
                  <span style={{ marginLeft: "4px" }}>{studentRecord}</span>
                </p>
                <p className="p-legajo-info-title">
                  Usuario:{" "}
                  <span style={{ marginLeft: "4px" }}>{usrPortal}</span>
                  {/* <span className="legajo-span"></span> */}
                </p>
              </Col>
            </Col>
            {/* <Col span={2}>
              <div className="line-divider-legajo"></div>
            </Col> */}
          </Row>

          {!isUsrLoggedEqualUsrPortal && (
            <>
              <Row justify="center">
                <Col span={20} style={{ textAlign: "center" }}>
                  <Text className="cardTitle">
                    Este usuario te servirá para realizar todas tus gestiones
                    académicas. Para volver a este sitio, podrás seguir usando
                    el usuario creado al registrarte.
                  </Text>
                </Col>
              </Row>
            </>
          )}

          {studentRecordButton && (
            <>
              <Row justify="center">
                <Col span={20} style={{ textAlign: "center" }}>
                  <Text className="cardTitle">
                    ¡No olvides revisar tu mail! Vas a recibir información importante sobre tu cursado por ese medio!
                  </Text>
                </Col>
              </Row>
            </>
          )}

          {!studentRecordButton &&
            payperticUrl &&
            !errorMessage && (
              <>
                <Row justify="space-around" className="student-record-pay">
                  <Title className="title-select-pay" level={3}>
                    Seleccioná el{" "}
                    <span style={{ color: "#006755" }}>medio de pago</span> que
                    mejor se adapte a vos
                  </Title>
                </Row>
                <Row>
                  <Col span={isSimilTablet ? 20 : 24}>
                    <Title
                      className="text-pay-method-cta"
                      style={{ marginBottom: "3px" }}
                      level={4}
                    >
                      Pagá con{" "}
                      <span style={{ color: "#006755" }}>
                        otros bancos y tarjetas
                      </span>
                    </Title>
                    <p className="text-method-option">
                      Utilizando tus tarjetas de crédito y débito:
                    </p>
                  </Col>
                  <Col
                    style={
                      !isSimilTablet && {
                        display: "flex",
                        justifyContent: "center",
                      }
                    }
                    span={isSimilTablet ? 4 : 24}
                  >
                    <a href={payperticUrl} target="_blank" rel="noreferrer">
                      <CustomButton
                        style={{
                          // background: "#00A98F",
                          // border: "1px solid #00A98F",
                          marginBottom: "8px",
                          marginTop: isSimilTablet ? "10px" : null,
                          width: !isSimilTablet && "102px",
                        }}
                        block={false}
                        type="primary"
                        disabled={loading || !payperticUrl}
                      >
                        {loading ? (
                          <Spin style={{ marginRight: "0.8em" }} />
                        ) : null}{" "}
                        Pagar
                      </CustomButton>
                    </a>
                  </Col>
                </Row>
                <Row
                  style={{ margin: "1em 0 2em 0" }}
                  justify={isSimilTablet ? "space-around" : "space-between"}
                >
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={nacion}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={nacion}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={cordobesa}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={visa}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={mastercard}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={cabal}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={amExpress}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={maestro}
                    />
                  </div>
                  <div className="img-pay-cont">
                    <Image
                      className="img-pay-method"
                      preview={false}
                      src={visaDebito}
                    />
                  </div>
                </Row>
              </>
            )}
          {errorMessage && studentRecordButton === undefined && (
            <Row justify="center">
              <Col span={22}>
                <Alert
                  style={{ textAlign: "center", color: "#ff11233" }}
                  className="caus-alert"
                  message={
                    <Text className="caus-alert-content-error">
                      {errorMessage}
                    </Text>
                  }
                  type="error"
                />
              </Col>
            </Row>
          )}

        </Card>
      </>
    );
  };

  return (
    <Row className="notFound">
      {/* <Image width={100} preview={false} src={logofinish} /> */}

      <div className="container">
        <Image
          style={{ marginBottom: "20px" }}
          preview={false}
          src={logoSiglo}
          width={200}
        />
        <ConfirmModal />

        <Link to="/">
          <Button type="text" className="cta-finish">
            {!studentRecordButton &&
            payperticUrl &&
            !errorMessage
              ? "Quiero pagar en otro momento"
              : "Volver al inicio"}
          </Button>
        </Link>
      </div>
    </Row>
  );
};

export default FinishInscriptionPage;
