import { useEffect, useState } from "react";
import { Card, Col, Row, Radio, Typography, Skeleton } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";

import { apiAddress } from "../../../../api/address-location";
import { usePosition } from "../../../../hooks";
import { apiPrograms } from "../../../../api";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../../../common/notifications";
import { getErrorMessage } from "../../../../api/api";
import { AttendancesEnum } from "./attendance.enum";
import { AttendanceModeEnum } from "../ModalityCareerCard/attendance-mode.enum";

import "./places-career-card.scss";
import "../../checkout.scss";
import { Checkout } from "../../../../i18n/locales/es/text";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const { Text, Title } = Typography;

export const PlaceCard = ({
  programId,
  selectedAttendance,
  selectedCau,
  setSelectedCau,
  setSelectedTurn,
}) => {
  const [caus, setCaus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLocation, setDataLocation] = useState();
  const [isOnline, setIsOnline] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const { t } = useTranslation();

  const { position, error, getLocation, hasActivePosition } = usePosition();

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAttendance) {
      setIsOnline(
        selectedAttendance.attendanceModeStrapi.mode ===
          AttendanceModeEnum.ONLINE
      );
    } else {
      setCaus([]);
      setWarningMessage(
        "Por favor, seleccioná la modalidad de estudio que deseas."
      );
    }
    setSelectedCau(undefined);

    //It's necessary to clean the select turn due the (if) in the checkout index.
    setSelectedTurn(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttendance]);

  useEffect(() => {
    getAddressAndCAUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, selectedAttendance]);

  useEffect(() => {
    if (error) {
      showNotification(NOTIFICATION_TYPES.ERROR, error);
    }
  }, [error]);

  useEffect(() => {
    if (selectedAttendance) {
      if (hasActivePosition()) {
        if (caus.length === 0) {
          if (selectedAttendance.idAcademicOffer === 1) {
            setWarningMessage(t(Checkout.placeCareerCard.warningNotExist));
          } else {
            setWarningMessage(t(Checkout.placeCareerCard.warningNotExistButED));
          }
        } else {
          setWarningMessage("");
        }
      } else if (isOnline && !hasActivePosition()) {
        if (
          selectedAttendance.attendanceModeStrapi.name.toUpperCase() ===
          AttendancesEnum.EDUCACION_DISTRIBUIDA
        ) {
          setWarningMessage(t(Checkout.placeCareerCard.warningLocationOnline));
        } else {
          setWarningMessage(t(Checkout.placeCareerCard.warningLocation));
        }
      } else {
        setWarningMessage("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caus, isOnline]);

  const checkPermissions = async () => {
    // CHECK SITE CACHE ON NAVIGATOR
    const hasPermissions = await navigator.permissions
      .query({ name: "geolocation" })
      .then((resp) => {
        return resp && resp.state === "granted";
      })
      .catch((error) => {
        return false;
      });
    if (hasPermissions) {
      getLocation();
    }
  };

  const getAddressAndCAUs = async () => {
    const promises = [];
    if (hasActivePosition()) {
      setLoading(true);
      const { latitude, longitude } = position;
      if (!dataLocation) {
        promises.push(
          apiAddress
            .getAddressByLocation(latitude, longitude)
            .then((response) => {
              const { city, state, country } =
                response.data.features?.[0].properties;
              setDataLocation(
                `${city === undefined ? "" : city + ", "}${
                  state !== undefined ? state : country
                }`
              );
            })
            .catch((error) => {
              showNotification(
                NOTIFICATION_TYPES.ERROR,
                getErrorMessage(error)
              );
            })
        );
      }
      if (selectedAttendance && programId) {
        promises.push(getCaus(longitude, latitude));
      }
    } else if (selectedAttendance && programId) {
      setLoading(true);
      promises.push(getCaus(null, null));
    }
    await Promise.all(promises);
    setLoading(false);
  };

  const getCaus = (latitude, longitude) => {
    return apiPrograms
      .getCausFromLocation(
        programId,
        selectedAttendance.id,
        latitude,
        longitude
      )
      .then((response) => {
        if (response.data) setCaus([...response.data]);
      })
      .catch((error) => {
        showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
      });
  };

  const handleLocationActivate = () => {
    getLocation();
  };

  const onChange = (e) => {
    setSelectedCau(JSON.parse(e.target.value));
  };

  return (
    <Card className="checkout-card caus-card" bodyStyle={{ overflowY: "auto" }}>
      {loading && <Skeleton active paragraph={{ rows: 2 }} />}

      {!loading && (
        <Row
          justify="space-between"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: !isSimilTablet ? "column" : null,
            borderBottom: " 1px solid #f0f0f0",
            marginBottom: "1em",
            paddingBottom: "1em",
          }}
        >
          <Col span={isSimilTablet ? 14 : 24}>
            <Title level={4} className="checkout-card-title">
              {t(Checkout.placeCareerCard.titleCard)}
            </Title>
          </Col>
          <Col style={{ display: "flex" }} span={isSimilTablet ? 10 : 24}>
            <div>
              <EnvironmentFilled
                className="location-icon"
                style={{ color: "#006755" }}
              />
            </div>
            <div>
              {!hasActivePosition() || error ? (
                <Title
                  level={5}
                  className="location-text"
                  onClick={handleLocationActivate}
                >
                  {t(Checkout.placeCareerCard.locationActive)}
                </Title>
              ) : position && loading && !dataLocation ? (
                <Title level={5} className="location-text">
                  {t(Checkout.placeCareerCard.loading)}
                </Title>
              ) : (
                <Title level={5} className="location-text">
                  {dataLocation}
                </Title>
              )}
            </div>
          </Col>
        </Row>
      )}

      {!loading && caus.length > 0 && (
        <>
          <Radio.Group
            onChange={onChange}
            style={{ marginBottom: "20px" }}
            value={JSON.stringify(selectedCau)}
          >
            <Row gutter={[16, 16]}>
              {caus.map((item, index) => (
                <Col span={12} key={index}>
                  <Radio value={JSON.stringify(item)}>
                    <Row>
                      <Text className="checkout-items-name">{item.name}</Text>
                      <Text className="single-cau-address">
                        {item.street} {item.streetNumber}
                      </Text>
                    </Row>
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </>
      )}

      {warningMessage && !loading && (
        <Col
          span={24}
          style={{
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          <Text
            style={{
              color: "grey",
              fontWeight: "lighter",
              fontSize: "16px",
            }}
          >
            {warningMessage}
          </Text>
        </Col>
      )}
    </Card>
  );
};
export default PlaceCard;
