import { AutoComplete, Input } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import { apiPrograms } from "../../api/programs";
import { compareStrings } from '../../helpers/stringHelper';
const { Search } = Input;
//ant-btn ant-btn-primary ant-btn-lg ant-input-search-button

const ButtonContainer = styled.div`
  .ant-btn-primary {
    font-size: 14px;
    background-color: #006755;
    border-color: #006755;
    height: 40px;
    width: 100%;
    border-radius: 5px;
  }
`;
export const CustomInputSearch = (props) => {
  const [options, setOptions] = useState([]);
  const isMountedRef = useRef(true);

  const handleSearch = (value) => {
    apiPrograms
    .getSuggestionsSearch(value)
    .then((response) => {
      if(response.data.programs  && isMountedRef.current) setOptions(response.data.programs);
    })
    .catch((error) => {
      console.error(error);
    });
  };
  useEffect(() => {
    handleSearch(null);
    return () => {
      isMountedRef.current = false;
    }

  }, []);

  return (
    <ButtonContainer>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{
          width: '100%',
        }}
        options={options}
        filterOption={(inputValue, option) => {
          return compareStrings(inputValue, option.value);
        }}
        onSelect={props.onSelect}
        autoFocus
      >
        <Search {...props} />
      </AutoComplete>
    </ButtonContainer>
  );
};