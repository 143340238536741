import api from "./api";

const prefix = "province";

export const apiLocation = {
  getProvinces: function () {
    return api.get(prefix);
  },

  getLocations: function (id) {
    return api.get(`${prefix}/${id}`);
  },
};
