import api from "./api";

const prefix = "cart";

export const apiCart = {
  getItems: () => {
    return api.get(`${prefix}/user`);
  },

  getItemByProgram: (programId) => {
    return api.get(`${prefix}/user/program/${programId}`, {
      params: {
        programId
      }
    });
  },

  getStudentRecord: (programId) => {
    return api.get(`${prefix}/studentRecord`, {
      params: {
        programId
      }
    });
  },

  sendItemsToDB: (items) => {
    return api.post(`${prefix}`, {
      userInterests: items,
    });
  },

  deleteItemsFromDB: (id) => {
      return api.post(`${prefix}/delete`, id);
  },

  sendSimulationsToDB: (body) => {
    return api.post(`${prefix}/enrollment`, body);
  },

  getTermsAccepted: (programId) => {
    return api.get(`${prefix}/termsAccepted`, {
      params: {
        programId
      }
    });
  },

  updateTermsAccepted: (programId) => {
    return api.put(`${prefix}/termsAccepted`, {
      programId
    });
  },

  savePaymentLinks: (programId,mercadoPagoUrl,payperticUrl) => {
    return api.put(`${prefix}/paymentLinks`, {
      programId,
      payperticUrl
    });
  },

  checkPaymentStatus: () => {
    return api.post(`${prefix}/checkPaymentStatus`);
  },
}
