import React from "react";
import { useTranslation } from "react-i18next";
import { Courses } from "../../i18n/locales/es/text";
import { CustomButton } from "../CustomButton";
import { Card, Col, Typography, Row, Image } from "antd";
import { Link, useHistory } from "react-router-dom";

import style from "./cards.module.scss";

import clock from "../../assets/clock-regular.svg";
import user from "../../assets/user-regular.svg";
import { useMediaQuery } from "react-responsive";

const { Text, Title } = Typography;

export const AbstractCard = ({
  id,
  linkImage1,
  linkImage2,
  name,
  duration,
  attendanceModesStrapi,
}) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });

  const { t } = useTranslation();

  return (
    <Col>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0.5em 0.5em",
        }}
      >
        <Card
          className={style.card}
          style={
            isSimilTablet
              ? { maxWidth: isBigScreen ? "350px" : "300px" }
              : { maxWidth: "350px" }
          }
          cover={
            <Image
              preview={false}
              className={style.imgCardAbs}
              alt="example"
              src={linkImage1 ? linkImage1 : linkImage2}
            />
          }
          bodyStyle={{
            height: "150px",
            position: "relative",
          }}
          actions={[
            <Row justify="space-between" className={style.cardFooter}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img className={style.icon} src={clock} alt={t(duration)} />
                <Text style={{ marginLeft: "5px" }}>{duration}</Text>
              </div>
              <CustomButton block type="primary">
                <Link style={{ color: "#fff" }} to={`/product/${id}`}>
                  Ver más
                </Link>
              </CustomButton>
            </Row>,
          ]}
        >
          <div style={{ position: "relative", height: "100%" }}>
            <Title className={style.careerTitle}>{t(name)}</Title>
            <div className={style.mode}>
              <img className={style.icon} src={user} alt={"user icon"} />

              {attendanceModesStrapi.length > 0 ? (
                <Text className={style.modeText}>
                  {t(Courses.mode + ": ")}
                  {attendanceModesStrapi
                    .map((att) => {
                      return t(att.name);
                    })
                    .join(", ")}
                </Text>
              ) : (
                <Text className={style.modeText}>
                  {t(Courses.mode)}: A Definir
                </Text>
              )}
            </div>
          </div>
        </Card>
      </div>
    </Col>
  );
};

export const AbstractCardCarousel = ({
  id,
  name,
  duration,
  attendanceModesStrapi,
  linkImage1,
  linkImage2,
}) => {
  const { t } = useTranslation();
  let history = useHistory();
  const onClick = () => {
    history.push(`/product/${id}`);
    history.go(0);
  };

  return (
    <Col>
      <Card
        className={style.cardCarousel}
        cover={
          <div className={style.contImgRecord}>
            <Image
              preview={false}
              className={style.imgCardAbs}
              alt="example"
              src={linkImage1 ? linkImage1 : linkImage2}
            />
          </div>
        }
        bodyStyle={{ height: "37%" }}
        actions={[
          <Row justify="space-between" className={style.cardFooter}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img className={style.icon} src={clock} alt={t(duration)} />
              <Text style={{ marginLeft: "5px", fontSize: "12px" }}>
                {duration}
              </Text>
            </div>
            <CustomButton block type="primary" onClick={onClick}>
              Ver más
            </CustomButton>
          </Row>,
        ]}
      >
        <div style={{ position: "relative", height: "100%" }}>
          <Title className={style.careerTitle}>{t(name)}</Title>
          <div className={style.mode}>
            <img className={style.icon} src={user} alt={"user icon"} />

            {attendanceModesStrapi.length > 0 ? (
              <Text className={style.modeText}>
                {t(Courses.mode + ": ")}
                {attendanceModesStrapi
                  .map((att) => {
                    return t(att.name);
                  })
                  .join(", ")}
              </Text>
            ) : (
              <Text className={style.modeText}>
                {t(Courses.mode)}: A Definir
              </Text>
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
};
