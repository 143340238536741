import { useEffect, useState } from "react";
import { Col, Row, Typography, Button, Input, Space, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { ProfileSiglo } from "../../i18n/locales/es/text";
import { LoginOutlined } from "@ant-design/icons";
import { useAuth } from "../../common/auth/auth.context";
import style from "./index.module.scss";
import { useMediaQuery } from "react-responsive";
import { apiUser } from "../../api/user";
import React from "react";

const Profile = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const switchEnabled = true;
  const [userDetail, setUserDetail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { Title, Text } = Typography;
  const { logout, isAuthenticated, getUser } = useAuth();

  const { t } = useTranslation();

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 });
    return isNotMobile ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 });
    return isMobile ? children : null;
  };

  useEffect(() => {
    setUser(getUser());
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetail = async () => {
    const { username } = getUser();
    try {
      const { data } = await apiUser.getUserDetail(username);
      console.log(data);
      setUserDetail(data);
    } catch (error) {
      setUserDetail(null);
      setErrorMessage("Ocurrió un error al recuperar los datos del perfil.");
    }
    setLoading(false);
  };

  const dateFormat = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  };

  const Spinner = () => {
    return (
      <Space>
        <Row>
          <Col justify="center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Spin style={{ transform: "scale(1.5)" }} size="large" />
              <Title style={{ marginTop: "1.3em" }} level={3}>
                Estamos buscando tus datos
              </Title>
            </div>
          </Col>
        </Row>
      </Space>
    );
  };

  const handleLogOut = async () => {
    logout();
  };
  return (
    <>
      <Default>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loading && !userDetail ? (
            <Spinner />
          ) : (
            <div
              style={{
                padding: "0 90px",
                width: isBigScreen ? "1300px" : "100%",
                margin: "100px auto",
              }}
            >
              <Row justify="space-between">
                <Col span={12}>
                  <div style={{ width: "100%" }}>
                    <Title className={style.textUserWelcome}>
                      <Text style={{ color: "#464646" }}>
                        {t(ProfileSiglo.welcome)}
                      </Text>
                      <Text style={{ color: "#006755" }}>
                        {userDetail
                          ? `${userDetail?.name} ${userDetail?.lastName}`
                          : user?.username}
                      </Text>
                    </Title>
                  </div>
                </Col>

                <Col span={12}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {isAuthenticated && (
                      <Button
                        icon={<LoginOutlined />}
                        size="large"
                        type="text"
                        onClick={handleLogOut}
                      >
                        {t(ProfileSiglo.logout)}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              {errorMessage ? (
                <Row justify="center">
                  <Col span={22}>
                    <Alert
                      style={{ textAlign: "center", color: "#ff11233" }}
                      className="caus-alert"
                      message={
                        <Text className="caus-alert-content-error">
                          {errorMessage}
                        </Text>
                      }
                      type="error"
                    />
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={3} className={style.personalInfo}>
                      {t(ProfileSiglo.personalData)}
                    </Title>
                  </Col>
                  <Col span={12}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.name)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.name}
                    />
                  </Col>
                  <Col span={12}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.lastName)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.lastName}
                    />
                  </Col>
                  <Col span={12}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.email)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.primaryEmail}
                    />
                  </Col>
                  {userDetail?.addresses?.[0]?.telephoneNumber1 && (
                    <Col span={12}>
                      <Text className={style.inputTextUser}>
                        {t(ProfileSiglo.telephone)}
                      </Text>
                      <Input
                        disabled={switchEnabled}
                        className={style.inputProfile}
                        value={userDetail?.addresses?.[0]?.telephoneNumber1}
                      />
                    </Col>
                  )}
                  <Col span={12}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.birthDate)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={
                        userDetail?.birthDate
                          ? dateFormat(userDetail?.birthDate)
                          : ""
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.documentNumber)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.documentNumber}
                    />
                  </Col>
                  {/* <Col style={{ marginTop: "1em" }} span={6} offset={18}>
                  {switchEnabled ? (
                    <CustomButton
                      onClick={() => setSwitchEnabled(false)}
                      type="primary"
                    >
                      {t(ProfileSiglo.updateData)}
                    </CustomButton>
                  ) : (
                    <CustomButton type="primary">
                      {ProfileSiglo.saveData}
                    </CustomButton>
                  )}
                </Col> */}
                </Row>
              )}
            </div>
          )}
        </div>
      </Default>
      <Mobile>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loading && !userDetail ? (
            <Spinner />
          ) : (
            <div
              style={{
                padding: "20px 40px",
                width: "100%",
              }}
            >
              <Row justify="space-between">
                <Col span={24}>
                  {isAuthenticated && (
                    <Button
                      icon={<LoginOutlined />}
                      size="large"
                      type="text"
                      onClick={handleLogOut}
                      className={style.buttonLogoutMobile}
                    >
                      {t(ProfileSiglo.logout)}
                    </Button>
                  )}
                </Col>

                <Col span={24}>
                  <div style={{ width: "100%" }}>
                    <Title className={style.textUserWelcomeMobile}>
                      <Text style={{ color: "#464646" }}>
                        {t(ProfileSiglo.welcome)}
                      </Text>
                      <Text style={{ color: "#006755" }}>
                        {userDetail
                          ? `${userDetail?.name} ${userDetail?.lastName}`
                          : user?.username}
                      </Text>
                    </Title>
                  </div>
                </Col>
              </Row>
              {errorMessage ? (
                <Row justify="center">
                  <Col span={22}>
                    <Alert
                      style={{ textAlign: "center", color: "#ff11233" }}
                      className="caus-alert"
                      message={
                        <Text className="caus-alert-content-error">
                          {errorMessage}
                        </Text>
                      }
                      type="error"
                    />
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.name)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.name}
                    />
                  </Col>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.lastName)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.lastName}
                    />
                  </Col>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.email)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.primaryEmail}
                    />
                  </Col>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.telephone)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.addresses?.[0]?.telephoneNumber1}
                    />
                  </Col>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.birthDate)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={
                        userDetail?.birthDate
                          ? dateFormat(userDetail?.birthDate)
                          : ""
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <Text className={style.inputTextUser}>
                      {t(ProfileSiglo.documentNumber)}
                    </Text>
                    <Input
                      disabled={switchEnabled}
                      className={style.inputProfile}
                      value={userDetail?.documentNumber}
                    />
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
      </Mobile>
    </>
  );
};

export default Profile;
