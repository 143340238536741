import React from "react";
import { Typography } from "antd";
import "./checkout.steps.scss";
import { CheckOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

const CheckoutSteps = ({
  number,
  title,
  description,
  isSelected,
  isActive,
}) => {
  return (
    <>
      <div className="steps-content-checkout">
        <div
          style={
            isActive
              ? { border: "1px solid #006755", background: "#006755" }
              : isSelected
              ? { border: "1px solid #006755", background: "#006755" }
              : { border: "1px solid rgba(0, 0, 0, 0.25)" }
          }
          className="step-checkout-circle"
        >
          <span style={{ color: isActive ? "#fff" : "#006755" }}>
            {isActive && !isSelected && number}
            {isActive && isSelected && (
              <CheckOutlined style={{ color: "#fff" }} />
            )}
            {!isActive && !isSelected && number}
          </span>
        </div>
        <div className="step-content-typo">
          <Title className="title-step-checkout" level={3}>
            {title}
          </Title>
          <Text className="text-step-checkout">{description}</Text>
        </div>
      </div>
    </>
  );
};

export default CheckoutSteps;
