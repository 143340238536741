import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./common/scrollTop";
import { PrivateRouteOAuth, RouteWithLayout } from "./components";
import SessionExpired from "./sites/session-expired";
import Home from "./sites/home";
import CoursesPage from "./sites/courses";
import Cart from "./sites/cart/Cart";
import Faqs from "./sites/faqs/Faqs";
import NotFound from "./sites/not-found";
import CheckoutPage from "./sites/checkout";
import ProductSite from "./sites/product";
import Profile from "./sites/profile";
import Signin from "./sites/signin";
import CompleteInscriptionPage from "./sites/completeInscription";
import RegisterPage from "./sites/register";
import FinishInscriptionPage from "./sites/finishInscription";

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/session-expired" component={SessionExpired} />
        <RouteWithLayout exact path="/" component={Home} />
        <RouteWithLayout exact path="/courses" component={CoursesPage} />
        <RouteWithLayout exact path="/checkout/:id" component={CheckoutPage} />
        <RouteWithLayout path="/product/:id" component={ProductSite} />
        <RouteWithLayout path="/cart" component={Cart} />
        <RouteWithLayout path="/faqs" component={Faqs} />
        <PrivateRouteOAuth exact path="/profile" component={Profile} />
        <RouteWithLayout exact path="/signin/:id?" component={Signin} />
        <RouteWithLayout exact path="/register/:id?" component={RegisterPage} />
        <PrivateRouteOAuth
          inCart={true}
          exact
          path="/completeInscription/:id"
          component={CompleteInscriptionPage}
        />
        <Route
          exact
          path="/finish-inscription/:scope?/:id/:studentRecordButton?"
          component={FinishInscriptionPage}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
