import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Layout } from "antd";
import Header from "../../common/layout/header";
import Footer from "../../common/layout/footer";
import style from "./PrivateRoute.module.scss";
import { useAuth } from "../../common/auth/auth.context";

const { Content } = Layout;

export const PrivateRouteOAuth = ({
  component: Component,
  inCart,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();

  return (
    <Layout className={style.layout}>
      <Header />
      <Content>
        {isAuthenticated ? (
          <Route {...rest}>
            <Component />
          </Route>
        ) : inCart ? (
          <Redirect to="/register" />
        ) : (
          <Redirect to="/" />
        )}
      </Content>
      <Footer />
    </Layout>
  );
};

export const RouteWithLayout = ({ component: Component, ...rest }) => {
  return (
    <Layout className={style.layout}>
      <Header />

      <Route {...rest}>
        <Component />
      </Route>

      <Footer />
    </Layout>
  );
};
