import React from "react";
import  {Link} from "react-router-dom"
import {Row, Col, Typography, Button} from "antd"
import style from "./not-found.module.scss"

const {Paragraph, Title} = Typography;

const NotFound = () => {
  return (
    <Row className={style.notFound}>
      <Col className={style.container}>
        <Title className={style.title}>404</Title>
        <Paragraph className={style.subTitle}>Página no encontrada</Paragraph>
        <Link to="/">
          <Button className={style.cta}>Ir al inicio</Button>
        </Link>
      </Col>
    </Row>
  );
};

export default NotFound;
