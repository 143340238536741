import axios from "axios";
import { message } from "antd";

const apiUrl = process.env.REACT_APP_BASE_URL_BACKEND;

const instance = axios.create({
  baseURL: apiUrl,
});

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = true;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      message.error("La sesión expiró", 2, () => {
        console.log("redirect home", error);
        document.cookie = "accessToken=;expires=Thu, 01 Jan 1970";
        window.location = "/";
      });
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export async function initAxiosInterceptors(token) {
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

export async function removeHeaderAxios(token) {
  instance.interceptors.request.use((config) => {
    config.headers.Authorization = "";
    return config;
  });
}

export function getErrorMessage(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "Ha ocurrido un error";
  }
}

export default instance;
