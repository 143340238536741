import { useEffect, useState } from "react";
import { Card, Col, Row, Radio, Typography, Skeleton } from "antd";
import "./turn.card.scss";
import { apiPrograms } from "../../../../api";
import { getErrorMessage } from "../../../../api/api";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../../../common/notifications";

const { Text, Title } = Typography;

export const TurnsCard = ({
  programId,
  selectedAttendance,
  selectedCau,
  selectedTurn,
  setSelectedTurn,
}) => {
  const [turns, setTurns] = useState([]);
  const [loading, setLoading] = useState(true);

  const noTurn = (data) => {
    for (let i = 0; i < data.length; i++) {
      if(data[i].idCourseMode !== 3) return false
    }
    return true;
  }

  useEffect(() => {
    if (selectedCau && selectedAttendance && programId) {
      apiPrograms
        .getCourseModesByAttendanceAndCau(
          programId,
          selectedAttendance.id,
          selectedCau.idCau
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            setTurns(data);
            setLoading(false);
            if (data.length === 1 || noTurn(data)) {
              setSelectedTurn(data?.[0]);
            }
          }
        })
        .catch((error) => {
          showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
        });
    } else {
      setTurns([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCau]);

  const onChange = (e) => {
    setSelectedTurn(JSON.parse(e.target.value));
  };

  //Rule when is attendance 2 (ED) don't show modal
  if (loading === true && selectedCau && selectedAttendance && programId) {
    return (
      <Card
        className="checkout-card turn-card"
        bodyStyle={{ overflowY: "auto" }}
        title={
          <Title level={4} className="caus-card-title">
            Turnos disponibles
          </Title>
        }
      >
        <Skeleton active paragraph={{ rows: 2 }} />
      </Card>
    );
  } else if (loading && turns.length === 0) {
    return (
      <Card
        className="checkout-card turn-card"
        bodyStyle={{ overflowY: "auto" }}
        title={
          <Title level={4} className="caus-card-title">
            Turnos disponibles
          </Title>
        }
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          <Text
            style={{ color: "grey", fontWeight: "lighter", fontSize: "16px" }}
          >
            Seleccioná antes el centro de aprendizaje donde quieres estudiar.
          </Text>
        </Col>
      </Card>
    );
  } else if (selectedAttendance?.attendanceModeStrapi.strapiCode > 1) {
    return (
      <Card
        className="checkout-card"
        bodyStyle={{ overflowY: "auto" }}
        title={
          <Title level={4} className="caus-card-title">
            Turnos disponibles
          </Title>
        }
      >
        {!loading && selectedTurn === undefined && turns.length === 0 ? (
          <Col span={24} style={{ textAlign: "center", marginTop: "15px" }}>
            <Text
              style={{ color: "grey", fontWeight: "lighter", fontSize: "16px" }}
            >
              No existen turnos para la modalidad y Centro de Aprendizaje seleccionadas.
            </Text>
          </Col>
        ) : turns.length === 1 && selectedTurn ? (
          <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
            {selectedTurn.description || 'Sin Turno'}
          </Text>
        ) : (
          <Radio.Group onChange={onChange} value={JSON.stringify(selectedTurn)}>
            <Row gutter={[16, 16]}>
              {turns.map((item, index) => (
                <Col span={12} key={index}>
                  <Radio value={JSON.stringify(item)} key={index}>
                    {item.description === "" ? (
                    <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Sin Turno 
                    </Text>
                    ) : (
                      <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {item.description}
                      </Text>
                    )
                  }
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        )}
      </Card>
    );
  } else {
    return (
      <Card
        className="checkout-card"
        bodyStyle={{ overflowY: "auto" }}
        title={
          <Title level={4} className="caus-card-title">
            Turnos disponibles
          </Title>
        }
      >
        {turns.length === 0 ? (
          <Col span={24} style={{ textAlign: "center", marginTop: "15px" }}>
            <Text
              style={{ color: "grey", fontWeight: "lighter", fontSize: "16px" }}
            >
              No existen turnos para la modalidad y Centro de Aprendizaje seleccionadas.
            </Text>
          </Col>
        ) : turns.length === 1 &&
          selectedTurn &&
          selectedTurn.description !== "Sin Turno" &&
          selectedTurn.description !== "" ? (
          <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
            {selectedTurn.description}
          </Text>
        ) : (
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{ color: "grey", fontWeight: "lighter", fontSize: "16px" }}
            >
              En las modalidades virtuales no es necesario que selecciones
              horario. ¡Podés acceder cuando quieras!
            </Text>
          </Col>
        )}
      </Card>
    );
  }
};
export default TurnsCard;
