import { createBrowserHistory } from "history";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import rootReducers from "./reducers";
import rootSagas from "./sagas";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [];
middleware.push(routerMiddleware(history));
middleware.push(sagaMiddleware);

/* const enhancers = [applyMiddleware(...middleware)]; */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducers(history),
  undefined,
  composeEnhancers(applyMiddleware(...middleware))
);

const configureStore = () => store;

sagaMiddleware.run(rootSagas);

export default configureStore;