import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import imageCheckoutbg from "../../../../assets/checkout-image.jpg";
import HeaderComponent from "../../../../components/header";
import { Checkout } from "../../../../i18n/locales/es/text";

const { Title, Text } = Typography;

export const BannerChekout = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderComponent left="-20em" imgBg={imageCheckoutbg}>
        <Title style={{ color: "white" }}>
          {t(Checkout.bannerCheckout.title)}
        </Title>
        <Text style={{ color: "white", marginBottom: "20px" }}>
          {t(Checkout.bannerCheckout.titleMap)}
        </Text>
      </HeaderComponent>
    </>
  );
};
export default BannerChekout;
