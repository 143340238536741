import React, { useEffect, useState } from "react";
import { Radio, Row, Col, Typography, Skeleton } from "antd";
import { apiPrograms } from "../../../../api";
import { AttendanceModeEnum } from "./attendance-mode.enum";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../../../common/notifications";
import { getErrorMessage } from "../../../../api/api";

import "./modality-career-card.scss";
import "../../checkout.scss";
import { useTranslation } from "react-i18next";
import { Checkout } from "../../../../i18n/locales/es/text";
import { CustomCard } from "../../../../components/CustomCard";

const { Text, Title } = Typography;

export const ModalityCareerCard = ({
  programId,
  selectedAttendance,
  setSelectedAttendance,
}) => {
  const [loading, setLoading] = useState(true);
  const [attendances, setAttendances] = useState();
  const [filteredAttendances, setFilteredAttendances] = useState([]);
  const [selectedAttendanceMode, setSelectedAttendanceMode] = useState(
    AttendanceModeEnum.ONLINE
  );

  const { t } = useTranslation();

  useEffect(() => {
    apiPrograms
      .getAttendances(programId)
      .then((response) => {
        if (response.data && response.data.length) {
          setAttendances(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAttendanceMode && attendances && attendances.length) {
      setFilteredAttendances([
        ...attendances.filter(
          (item) => item.attendanceModeStrapi.mode === selectedAttendanceMode
        ),
      ]);
    }
  }, [attendances, selectedAttendanceMode]);

  const onChange = (e) => {
    setSelectedAttendance(JSON.parse(e.target.value));
  };

  const handleTabChange = (attendanceMode) => {
    setSelectedAttendance(undefined);
    setSelectedAttendanceMode(attendanceMode);
  };

  return (
    <CustomCard className="checkout-card modality-card">
      <Row
        style={{ padding: loading && "0 24px" }}
        gutter={[0, 16]}
        justify="center"
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="title-modality-card">
              <Title level={4} className="checkout-card-title">
                {t(Checkout.modalityCareerCard.titleCard)}
              </Title>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <Col
                span={12}
                className={`tab ${
                  selectedAttendanceMode === AttendanceModeEnum.ONLINE
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  handleTabChange(AttendanceModeEnum.ONLINE);
                }}
              >
                Online
              </Col>
              <Col
                span={12}
                className={`tab ${
                  selectedAttendanceMode === "IN_PERSON" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabChange("IN_PERSON");
                }}
              >
                Presencial
              </Col>
            </div>
            <Col style={{ padding: "15px 25px 20px 25px" }} span={24}>
              {filteredAttendances && filteredAttendances.length ? (
                <Radio.Group
                  onChange={onChange}
                  value={JSON.stringify(selectedAttendance)}
                >
                  {filteredAttendances?.map((attendance, index) => (
                    <Row key={index}>
                      <Radio
                        value={JSON.stringify(attendance)}
                        key={attendance.id}
                      >
                        <Text className="checkout-items-name">
                          {attendance.description}
                        </Text>
                      </Radio>
                    </Row>
                  ))}
                </Radio.Group>
              ) : (
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      color: "grey",
                      fontWeight: "lighter",
                      fontSize: "16px",
                    }}
                  >
                    {t(Checkout.modalityCareerCard.notExistCareer)}
                  </Text>
                </Col>
              )}
            </Col>
          </>
        )}
      </Row>
    </CustomCard>
  );
};
export default ModalityCareerCard;
