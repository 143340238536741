import React from "react";
import { useMediaQuery } from "react-responsive";
import { ListCarousel, MainHome } from "../../components";
import { Faqs } from "../../components/faqs/Faqs";

import OpinionsSection from "./component/opinions";
const Home = () => {
  const isNotMobile = useMediaQuery({ minWidth: 1224 });

  return (
    <div>
      <MainHome />
      <OpinionsSection />
      <ListCarousel q={isNotMobile ? 4 : 1} />
      <Faqs fq={3} bg/>
    </div>
  );
};

export default Home;
