import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BonusCard, BonusCardMobile } from "./components/BonusCard";
import { PlaceCard } from "./components/PlaceCareerCard";
import { TurnsCard } from "./components/TurnsCard";
import { BannerChekout } from "./components/BannerCheckout";
import { Row, Col, Layout, Skeleton, Typography } from "antd";
import { InscriptionDetailCard } from "./components/InscriptionDetailCard";
import { ModalityCareerCard } from "./components/ModalityCareerCard";
import { apiPrograms, apiFlex, apiEnrollment } from "../../api";
import { getErrorMessage } from "../../api/api";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../common/notifications";

import "./checkout.scss";
import { useMediaQuery } from "react-responsive";
import CheckoutSteps from "./components/CheckoutSteps";
const { Title } = Typography;

export const CheckoutPage = () => {
  const { id } = useParams();
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  //Attendance
  const [isAttendanceSelected, setIsAttendanceSelected] = useState(false);
  const isAttendanceActive = true;
  //CAU
  const [isCAUSelected, setIsCAUSelected] = useState(false);
  const [isCAUActive, setIsCAUActive] = useState(false);

  //Tuin
  const [isTurnSelected, setIsTurnSelected] = useState(false);
  const [isTurnActive, setIsTurnActive] = useState(false);
  const [priceData, setPriceData] = useState();
  const [program, setProgram] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [selectedCau, setSelectedCau] = useState();
  const [selectedTurn, setSelectedTurn] = useState();
  const [flexCourses, setFlexCourses] = useState();
  const [isFlex, setIsFlex] = useState(false);
  const [ coupon, setCoupon ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);

  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });

  const history = useHistory();

  useEffect(() => {
    if (selectedAttendance) {
      setIsAttendanceSelected(true);
      setIsCAUActive(true);
    }
  }, [selectedAttendance]);

  async function fetchFlex() {
    try {
      const { data } = await apiFlex.get();
      setFlexCourses(data[0]);
      return data[0];
    } catch (error) {
      showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
    }
  }

  useEffect(() => {
    if (selectedCau) {
      setIsCAUSelected(true);
      setIsTurnActive(true);
      // if (selectedTurn?.idCourseMode === 2) {
      //   setIsTurnSelected(true);
      // }
    }
  }, [selectedCau, selectedTurn]);
  useEffect(() => {
    if (selectedTurn) {
      setIsTurnSelected(true);
    }
  }, [selectedTurn]);

  useEffect(() => {
    apiPrograms
      .getProgramsId(id)
      .then( async (response) => {
        if (!response.data) {
          history.push("/not-found");
        }
        setProgram(response.data);
        try {
          const { careersIds } = await fetchFlex();
          const ids = careersIds.split(",");
          ids.forEach(e => {
            if (response.data.idCareer === Number(e)) setIsFlex(true);
          });
        } catch (error) {
          showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));  
        } finally {
          setLoading(false);
        }
      })
      .catch((error) => {
        showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateCoupon = async (cupon) => {
    setIsLoading(true);
      const dataSimulate = priceData.simulationData;
      await apiEnrollment.validateCoupon({
        careerName: program.name,
        cauId: dataSimulate.cauId,
        coupon: cupon,
      },{
        careerId: dataSimulate.careerId,
        siteId: dataSimulate.cauStrapiCode,
        attendanceModeId: selectedAttendance.idAcademicOffer,
        studentType: 'NI',
        packageId: dataSimulate.packageId,
        subPeriodId: dataSimulate.periodId,
        periodCode: priceData.tickets[0].period,
      }).then(() => {
        showNotification(NOTIFICATION_TYPES.SUCCESS, 'Se aplico el cupon correctamente!');
        setCoupon(cupon);
      })
      .catch(error => {
        showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
        setCoupon(null);
        setIsLoading(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Layout style={{ background: "#fff" }}>
          {/* <LocationModal setCaus={setCaus} ref={locationModalRef} id={id} /> */}
          <BannerChekout />
          <div
            style={{
              padding: isSimilTablet && "0 90px",
              width: isBigScreen ? "1300px" : "100%",
              margin: "0 auto",
            }}
          >
            {isSimilTablet ? (
              <Title
                level={2}
                style={{ textAlign: "center", marginTop: "2em", visibility: isFlex ? "hidden" : "visible" }}
              >
                Completá los <span style={{ color: "#006755" }}>3 pasos</span>{" "}
                para inscribirte en tu programa o carrera
              </Title>
            ) : (
              <div style={{ padding: "0 1em", visibility: isFlex ? "hidden" : "visible"  }}>
                <Title
                  level={4}
                  style={{ textAlign: "center", marginTop: "2em" }}
                >
                  Completá los <span style={{ color: "#006755" }}>3 pasos</span>{" "}
                  para inscribirte en tu programa o carrera
                </Title>
              </div>
            )}

            {!isSimilTablet ? (
              <Row justify="center">
                <Col span={24} style={{ padding: "0" }}>
                  {isFlex ? 
                    null
                  : <>
                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={1}
                        isSelected={isAttendanceSelected}
                        isActive={isAttendanceActive}
                        title="Paso 1"
                        description="Seleccioná la modalidad que prefieras."
                      />
                      <ModalityCareerCard
                        programId={id}
                        selectedAttendance={selectedAttendance}
                        setSelectedAttendance={setSelectedAttendance}
                      />
                    </div>
                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={2}
                        isSelected={isCAUSelected}
                        isActive={isCAUActive}
                        title="Paso 2"
                        description="Seleccioná el centro de aprendizaje donde querés estudiar."
                      />
                      <PlaceCard
                        selectedAttendance={selectedAttendance}
                        selectedCau={selectedCau}
                        setSelectedCau={setSelectedCau}
                        setSelectedTurn={setSelectedTurn}
                        programId={id}
                      />
                    </div>
                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={3}
                        isSelected={isTurnSelected}
                        isActive={isTurnActive}
                        title="Paso 3"
                        description="Seleccioná el horario de cursado que prefieras."
                      />
                      <TurnsCard
                        programId={id}
                        selectedCau={selectedCau}
                        selectedAttendance={selectedAttendance}
                        selectedTurn={selectedTurn}
                        setSelectedTurn={setSelectedTurn}
                      />
                    </div>
                  </>
                  }
                  <div className="boxContInfo">
                    <InscriptionDetailCard
                      priceData={priceData}
                      setPriceData={setPriceData}
                      program={program}
                      selectedCau={selectedCau}
                      selectedAttendance={selectedAttendance}
                      selectedTurn={selectedTurn}
                      flexCourses={flexCourses}
                      isFlex={isFlex}
                      coupon={coupon}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <Row justify="center" gutter={[16]}>
                {isFlex ?
                  null
                : <Col span={12}>
                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={1}
                        isSelected={isAttendanceSelected}
                        isActive={isAttendanceActive}
                        title="Paso 1"
                        description="Seleccioná la modalidad que prefieras."
                      />
                      <ModalityCareerCard
                        programId={id}
                        selectedAttendance={selectedAttendance}
                        setSelectedAttendance={setSelectedAttendance}
                      />
                    </div>
                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={2}
                        isSelected={isCAUSelected}
                        isActive={isCAUActive}
                        title="Paso 2"
                        description="Seleccioná el centro de aprendizaje donde querés estudiar."
                      />
                      <PlaceCard
                        selectedAttendance={selectedAttendance}
                        selectedCau={selectedCau}
                        setSelectedCau={setSelectedCau}
                        setSelectedTurn={setSelectedTurn}
                        programId={id}
                      />
                    </div>

                    <div className="boxContInfo">
                      <CheckoutSteps
                        number={3}
                        isSelected={isTurnSelected}
                        isActive={isTurnActive}
                        title="Paso 3"
                        description="Seleccioná el horario de cursado que prefieras."
                      />
                      <TurnsCard
                        programId={id}
                        selectedCau={selectedCau}
                        selectedAttendance={selectedAttendance}
                        selectedTurn={selectedTurn}
                        setSelectedTurn={setSelectedTurn}
                      />
                    </div>
                  </Col>
                }
                <Col span={12}>
                  <div className="boxContInfo">
                    <InscriptionDetailCard
                      priceData={priceData}
                      setPriceData={setPriceData}
                      program={program}
                      selectedCau={selectedCau}
                      selectedAttendance={selectedAttendance}
                      selectedTurn={selectedTurn}
                      flexCourses={flexCourses}
                      isFlex={isFlex}
                      coupon={coupon}
                    />
                  </div>
                </Col>
              </Row>
            )}

            {isSimilTablet ? <BonusCard action={(e) => validateCoupon(e)} activeButton={priceData} isLoading={isLoading}/> : <BonusCardMobile action={(e) => validateCoupon(e)} activeButton={priceData} isLoading={isLoading}/>}
          </div>
        </Layout>
      )}
    </>
  );
};
export default CheckoutPage;
