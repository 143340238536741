//ant-card-body
import { Card } from "antd";
import styled from "styled-components";

const CardContainer = styled.div`
  .ant-card-body {
    padding: 0 !important;
  }
`;
export const CustomCard = (props) => {
  return (
    <>
      <CardContainer>
        <Card {...props}>{props.children}</Card>
      </CardContainer>
    </>
  );
};
