import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Image } from "antd";
import { useTranslation } from "react-i18next";
import { cart } from "../../../i18n/locales/es/text";
import cartImg from "../../../assets/cart.png";
import style from "./empty.module.scss";
import { CustomButton } from "../../../components";

const { Title } = Typography;
const empty = { ...cart.emptyCart };

function Empty() {
  const { t } = useTranslation();
  return (
    <Row className={style.container}>
      <Col className={style.imageContainer}>
        <Image src={cartImg} preview={false} className={style.image} />
      </Col>
      <Title className={style.title}>{t(empty.emptyCartTitle)}</Title>
      <Link to="/courses">
        <CustomButton type="primary" className={style.cta}>
          {t(empty.emptyCartCta)}
        </CustomButton>
      </Link>
    </Row>
  );
}

export default Empty;
