import React from "react";
import { Card, Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { Courses } from "../../../../i18n/locales/es/text";

import style from "../../index.module.scss";
import "../../courses.scss";

const { Content } = Layout;
const { Title } = Typography;

const CoursesNoResult = () => {
  const { t } = useTranslation();

  return (
    <Content className={style.cardsContainerNoResult}>
      <Card>
        <Title className="title-no-result" level={3}>
          {t(Courses.notFound)}
        </Title>
        <Typography className="text-no-result">
          {t(Courses.tryAgain)}
        </Typography>
      </Card>
    </Content>
  );
};

export default CoursesNoResult;
