export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
}
export const compareStrings = (search,targetName) => {
    const searchNormalized = removeAccents(search);
    const targetNameNormalized = removeAccents(targetName);

    return !!targetNameNormalized.includes(searchNormalized);
}