import React, { useEffect, useState } from "react";
import { Checkbox, Menu, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { Courses } from "../../../../i18n/locales/es/text";
import { CustomCheckbox } from "../../../../components";

import "../../courses.scss";
import { apiPrograms } from "../../../../api";
import Text from "antd/lib/typography/Text";
import { AttendanceModeEnum } from "../../../checkout/components/ModalityCareerCard/attendance-mode.enum";
import { capitalize } from "../../../../helpers/stringHelper";
const { SubMenu } = Menu;

const CoursesSidebar = ({
  setApt,
  setModalities,
  setFieldStudy,
  preSelectedProgramType,
  preSelectedStudyField,
  preSelectedModality,
}) => {
  const { t } = useTranslation();

  //Tipo de programa
  const [loadingP, setLoadingP] = useState(true);
  const [ptys, setPtys] = useState();

  //Area de estudios
  const [loadingSF, setLoadingSF] = useState(true);
  const [stField, setStField] = useState();

  useEffect(() => {
    apiPrograms
      .getAllTypeOfProgram()
      .then((response) => {
        setPtys(response.data);
        setLoadingP(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    apiPrograms
      .getAllStudyFields()
      .then((response) => {
        setStField(response.data);
        setLoadingSF(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const onChangeTypeProgram = (checkedValues) => {
    setApt(checkedValues.toString());
  };

  const onChangeModalities = (checkedValues) => {
    setModalities(checkedValues.toString());
  };

  const onChangeFOStudy = (checkedValues) => {
    setFieldStudy(checkedValues.toString());
  };

  return (
    <>
      <Menu mode="inline">
        <SubMenu
          className="submenu-courses"
          key="sub1"
          title={t(Courses.typeOfProgram)}
        >
          {loadingP ? (
            <Skeleton active />
          ) : (
            <Checkbox.Group
              defaultValue={[preSelectedProgramType]}
              onChange={onChangeTypeProgram}
            >
              {ptys.map((el, i) => {
                return (
                  <div style={{ margin: "1.5em 0" }}>
                    <CustomCheckbox key={el.id} value={el.id}>
                      {t(el.name)}
                    </CustomCheckbox>
                    <br />
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </SubMenu>

        <SubMenu
          className="submenu-courses"
          key="sub2"
          title={t(Courses.studyArea)}
        >
          {loadingSF ? (
            <Skeleton active />
          ) : (
            <Checkbox.Group
              defaultValue={[preSelectedStudyField]}
              onChange={onChangeFOStudy}
            >
              {stField.map((el, i) => {
                return (
                  <div style={{ margin: "1.5em 0" }}>
                    <CustomCheckbox key={el.id} value={el.id}>
                      <Text>{t(el.name)}</Text>
                    </CustomCheckbox>
                    <br />
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </SubMenu>

        <SubMenu className="submenu-courses" key="sub3" title={t(Courses.mode)}>
          <Checkbox.Group
            defaultValue={[preSelectedModality]}
            onChange={onChangeModalities}
          >
            {Object.entries(AttendanceModeEnum).map(([key, value]) => (
              <div style={{ margin: "1.5em 0" }}>
                <CustomCheckbox key={key} value={key}>
                  <Text>{capitalize(value)}</Text>
                </CustomCheckbox>
                <br />
              </div>
            ))}
          </Checkbox.Group>
        </SubMenu>
      </Menu>
    </>
  );
};

export const CoursesSidebarMobile = ({
  setApt,
  setModalities,
  setFieldStudy,
  preSelectedProgramType,
  preSelectedStudyField,
  preSelectedModality,
}) => {
  const { t } = useTranslation();

  //Tipo de programa
  const [loadingP, setLoadingP] = useState(true);
  const [ptys, setPtys] = useState();

  //Area de estudios
  const [loadingSF, setLoadingSF] = useState(true);
  const [stField, setStField] = useState();

  useEffect(() => {
    apiPrograms
      .getAllTypeOfProgram()
      .then((response) => {
        setPtys(response.data);
        setLoadingP(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    apiPrograms
      .getAllStudyFields()
      .then((response) => {
        setStField(response.data);
        setLoadingSF(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const onChangeTypeProgram = (checkedValues) => {
    setApt(checkedValues.toString());
  };

  const onChangeModalities = (checkedValues) => {
    setModalities(checkedValues.toString());
  };

  const onChangeFOStudy = (checkedValues) => {
    setFieldStudy(checkedValues.toString());
  };

  return (
    <>
      <Menu defaultOpenKeys={["sub1"]} mode="inline">
        <SubMenu
          className="submenu-courses"
          key="sub1"
          title={t(Courses.typeOfProgram)}
        >
          {loadingP ? (
            <Skeleton active />
          ) : (
            <Checkbox.Group
              defaultValue={[preSelectedProgramType]}
              onChange={onChangeTypeProgram}
            >
              {ptys.map((el, i) => {
                return (
                  <div style={{ margin: "0.5em 0" }}>
                    <CustomCheckbox key={el.id} value={el.id}>
                      {t(el.name)}
                    </CustomCheckbox>
                    <br />
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </SubMenu>

        <SubMenu
          className="submenu-courses"
          key="sub2"
          title={t(Courses.studyArea)}
        >
          {loadingSF ? (
            <Skeleton active />
          ) : (
            <Checkbox.Group
              defaultValue={[preSelectedStudyField]}
              onChange={onChangeFOStudy}
            >
              {stField.map((el, i) => {
                return (
                  <div style={{ margin: "0.5em 0" }}>
                    <CustomCheckbox key={el.id} value={el.id}>
                      <Text>{t(el.name)}</Text>
                    </CustomCheckbox>
                    <br />
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </SubMenu>

        <SubMenu className="submenu-courses" key="sub3" title={t(Courses.mode)}>
          <Checkbox.Group
            defaultValue={[preSelectedModality]}
            onChange={onChangeModalities}
          >
            {Object.entries(AttendanceModeEnum).map(([key, value]) => (
              <div style={{ margin: "0.5em 0" }}>
                <CustomCheckbox key={key} value={key}>
                  <Text>{capitalize(value)}</Text>
                </CustomCheckbox>
                <br />
              </div>
            ))}
          </Checkbox.Group>
        </SubMenu>
      </Menu>
    </>
  );
};

export default React.memo(CoursesSidebar);
