import React from "react";
import { Row, Col, Typography, Image, Avatar, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import { Home } from "../../../i18n/locales/es/text";
import quot from "../../../assets/quotation-marks.svg";
import style from "./opinions.module.scss";
import "./opinions.css";
import { useMediaQuery } from "react-responsive";
import test1 from "../../../assets/test1.jpg";
import test2 from "../../../assets/test2.jpg";
import test3 from "../../../assets/test3.jpg";

const { Title, Text } = Typography;

const OpinionCard = ({ txt, avatar, nameAvatar, infoavatar }) => {

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 })
    return isNotMobile ? children : null
  }
  
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 })
    return isMobile ? children : null
  }

  return (
    <>
      <Default>
        <div className={style.contentCardOpinion}>
          <Row>
            <Image
              className={style.imgDialog}
              preview={false}
              width={50}
              src={quot}
            />
          </Row>
          <div className={style.divAvatar}>
            <Row>
              <Col className={style.contentTextDialog}>
                <Text className={style.textDialog}>{txt}</Text>
              </Col>
            </Row>
            <Row className={style.rowBottom}>
              <Col span={6}>
                <Avatar
                  className={style.avatarContent}
                  size={59}
                  src={
                    <Image
                      preview={false}
                      src={avatar}
                      className={style.avatarImg}
                    />
                  }
                />
              </Col>
              <Col span={18}>
                <Title className={style.titleAvatar}>{nameAvatar}</Title>
                <Text className={style.textInfoAvatar}>{infoavatar}</Text>
              </Col>
            </Row>
          </div>
        </div>
      </Default>
      <Mobile>
        <Row style={{ backgroundColor: 'white', height: '370px' }}>
          <Col span={24} style={{ backgroundColor: 'white', padding: '10px 20px 0px 20px' }}>
            <Image
              preview={false}
              width={50}
              src={quot}
            />
          </Col>
          <Col span={24} style={{ backgroundColor: 'white', padding: '0px 20px 0px 20px' }}>
            <span style={{ fontWeight: "normal"}}>
              {txt}
            </span>
          </Col>
          <Col span={24} style={{ backgroundColor: 'white', padding: '0px 20px 0px 20px' }}>
            <Row>
              <Col span={9}>
              <Avatar
                size={59}
                src={
                  <Image
                    preview={false}
                    src={avatar}
                    style={{ width: '59px' }}
                  />
                }
              />
              </Col>
              <Col span={15}>
                <h1 style={{ fontSize: "26px", fontWeight: "normal" }}>{nameAvatar}</h1>
                <Text style={{ fontWeight: 'normal', fontSize: '16px !important' }}>{infoavatar}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Mobile>
    </>
  );
};

const OpinionsSection = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const { t } = useTranslation();

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 })
    return isNotMobile ? children : null
  }
  
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 })
    return isMobile ? children : null
  }

  return (
    <>
      <Default>
        <div className={style.opinionContent}>
          <Row justify="center">
            <Col>
              <Title className={style.titleOpSection}>
                {t(Home.Home.opinionsTitle)}
              </Title>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ width: isBigScreen ? "1200px" : "100%" }}>
              <Row
                style={{ position: "relative", padding: "0 5em" }}
                justify="space-around"
              >
                <Col className={style.colCardContent}>
                  <OpinionCard
                    avatar={test3}
                    txt={
                      "“Cada profesor tiene la capacidad de generar debates, reflexiones y darte herramientas más allá de lo teórico”"
                    }
                    nameAvatar={"Noah Zoé Pacios"}
                    infoavatar={
                      "Estudiante de Lic. en Relaciones Públicas e Institucionales, La Pampa."
                    }
                  />
                </Col>
                <Col className={style.colCardContent}>
                  <OpinionCard
                    avatar={test2}
                    txt={
                      "“Es muy innovadora, no hay otra universidad que tenga lo que tiene Siglo 21”"
                    }
                    nameAvatar={"Marcos Biondo"}
                    infoavatar={"Egresado de Abogacía, Buenos Aires."}
                  />
                </Col>
                <Col className={style.colCardContent}>
                  <OpinionCard
                    avatar={test1}
                    txt={
                      "“La Universidad te brinda herramientas prácticas y teóricas”"
                    }
                    nameAvatar={"Facundo Olmedo"}
                    infoavatar={"Egresado de la Lic. en Administración, Córdoba."}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Default>
      <Mobile>
        <Row style={{ background: "#0d513f", padding: "25px 0" }} justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <span style={{
              color: '#fff',
              fontSize: '32px',
              textAlign: 'center'
            }}>
              {t(Home.Home.opinionsTitle)}
            </span>
          </Col>
          <Col span={16}>
            <Carousel className="opinionsCarousel" style={{ marginTop: '32px' }}>
              <OpinionCard
                avatar={test3}
                txt={
                  "“Cada profesor tiene la capacidad de generar debates, reflexiones y darte herramientas más allá de lo teórico”"
                }
                nameAvatar={"Noah Zoé Pacios"}
                infoavatar={
                  "Estudiante de Lic. en Relaciones Públicas e Institucionales, La Pampa."
                }
              />
              <OpinionCard
                avatar={test2}
                txt={
                  "“Es muy innovadora, no hay otra universidad que tenga lo que tiene Siglo 21”"
                }
                nameAvatar={"Marcos Biondo"}
                infoavatar={"Egresado de Abogacía, Buenos Aires."}
              />
              <OpinionCard
                avatar={test1}
                txt={
                  "“La Universidad te brinda herramientas prácticas y teóricas”"
                }
                nameAvatar={"Facundo Olmedo"}
                infoavatar={"Egresado de la Lic. en Administración, Córdoba."}
              />
            </Carousel>
          </Col>
        </Row>
      </Mobile>
    </>
  );
};

export default OpinionsSection;
