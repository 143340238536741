import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { apiCart } from "../../api/cartItems";
import { useTranslation } from "react-i18next";
import { Product } from "../../i18n/locales/es/text";

import ReCAPTCHA from "react-google-recaptcha";

import Reaptcha from "reaptcha";
import { Affix, Card, Col, Collapse, Image, Layout, Row, Skeleton } from "antd";
import { apiPrograms } from "../../api";
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from "antd";
import clock from "../../assets/clock-regular.svg";
import user from "../../assets/user-regular.svg";
import { CustomButton, ListCarousel } from "../../components";
import { useAuth } from "../../common/auth/auth.context";
import "./product.scss";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { setCartItemsLenght } from "../../redux/cart/action";
import { showMessageAddedToCart } from "./messageProduct";
import { getErrorMessage } from "../../api/api";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../common/notifications";

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const ProductSite = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRefV2 = React.createRef();
  const recaptchaRef = React.createRef();
  const [program, setProgram] = useState();
  const [terms, setTerms] = useState();
  const [loading, setLoading] = useState(true);
  const [isSelectOne, setIsSelectOne] = useState(true);
  const [isSelectTwo, setIsSelectTwo] = useState(false);
  const [alredyInCart, setAlreadyInCart] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [cartItems, setCartItems] = useState();
  const [isProgramSimulated, setIsProgramSimulated] = useState(null);
  const [cookies, setCookies, removeCookie] = useCookies([
    "programs",
    "viewedStudyFields",
  ]);
  const { isAuthenticated } = useAuth();
  //const [cookies, setCookies, removeCookie] = useCookies(["programs"]);
  const { id } = useParams();
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const { t } = useTranslation();

  //Component Card
  const onVerify = (value) => {
    //console.log("Captcha value:", value);
    setIsBtnDisabled(false);
  };

  const handleCaptchaResolved = async (value) => {
    //console.log("Captcha value:", value);
    history.push(`/checkout/${id}`);
  };

  const onClickCaptchaBtn = () => {
    return recaptchaRef.current.execute();
  };

  const cardDesktop = () => {
    return (
      <div className="site-card-border-less-wrapper">
        <Card bordered={false} className="card-product-info">
          <div className="title-card-prod">
            <p className="nameincard">{program.name}</p>
            <Title className="featuresCard" level={3}>
              {t(Product.feature)}
            </Title>
          </div>

          <div className="info-card-product">
            <div className="info-card-title">
              <Image
                className="info-card-product-image"
                src={clock}
                preview={false}
              />
              <p>{t(Product.duration)}</p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "normal",
                  fontSize: "12px",
                }}
              >
                {program.duration}
              </p>
            </div>
          </div>
          <div className="info-card-product">
            <div className="info-card-title">
              <Image
                className="info-card-product-image"
                src={user}
                preview={false}
              />
              <p>{t(Product.modality)}</p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "normal",
                  fontSize: "12px",
                }}
              >
                {program.attendanceModesStrapi
                  ?.map((modal) => {
                    return modal.name;
                  })
                  .join("-")}
              </p>
            </div>
          </div>
          <CustomButton
            className="info-card-buttom-program"
            block
            type="link"
            onClick={addToInterests}
            disabled={alredyInCart}
          >
            {alredyInCart ? t(Product.duplicatedItem) : t(Product.imImterested)}
          </CustomButton>
          <Link
            to={{
              pathname: !isBtnDisabled ? `/checkout/${id}` : null,
              state: {
                name: program.name,
              },
            }}
          >
            <CustomButton
              disabled={isBtnDisabled || isProgramSimulated}
              className="info-card-buttom-program"
              block
              type="primary"
            >
              {isProgramSimulated === 3
                ? "Pre inscripto"
                : isProgramSimulated === 4
                ? "Inscripto"
                : "Ver aranceles"}
            </CustomButton>
          </Link>
          {isProgramSimulated ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  transform: "scale(0.85)",
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRefV2}
                  sitekey="6LdTHHAdAAAAAJNWJSe3omOzF4lIoXQHZQ9Pwfib"
                  onChange={onVerify}
                  onExpired={() => setIsBtnDisabled(true)}
                />
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  };

  const cardMobile = () => {
    return (
      <Row
        gutter={[24, 24]}
        className="card-btns-mobile-group"
        justify="space-around"
      >
        <Col span={12}>
          <CustomButton
            className="info-card-buttom-program"
            block
            type="link"
            onClick={addToInterests}
            disabled={alredyInCart}
          >
            {alredyInCart ? t(Product.duplicatedItem) : t(Product.imImterested)}
          </CustomButton>
        </Col>
        <Col span={12}>
          <CustomButton
            className="info-card-buttom-program"
            block
            type="primary"
            onClick={onClickCaptchaBtn}
          >
            {isProgramSimulated === 3
              ? "Pre inscripto"
              : isProgramSimulated === 4
              ? "Inscripto"
              : "Ver aranceles"}
          </CustomButton>

          {isProgramSimulated ? null : (
            <Reaptcha
              ref={recaptchaRef}
              sitekey="6LeEt04kAAAAAP3cMhTUI5svUBV3NDU2xzl8H7ua"
              onVerify={handleCaptchaResolved}
              size="invisible"
            />
          )}
        </Col>
      </Row>
    );
  };

  const SelOne = () => {
    if(program?.studyPlan) {
      setIsSelectTwo(!isSelectTwo);
      setIsSelectOne(!isSelectOne);
    }
  };

  useEffect(() => {
    apiPrograms
      .getTermsByProgram(id)
      .then((response) => {
        setTerms(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    apiPrograms
      .getProgramsId(id)
      .then((response) => {
        setProgram(response.data);

        if (
          cookies.viewedStudyFields &&
          cookies.viewedStudyFields !== response.data.studyField.id
        ) {
          let cookie = [...cookies.viewedStudyFields];
          if (!cookie.includes(response.data.studyField.id)) {
            cookie.push(response.data.studyField.id);
            removeCookie("viewedStudyFields");
            setCookies("viewedStudyFields", cookie, { path: "/" });
          }
        } else {
          setCookies("viewedStudyFields", [response.data.studyField.id], {
            path: "/",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    isAuthenticated &&
      apiCart
        .getItems()
        .then((response) => {
          setCartItems(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    let cartItemSelected = null;
    if(cartItems) {
      cartItemSelected = cartItems.find(
        (cartItem) => cartItem.program.id === id
      );
    }
    if (cartItemSelected && cartItemSelected.status > 2) {
      setIsProgramSimulated(cartItemSelected.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  const addToInterests = async () => {
    const data = {
      program: {
        id: program.id,
        linkImage1: program.linkImage1 || program.linkImage2,
        name: program.name,
      },
      status: 1,
      termsAccepted: false,
    };

    if (isAuthenticated) {
      setAlreadyInCart(true);
      await apiCart.sendItemsToDB([
        {
          programId: id,
          status: 1,
        },
      ]);
      showMessageAddedToCart();
      const savedItems = await apiCart.getItems();
      const { data } = savedItems;
      dispatch(setCartItemsLenght(data.length));
    } else {
      if (cookies.programs) {
        let cookie = [...cookies.programs];
        cookie.push(data);
        dispatch(setCartItemsLenght(cookie.length));
        removeCookie("programs");
        setCookies("programs", cookie, { path: "/" });
      } else {
        dispatch(setCartItemsLenght(1));
        setCookies("programs", [data], { path: "/" });
      }
      showMessageAddedToCart();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      apiCart.getItems().then((response) => {
        if (response.status === 200) {
          let filtered = response.data.filter((item) => {
            return item.program.id === id;
          });
          if (filtered.length > 0) {
            setAlreadyInCart(true);
          }
        }
      });
    } else {
      if (cookies.programs !== undefined) {
        cookies.programs.forEach((program) => {
          if (program.program.id === id) {
            setAlreadyInCart(true);
          }
        });
      }
    }
  }, [cookies, id, isAuthenticated]);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Row
            style={{
              background: "#fff",
              padding: isSimilTablet ? "0 8em" : "0 1em",
            }}
          >
            <div
              style={{
                width: isBigScreen ? "1200px" : "100%",
                margin: "auto",
              }}
            >
              <Row>
                <Col
                  span={isSimilTablet ? 16 : 24}
                  flex="auto"
                  className="content-product-ov"
                >
                  <Col flex="auto" className="col-title-prog">
                    <Title className="title-product-detail" level={2}>
                      {program.name}
                    </Title>
                  </Col>

                  {program.ministerialCode && (<Col flex="auto" className="col-cod-min">
                    <Text className="text-cod-min" strong>
                      {t(Product.MinisterialCode)} {program.ministerialCode}
                    </Text>
                  </Col>)}

                  <Col flex="auto">
                    <Image
                      preview={false}
                      className="img-program"
                      src={program.linkImage1}
                    />
                  </Col>
                  <Row>
                    <Col
                      flex="auto"
                      onClick={SelOne}
                      className="col-menu-info-program"
                      span={12}
                    >
                      <Title
                        style={{
                          color: isSelectOne ? "#006755" : "#909090",
                        }}
                        className="text-info-program"
                        level={5}
                      >
                        {t(Product.programDetails)}
                      </Title>
                      <div
                        style={{
                          width: "100%",
                          borderBottom: isSelectOne
                            ? "2px solid #006755"
                            : "1px solid #909090",
                        }}
                      ></div>
                    </Col>
                    <Col
                      onClick={SelOne}
                      className="col-menu-info-program"
                      span={12}
                    >
                      <Title
                        style={{
                          color: isSelectTwo ? "#006755" : "#909090",
                        }}
                        className="text-info-program"
                        level={5}
                      >
                        {t(Product.studyPlan)}
                      </Title>
                      <div
                        style={{
                          width: "100%",
                          borderBottom: isSelectTwo
                            ? "2px solid #006755"
                            : "1px solid #909090",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  {isSelectOne ? (
                    <Col style={{ marginTop: "1em" }}>
                      {terms?.requirements?.length > 0 &&(<Col>
                        <Title level={3}>Requisitos de ingreso</Title>
                        <div className="grid-requi-sys">
                          {terms?.requirements?.map((term) => {
                            return (
                              <div className="inline-requi-sys">
                                <Paragraph className="ph-requeriments">
                                  {term.description}
                                </Paragraph>
                              </div>
                            );
                          })}
                        </div>
                      </Col>)}
                      {terms?.conditions?.length > 0 &&(<Col>
                        <Title level={3}>Condiciones de cursado</Title>
                        <div className="grid-requi-sys">
                          {terms?.conditions?.map((term) => {
                            return (
                              <div className="inline-requi-sys">
                                <Paragraph className="ph-requeriments">
                                  {term.description}
                                </Paragraph>
                              </div>
                            );
                          })}
                        </div>
                      </Col>)}
                      <Col style={{ marginTop: "1em" }}>
                        <Title level={3}>{t(Product.description)}</Title>

                        <Paragraph
                          style={{
                            whiteSpace: "pre-wrap",
                            fontWeight: "normal",
                          }}
                        >
                          {program.description}
                        </Paragraph>
                        <Col style={{ margin: "3em 0" }}>
                          <Collapse bordered={false} collapsible={program.professionalProfile ? "header" : "disabled"}>
                            <Panel
                              className="panel-studyplan"
                              header={t(Product.professionalProfile)}
                              key={0}
                            >
                              <Paragraph
                                style={{
                                  whiteSpace: "pre-wrap",
                                  fontWeight: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {program.professionalProfile}
                              </Paragraph>
                            </Panel>
                          </Collapse>
                          <Collapse bordered={false} collapsible={program.reasonsToStudy ? "header" : "disabled"}>
                            <Panel
                              className="panel-studyplan"
                              header={t(Product.studyInSiglo)}
                              key={1}
                            >
                              <Paragraph
                                style={{
                                  whiteSpace: "pre-wrap",
                                  fontWeight: "normal",
                                }}
                              >
                                {program.reasonsToStudy}
                              </Paragraph>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Col>
                    </Col>
                  ) : (
                    <Col style={{ marginTop: "1em" }}>
                      <Col style={{ margin: "3em 0" }}>
                        <Collapse bordered={false} defaultActiveKey={["0"]}>
                          {program?.studyPlan?.map((studyplan, index) => {
                            return (
                              <Panel
                                className="panel-studyplan"
                                header={studyplan.title}
                                key={index}
                              >
                                <Paragraph
                                  style={{
                                    whiteSpace: "pre",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {studyplan.detail.replaceAll("**", "")}
                                </Paragraph>
                              </Panel>
                            );
                          })}
                        </Collapse>
                      </Col>
                    </Col>
                  )}
                </Col>

                {isSimilTablet && (
                  <Col span={8}>
                    <Affix offsetTop={70}>{cardDesktop()}</Affix>
                  </Col>
                )}
              </Row>
            </div>
          </Row>

          <Layout className="second-row-program">
            <Row>
              <Col className="col-title-program-rec">
                <Title className="title-program-rec" level={3}>
                  {t(Product.otherPrograms)}
                </Title>
                <Title level={3} className="interest">
                  {t(Product.ourRecommended)}
                </Title>
              </Col>
            </Row>
            <ListCarousel
              setPrograms={false}
              text={false}
              q={isSimilTablet ? 3 : 1}
            />
          </Layout>
          {!isSimilTablet && cardMobile()}
        </>
      )}
    </>
  );
};

export default ProductSite;
