import { useState } from "react";

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onSuccess = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  const onError = (error) => {
    if (error.code === 1) {
      setError(
        "El permiso de ubicación está desactivado. Por favor vuelva a activarlo o contacte a un administrador."
      );
    } else {
      setError(error.message);
    }
  };

  const getLocation = () => {
    const geoNav = navigator.geolocation;
    if (!geoNav) {
      setError("Geolocation is not supported");
      return;
    } else {
      setError("");
    }
    geoNav.getCurrentPosition(onSuccess, onError);
  };

  const hasActivePosition = () => {
    return Object.keys(position).length > 0;
  };

  return { position, error, getLocation, hasActivePosition };
};
