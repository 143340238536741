import api from "./api";

const prefix = "programs";

export const apiPrograms = {
  getProgramsFilter: function (programTypes, modalities, studyFields, page, pageSize) {
    return api.get(`${prefix}`, {
      params: {
        types_of_programs: programTypes,
        modalities: modalities,
        fields_of_study: studyFields,
        page,
        pageSize,
      },
    });
  },
  getProgramsAll: function (pageSize) {
    return api.get(`${prefix}`, {
      params: {
        pageSize,
      },
    });
  },
  getProgramsByCareerIds: function (career_ids) {
    return api.get(`${prefix}`, {
      params: {
        career_ids
      },
    });
  },
  getProgramsId: function (id) {
    return api.get(`${prefix}/${id}`);
  },
  getProgramsName: function (n, page, pageSize) {
    return api.get(`${prefix}`, {
      params: {
        name: n,
        page,
        pageSize,
      },
    });
  },

  getSuggestionsSearch: function () {
    return api.get(
      `${prefix}/search-suggestions`
    );
  },

  getAllTypeOfProgram: function () {
    return api.get(`program-types`);
  },

  getAllStudyFields: function () {
    return api.get(`study-fields`);
  },
  getProgramsByTypeOfProgram: function (id) {
    return api.get(`${prefix}/type_of_programs/${id}`);
  },

  getCausFromLocation: function (programId, attendanceId, longitude, latitude) {
    return api.get(
      `${prefix}/${programId}/attendance-mode/${attendanceId}/caus`,
      {
        params: {
          longitude,
          latitude,
        },
      }
    );
  },

  getTurnsByPlace: function (placeId) {
    return api.get(`${prefix}/turns?placeId=${placeId}`);
  },

  getAttendances: function (programId) {
    return api.get(`${prefix}/${programId}/attendance-modes`);
  },

  getTermsByProgram: function (programId) {
    return api.get(`${prefix}/${programId}/terms`);
  },

  getCourseModesByAttendanceAndCau: function (programId, attendanceId, cauId) {
    return api.get(
      `${prefix}/${programId}/attendance-mode/${attendanceId}/cau/${cauId}/courseModes`
    );
  },
};
