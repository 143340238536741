export let Home = {
  Header: {
    upperbar:
      "Universidad Siglo 21, La Universidad Privada más elegida de Argentina",
    university: "La Universidad",
    academics: "Oferta Academica",
    comunity: "Comunidad",
    student: "Estudiante",
    getsearch: "¿Que te gustaría aprender?",
    searchTitle: "Buscá el curso o carrera que prefieras",
    searchBtn: "Buscar",
  },

  Login: {
    login: "Iniciar Sesion",
    email: "Correo Electronico",
    emailError: "Por favor ingres un nombre de usuario",
    password: "Contraseña",
    pwError: "Por favor, ingrese una contraseña",
    pwRecovery: "Olvidaste tu contraseña?",
    gotAccount: "¿Aun no tienes cuenta?",
    register: "Registrate.",
  },

  Home: {
    inscriptions: "Inscripciones Abiertas",
    filterTitle: "Encontrá los programas en que deseas inscribirte",
    filterButton: "Buscar",
    typeOfProgram: "Tipo de Programa",
    nationalCertification: "Certificacion Nacional",
    internationalCertification: "Certificacion Internacional",
    studyArea: "Area de Estudio",
    development: "Desarrollo",
    bussines: "Negocios",
    mode: "Modalidad",
    distance: "A distancia",
    onSite: "Presencial",
    both: "Ambas",
    knowOurs: "Conocé nuestros ",
    coursesAndCareers: "programas y carreras",
    youMayInterest: "Te puede interesar",
    more: "Cargar mas programas",
    others: "Otras",
    opinionsTitle: "Lo que opinan nuestros estudiantes",
  },

  Card: {
    more: "Ver más",
    duration: "2 años y medio",
    durationAlt: "Duración",
    userAlt: "Usuario",
    mode: "Modalidad:",
  },

  Carousel: {
    may: "Te puede ",
    interestYou: "interesar",
    international: "Certificacion International",
    career: "Carreras de grado",
    specializations: "Especializaciones",
    certificates: "Diplomaturas y Certificaciones",
  },
};

export let Courses = {
  title: "Nuestros Cursos y Carreras",
  titleMap: "Inicio > Nuestros cursos y carreras",
  loadingResources: "Cargando resultados...",
  resourcesFound: " Recursos encontrados",
  notFound: "No se encontraron resultados",
  tryAgain: "Intenta nuevamente con otras opciones en el filtro",
  typeOfProgram: "Tipos de Programa",
  nationalCertification: "Certificacion Nacional",
  internationalCertification: "Certificacion Internacional",
  studyArea: "Area de Estudio",
  development: "Desarrollo",
  bussines: "Negocios",
  mode: "Modalidad",
  distance: "A distancia",
  onSite: "Presencial",
};

export let cart = {
  cartTitle: "Me interesa",
  map: "Inicio > Me Interesa",
  colored: "programas o carreras",
  title: " te interesan",
  suscribe: "¿Qué esperás para inscribirte?",

  status: [
    {
      button: "Ver arancel",
      tag: null,
      link: "/checkout",
      studentRecordButton: "",
    },
    {
      button: "Inscribirme",
      tag: "Precio simulado",
      link: "/completeInscription",
      studentRecordButton: "",
    },
    {
      button: "Pagar",
      tag: "Pre inscripto",
      link: "/finish-inscription/cart",
      studentRecordButton: "",
    },
    {
      button: "Ver legajo",
      tag: "Inscripto",
      link: "/finish-inscription/cart",
      studentRecordButton: "true",
    },
  ],

  emptyCart: {
    emptyCartTitle: "No tenés programas o carreras agregadas",
    emptyCartCta: "Ver programas y carreras",
  },
};

export let faqsTexts = {
  faqsTitle: "Preguntas Frecuentes",
  map: "Inicio > Preguntas Frecuentes",
  colored: "frecuentes",
  title: "Preguntas",
  returnToHome: "Regresar al Inicio"
};

export let Checkout = {
  inscriptionDetailCard: {
    loading: "Cargando...",
    title: "Detalle de la inscripción",
    careerDesc: "Programa o carrera",
    desglosePrice: "Desglose del precio",
    factor1:
      "El precio que se muestra en pantalla es referencial y solo para ingesantes. Los descuentos y promociones quedan sujetos a disponibilidad.",
    factor2: " Para más información sobre la reglamentación arancelaria,",
    factorLink: "hacé click acá.",
    enrollment: "Matrícula:",
    tariff: "Arancel:",
    bonusRegister: "Si te registrás:",
    bonusFriend: "Amigo referido:",
    totalEnrollment: "Total Matrícula:",
    code: "Cod. descuento:",
    totalTariff: "Total Arancel:",
    total: "Total",
    share: "Cuotas",
    shareAccount: "cuotas de",
    requirements:
      "Declaro que cumplo con los requisitos para acceder a este programa o carrera:",
    secondary: "Secundario completo",
    certificate: "Cuento con certificado analítico definitivo o en trámite",
    documentation:
      "Enviaré copias de la documentación que respalda el cumplimiento de los requisitos antes mencionados en un plazo de 60 dias.",
    inscription: "Quiero inscribirme",
    setpModalityTitle: "Modalidad",
    setpModalityDescription: "Elige una modalidad.",
    setpCAUTitle: "CAU",
    setpCAUDescription: "Selecciona un CAU.",
    setpTurnTitle: "Turno",
    setpTurnDescription: "Selecciona un turno",

    turnSelected: "Turno a elegir",
    flexMessageError: "Ups!, hemos tenido un problema y no podemos mostrarte correctamente la oferta. Intenta nuevamente más tarde.",
  },

  bannerCheckout: {
    title: "Aranceles del Programa o Carrera",
    titleMap: "Inicio > Aranceles del programa o carrera",
  },
  bonusCard: {
    loading: "Cargando...",
    referTitle: "¿Conocés a alguien que estudie en Siglo 21?",
    referSubtitle: "Obtené un 10% de descuento sobre tu arancel. (aplica para carreras de grado y pregrado).",
    referInput: "Indicar Legajo",
    couponTitle: "¿Tenés algún cupón de descuento?",
    couponSubtitle: "Obtené un descuento extra sobre tu arancel",
    couponInput: "Indicar código",
    button: "Aplicar",
  },
  modalityCareerCard: {
    loading: "Cargando...",
    titleCard: "¿En qué modalidad te gustaría estudiar?",
    onlineTab: "Online",
    notExistCareer: "No existen modalidades para la carrera.",
    faceToFaceTab: "Presencial",
  },
  placeCareerCard: {
    loading: "Cargando...",
    titleCard: "¿Donde querés estudiar?",
    locationActive: "Activar ubicación",
    warningNotExist:
      "No existen centros de aprendizaje para tu ubicación, por favor probá con otra carrera.",
    warningNotExistButED:
      "No existen Centros de Aprendizaje cercanos para tu ubicación. Llamá al 08105550202 para ver otras opciones.",
    warningLocationOnline:
      "Activá tu ubicación para conocer los Centros de Aprendizaje cercanos.",
    warningLocation:
      "Activá tu ubicación para conocer más Centros de Aprendizaje cercanos.",
  },
  paymentCard: {
    titleCard: "Métodos de pago disponibles",
    creditCardTab: "Tarjeta de crédito",
    creditCard: "Tarjeta de crédito en cuotas",
    debitCard: "Tarjeta de débito en un pago",
    cash: "Efectivo en un pago",
  },
};

export let Product = {
  imImterested: "Me interesa",
  duplicatedItem: "Ya esta en el carrito",
  MinisterialCode: "Resolución Ministerial:",
  programDetails: "Detalle del carrera",
  studyPlan: "Plan de Estudios",
  description: "Descripción",
  feature: "Características",
  professionalProfile: "Perfil Profesional",
  studyInSiglo: "Por qué estudiar esta carrera en Siglo 21",
  duration: "Duración",
  modality: "Modalidad",
  otherPrograms: "Otros programas que",
  ourRecommended: "recomendamos para vos",
};

export let Signin = {
  bannerTitle: "Iniciar sesión",
  bannerSubtitle: "Inicio > Aranceles del programa o carrera > Iniciar sesión",
  returnButtonText: "Modificar mi inscripción",
  loginButton: "Iniciar Sesión",
  registerButton: "Registrarse",
  registerForm: {
    registerFormTitle: "Crear cuenta",
    registerFormSubtitle:
      "Para poder inscribirte el programa o carrera de tu preferencia, debes registrarte en nuestro sistema, ¡es muy sencillo!",
    name: "Nombre",
    surname: "Apellido",
    email: "Correo electrónico",
    phone: "Teléfono",
    password: "Contraseña",
    repassword: "Repetir Contraseña",
    submitButton: "Crear cuenta",
  },
  loginForm: {
    loginFormTitle: "Iniciar sesión",
    loginFormSubtitle:
      "Para poder inscribirte el programa o carrera de tu preferencia, debes iniciar sesión en nuestro sistema, ¡es muy sencillo!",
    email: "Correo electrónico",
    password: "Contraseña",
    submitButton: "Iniciar sesión en mi cuenta",
  },
};

export let CompletePage = {
  bannerTitle: "Resumen de la Inscripción",
  bannerSubtitle: "Inicio > Aranceles del programa o carrera > Completar datos",
  returnButtonText: "Modificar mi inscripción",
};

export let ProfileSiglo = {
  welcome: "Bienvenido/a,",
  logout: "Log Out",
  personalData: "Datos Personales",
  name: "Nombre",
  lastName: "Apellido",
  email: "Correo Electrónico",
  telephone: "Teléfono",
  birthDate: "Fecha de Nacimiento",
  documentNumber: "Número de Documento",
  updateData: "Actualizar datos",
  saveData: "Guardar datos",
};
