import api from "./api";

const prefix = "user";

export const apiUser = {
  createUser: (body) => {
    return api.post(`${prefix}`, body);
  },
  getUserDetail: (usrPortal) => {
    return api.get(`${prefix}/${usrPortal}`);
  },
}
