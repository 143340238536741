import React from "react";
import SocialMedia from "../../socialMedia";
import { Layout, Typography, Row, Col, Image, Collapse } from "antd";
import coneru from "../../../assets/logos/coneru.png";
import aa from "../../../assets/logos/aa.png";
import recla from "../../../assets/logos/recla.png";
import barca from "../../../assets/logos/barca.png";
import gl from "../../../assets/logos/gl.png";
import cladea from "../../../assets/logos/cladea.png";

import { FaPhoneAlt } from "react-icons/fa";

import style from "./index.module.scss";
import logo from "../../../assets/s21-alt.webp";
import { useMediaQuery } from "react-responsive";
import { CaretRightOutlined, DownOutlined } from "@ant-design/icons";
import "./index.mobile.css";

const { Footer } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

const OwnFooter = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 });
    return isNotMobile ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 });
    return isMobile ? children : null;
  };

  return (
    <>
      <Default>
        <Footer className={style.footer}>
          <div
            style={{ width: isBigScreen ? "1300px" : "100%", margin: "0 auto" }}
          >
            <Row className={style.logoContainer}>
              <Col>
                <Image src={logo} preview={false} />
              </Col>
            </Row>
          </div>
          <div
            style={{
              width: isBigScreen ? "1300px" : "100%",
              margin: "0 auto",
            }}
          >
            <Row className={style.linksContainer}>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>CONTACTANOS</Text>

                <Col className={style.contactInfo}>
                  <Text className={style.info}>Informes e Inscripciones</Text>
                  <Text className={style.info}>
                    <FaPhoneAlt /> 0810-555-0202
                  </Text>
                </Col>
                <Col className={style.contactInfo}>
                  <Text className={style.info}>Postgrados y Cursos</Text>
                  <Text className={style.info}>
                    <FaPhoneAlt /> 0810-555-0202
                  </Text>
                </Col>
                <Col className={style.contactInfo}>
                  <Text className={style.info}>Atención Alumnos</Text>
                  <Text className={style.info}>
                    <FaPhoneAlt /> 0810-8888-3721
                  </Text>
                </Col>
              </Col>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>LA UNIVERSIDAD</Text>
                <a
                  href="https://21.edu.ar/la-universidad/investigacion-1"
                  className={style.links}
                >
                  Investigacion
                </a>
                <a
                  href="https://21.edu.ar/la-universidad/quienes-somos"
                  className={style.links}
                >
                  Quiénes somos
                </a>
                <a
                  href="https://21.edu.ar/sedes-y-centros/"
                  className={style.links}
                >
                  Sedes y Centros
                </a>
              </Col>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>OFERTA EDUCATIVA</Text>
                <a
                  href="https://21.edu.ar/oferta-academica/becas-beneficios-y-pagos"
                  className={style.links}
                >
                  Becas y Beneficios
                </a>
                <a
                  href="https://21.edu.ar/ingreso-e-inscripcion"
                  className={style.links}
                >
                  Ingresos
                </a>
                <a
                  href="https://21.edu.ar/oferta-academica/equivalencias"
                  className={style.links}
                >
                  Equivalencias
                </a>
              </Col>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>NOVEDADES</Text>
                <a
                  href="https://21.edu.ar/identidad21/"
                  className={style.links}
                >
                  Identidad 21
                </a>
                <a href="https://21.edu.ar/noticias/" className={style.links}>
                  Noticias
                </a>
                <a href="https://21.edu.ar/eventos/" className={style.links}>
                  Eventos
                </a>
                <a
                  href="https://21.edu.ar/comunidad/portal-de-empleo"
                  className={style.links}
                >
                  Portal Empleos
                </a>
              </Col>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>COMUNIDAD</Text>
                <a
                  href="http://siglo21.agentbot.net/web/"
                  className={style.links}
                >
                  Asesor Virtual
                </a>
                <a
                  href="https://contenidos.21.edu.ar/microsites/reglamento/"
                  className={style.links}
                >
                  Reglamento
                </a>
                <a href="https://21.edu.ar/estudiantes" className={style.links}>
                  Estudiantes
                </a>
                <a
                  href="https://21.edu.ar/la-universidad/docente"
                  className={style.links}
                >
                  Docentes
                </a>
                <a
                  href="https://21.edu.ar/comunidad/egresados"
                  className={style.links}
                >
                  Egresados
                </a>
                <a href="/#" className={style.links}>
                  Biblioteca
                </a>
                <a href="/#" className={style.links}>
                  Prácticas
                </a>
                <a
                  href="https://21.edu.ar/estudiante/pasantias"
                  className={style.links}
                >
                  Pasantías
                </a>
              </Col>
              <Col className={style.linksColumn}>
                <Text className={style.columnTitle}>ACREDITADO</Text>
                <Row>
                  <Col className={style.accredited}>
                    <Image
                      className={style.imgAcc}
                      preview={false}
                      src={coneru}
                    />
                    <Image className={style.imgAcc} preview={false} src={aa} />
                    <Image
                      className={style.imgAcc}
                      preview={false}
                      src={recla}
                    />
                    <Image
                      className={style.imgAcc}
                      preview={false}
                      src={barca}
                    />
                    <Image className={style.imgAcc} preview={false} src={gl} />
                    <Image
                      className={style.imgAcc}
                      preview={false}
                      src={cladea}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className={style.socialMediaContainer}>
            <div
              style={{
                width: isBigScreen ? "1200px" : "100%",
                margin: "0 auto",
              }}
            >
              <Row className={style.socialMediaContainerInto}>
                <Text className={style.copyright}>Copyright, 2021</Text>
                <SocialMedia />
              </Row>
            </div>
          </Row>
        </Footer>
      </Default>
      <Mobile>
        <Row>
          <Col span={24}>
            <div
              style={{
                padding: "22px 0 12px 0",
                background: "#0d513f",
                textAlign: "center",
              }}
            >
              <Image src={logo} preview={false} />
            </div>
          </Col>
          <Col span={24}>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                showArrow={false}
                header={
                  <>
                    CONTACTANOS <DownOutlined />
                  </>
                }
                key="1"
                className="site-collapse-custom-panel"
              >
                <div className="collapseLink">
                  <p>Informes e Inscripciones</p>
                  <span>
                    <FaPhoneAlt /> 0810-555-0202
                  </span>
                </div>
                <div className="collapseLink">
                  <p>Postgrados y Cursos</p>
                  <span>
                    <FaPhoneAlt /> 0810-555-0202
                  </span>
                </div>
                <div className="collapseLink">
                  <p>Atención Alumnos</p>
                  <span>
                    <FaPhoneAlt /> 0810-8888-3721
                  </span>
                </div>
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <>
                    LA UNIVERSIDAD <DownOutlined />
                  </>
                }
                key="2"
                className="site-collapse-custom-panel"
              >
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/la-universidad/investigacion-1"
                    className={style.links}
                  >
                    Investigacion
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/la-universidad/quienes-somos"
                    className={style.links}
                  >
                    Quiénes somos
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/sedes-y-centros/"
                    className={style.links}
                  >
                    Sedes y Centros
                  </a>
                </div>
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <>
                    OFERTA EDUCATIVA <DownOutlined />
                  </>
                }
                key="3"
                className="site-collapse-custom-panel"
              >
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/oferta-academica/becas-beneficios-y-pagos"
                    className={style.links}
                  >
                    Becas y Beneficios
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/ingreso-e-inscripcion"
                    className={style.links}
                  >
                    Ingresos
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/oferta-academica/equivalencias"
                    className={style.links}
                  >
                    Equivalencias
                  </a>
                </div>
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <>
                    NOVEDADES <DownOutlined />
                  </>
                }
                key="4"
                className="site-collapse-custom-panel"
              >
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/identidad21/"
                    className={style.links}
                  >
                    Identidad 21
                  </a>
                </div>
                <div className="collapseLink">
                  <a href="https://21.edu.ar/noticias/" className={style.links}>
                    Noticias
                  </a>
                </div>
                <div className="collapseLink">
                  <a href="https://21.edu.ar/eventos/" className={style.links}>
                    Eventos
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/comunidad/portal-de-empleo"
                    className={style.links}
                  >
                    Portal Empleos
                  </a>
                </div>
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <>
                    COMUNIDAD <DownOutlined />
                  </>
                }
                key="5"
                className="site-collapse-custom-panel"
              >
                <div className="collapseLink">
                  <a
                    href="http://siglo21.agentbot.net/web/"
                    className={style.links}
                  >
                    Asesor Virtual
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://contenidos.21.edu.ar/microsites/reglamento/"
                    className={style.links}
                  >
                    Reglamento
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/estudiantes"
                    className={style.links}
                  >
                    Estudiantes
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/la-universidad/docente"
                    className={style.links}
                  >
                    Docentes
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/comunidad/egresados"
                    className={style.links}
                  >
                    Egresados
                  </a>
                </div>
                <div className="collapseLink">
                  <a href="/#" className={style.links}>
                    Biblioteca
                  </a>
                </div>
                <div className="collapseLink">
                  <a href="/#" className={style.links}>
                    Prácticas
                  </a>
                </div>
                <div className="collapseLink">
                  <a
                    href="https://21.edu.ar/estudiante/pasantias"
                    className={style.links}
                  >
                    Pasantías
                  </a>
                </div>
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <>
                    ACREDITADO <DownOutlined />
                  </>
                }
                key="6"
                className="site-collapse-custom-panel"
              >
                <Row>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={coneru} />
                  </Col>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={aa} />
                  </Col>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={recla} />
                  </Col>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={barca} />
                  </Col>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={gl} />
                  </Col>
                  <Col span={4}>
                    <Image className="imgAcc" preview={false} src={cladea} />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
          <Col
            span={24}
            style={{
              backgroundColor: "#002119",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <span style={{ color: "white", marginBottom: "100px" }}>
              Copyright, 2021
            </span>
            <SocialMedia />
          </Col>
        </Row>
      </Mobile>
    </>
  );
};

export default React.memo(OwnFooter);
