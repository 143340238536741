import { notification, Space, Typography, Row } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
  InfoOutlined,
} from "@ant-design/icons";

export const NOTIFICATION_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warn",
  INFO: "info",
};

export const showNotification = (type, text) => {
  let icon;
  let title;
  switch (type) {
    case NOTIFICATION_TYPES.ERROR:
      title = "¡Error!";
      icon = (
        <CloseOutlined
          className={`notification-icon notification-icon-${type}`}
        />
      );
      break;
    case NOTIFICATION_TYPES.SUCCESS:
      title = "¡Éxito!";
      icon = (
        <CheckOutlined
          className={`notification-icon notification-icon-${type}`}
        />
      );
      break;
    case NOTIFICATION_TYPES.WARNING:
      title = "¡Cuidado!";
      icon = (
        <ExclamationOutlined
          className={`notification-icon notification-icon-${type}`}
        />
      );
      break;
    case NOTIFICATION_TYPES.INFO:
      title = "¡Atención!";
      icon = (
        <InfoOutlined
          className={`notification-icon notification-icon-${type}`}
        />
      );
      break;
    default:
      icon = <></>;
      title = "";
      break;
  }
  notification[type]({
    key: text,
    icon: <></>,
    duration: 5,
    message: "",
    closeIcon: <CloseOutlined />,
    className: `notification-container notification-container-${type}`,
    description: (
      <Space>
        {icon}
        <Row className="notification-content">
          <Typography.Text className="notification-text">
            <Typography.Text className="notification-title">
              {title}
            </Typography.Text>{" "}
            {text}
          </Typography.Text>
        </Row>
      </Space>
    ),
    placement: "topRight",
  });
};
