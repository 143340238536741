import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Home } from "../../i18n/locales/es/text";
import { Form, Select, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";
import { AbstractCard } from "../cards";
import { Link } from "react-router-dom";
import { CustomButton } from "../CustomButton";
import { apiPrograms } from "../../api";
import style from "./main.module.scss";
import { AttendanceModeEnum } from "../../sites/checkout/components/ModalityCareerCard/attendance-mode.enum";
import React from "react";
import { capitalize } from "../../helpers/stringHelper";
import { useMediaQuery } from "react-responsive";
import { CarouselMobile } from "./CarouselMobile";

const { Title, Text } = Typography;
const { Option } = Select;

export const MainHome = ({ setValue }) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1225px)" });
  const [loading, setLoading] = useState(true);
  const [muestra, setMuestra] = useState(null);
  const { t } = useTranslation();

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 });
    return isNotMobile ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 });
    return isMobile ? children : null;
  };

  useEffect(() => {
    apiPrograms
      .getProgramsByCareerIds(['8802','8738','8720','8722','3682','4002'])
      .then((response) => {
        const { programs } = response.data;
        setMuestra(programs);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const SearchHome = () => {
    //Tipo de programa
    const [loadingP, setLoadingP] = useState(true);
    const [ptys, setPtys] = useState();

    //Area de estudios
    const [loadingSF, setLoadingSF] = useState(true);
    const [stField, setStField] = useState();

    const [form] = Form.useForm();
    let history = useHistory();

    useEffect(() => {
      apiPrograms
        .getAllTypeOfProgram()
        .then((response) => {
          setPtys(response.data);
          setLoadingP(false);
        })
        .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
      apiPrograms
        .getAllStudyFields()
        .then((response) => {
          setStField(response.data);
          setLoadingSF(false);
        })
        .catch((error) => console.error(error));
    }, []);

    const onFinish = (values) => {
      if (values["program-type"] === undefined) {
        delete values["program-type"];
      }
      if (values["study-area"] === undefined) {
        delete values["study-area"];
      }
      if (values["modality"] === undefined) {
        delete values["modality"];
      }
      history.push({
        pathname: "/courses",
        search: "?" + new URLSearchParams(values).toString(),
      });
    };

    return (
      <>
        <Default>
          <Form
            className={style.form}
            form={form}
            name="form-filter"
            onFinish={onFinish}
          >
            <Form.Item className={style.input} name="program-type">
              <Select placeholder={t(Home.Home.typeOfProgram)} allowClear>
                {loadingP ? (
                  <div>Cargando...</div>
                ) : (
                  ptys?.map((pty) => {
                    return (
                      <Option key={pty.id} value={pty.id}>
                        {pty.name}
                      </Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
            <Form.Item className={style.input} name="study-area">
              <Select placeholder={t(Home.Home.studyArea)} allowClear>
                {loadingSF ? (
                  <div>Cargando...</div>
                ) : (
                  stField?.map((stField) => {
                    return (
                      <Option key={stField.id} value={stField.id}>
                        {stField.name}
                      </Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
            <Form.Item className={style.input} name="modality">
              <Select placeholder={t(Home.Home.mode)} allowClear>
                {Object.entries(AttendanceModeEnum).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {capitalize(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className={style.cta}>
              <CustomButton
                style={{ height: "100%" }}
                type="primary"
                htmlType="submit"
                block
              >
                {t(Home.Home.filterButton)}
              </CustomButton>
            </Form.Item>
          </Form>
        </Default>
        <Mobile>
          <Form
            className={style.form}
            form={form}
            name="form-filter"
            onFinish={onFinish}
          >
            <Form.Item className={style.input} name="program-type">
              <Select placeholder={t(Home.Home.typeOfProgram)} allowClear>
                {loadingP ? (
                  <div>Cargando...</div>
                ) : (
                  ptys?.map((pty) => {
                    return (
                      <Option key={pty.id} value={pty.id}>
                        {pty.name}
                      </Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
            <Form.Item className={style.input} name="study-area">
              <Select placeholder={t(Home.Home.studyArea)} allowClear>
                {loadingSF ? (
                  <div>Cargando...</div>
                ) : (
                  stField?.map((stField) => {
                    return (
                      <Option key={stField.id} value={stField.id}>
                        {stField.name}
                      </Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
            <Form.Item className={style.input} name="modality">
              <Select placeholder={t(Home.Home.mode)} allowClear>
                {Object.entries(AttendanceModeEnum).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {capitalize(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className={style.cta}>
              <CustomButton
                style={{ height: "100%" }}
                type="primary"
                htmlType="submit"
                block
              >
                {t(Home.Home.filterButton)}
              </CustomButton>
            </Form.Item>
          </Form>
        </Mobile>
      </>
    );
  };

  return (
    <>
      <Default>
        <Row className={style.filterContainer}>
          <Col className={style.formTitle}>
            <Title className={style.mainTitle}>
              {t(Home.Home.inscriptions)}
            </Title>
            <Text className={style.subTitle}>{t(Home.Home.filterTitle)}</Text>
            <SearchHome setValue={setValue} />
          </Col>
        </Row>

        <div
          style={{
            backgroundColor: "#f7f7f7",
            padding: "0 3em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Title className={style.title}>
            {t(Home.Home.knowOurs)}
            <Text className={style.interest}>
              {t(Home.Home.coursesAndCareers)}
            </Text>
          </Title>

          {loading ? (
            <div>Cargando...</div>
          ) : muestra.length > 0 ? (
            <>
              <div style={{ width: isBigScreen ? "1224px" : "100%" }}>
                <Row justify="center">
                  {muestra.map((data) => {
                    return <AbstractCard span={8} key={data.id} {...data} />;
                  })}
                </Row>
              </div>
              <Col className={style.cardContainerFooter}>
                <Link to="/courses">
                  <CustomButton
                    style={{ margin: "50px 0" }}
                    block
                    type="primary"
                  >
                    Ver mas programas y carreras
                  </CustomButton>
                </Link>
              </Col>
            </>
          ) : (
            <div>Error</div>
          )}
        </div>
      </Default>
      <Mobile>
        <Row className={style.filterContainerMobile}>
          <Col className={`${style.formTitle} ${style.greyOverlay}`}>
            <Title className={style.mainTitle}>
              {t(Home.Home.inscriptions)}
            </Title>
            <Text className={style.subTitle}>{t(Home.Home.filterTitle)}</Text>
          </Col>
          <Col className={style.formTitle}>
            <SearchHome setValue={setValue} />
          </Col>
        </Row>
        <CarouselMobile q={1} />
      </Mobile>
    </>
  );
};
