import { useEffect } from "react";
import imageSigninbg from "../../assets/signin-banner.jpg";
import { useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Form,
  Image,
  Select,
  message,
  Spin,
  Popover,
} from "antd";
import "./index.register.scss";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Signin } from "../../i18n/locales/es/text";
import { ArrowLeftOutlined, CopyOutlined } from "@ant-design/icons";
import { CustomButton } from "../../components";
import HeaderComponent from "../../components/header";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import check from "../../assets/check.svg";
import { apiLocation } from "../../api";
import { apiUser } from "../../api/user";
import { useMediaQuery } from "react-responsive";
import { showMessageRegister } from "./components/messageRegister";
import { useAuth } from "../../common/auth/auth.context";
import React from "react";
const { Option } = Select;

const { Title, Text } = Typography;
const dateFormat = 'DD/MM/YYYY';

export const BannerSignin = () => {
  const { t } = useTranslation();

  // const Default = ({ children }) => {
  //   const isNotMobile = useMediaQuery({ minWidth: 1224 })
  //   return isNotMobile ? children : null
  // }

  // const Mobile = ({ children }) => {
  //   const isMobile = useMediaQuery({ maxWidth: 1223 })
  //   return isMobile ? children : null
  // }

  return (
    <>
      <HeaderComponent imgBg={imageSigninbg}>
        <Title style={{ color: "white" }}>{t(Signin.bannerTitle)}</Title>
        <Text style={{ color: "white", marginBottom: "20px" }}>
          {t(Signin.bannerSubtitle)}
        </Text>
      </HeaderComponent>
    </>
  );
};

const RegisterPage = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const [openModal, setOpenModal] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [infoUser, setInfoUser] = useState();
  const [stateId, setStateId] = useState(null);
  const [codeState, setCodeState] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [loadingCity, setLoadingCity] = useState(true);
  const [usernameCopied, setUsernameCopy] = useState(true);
  const [documentNumber, setDocumentNumber] = useState(true);
  const [cityId, setCityId] = useState();
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const { login } = useAuth();

  const content = <div>Click para continuar</div>;

  const isExtraSmallScreen = useMediaQuery({ maxWidth: 575 });

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 });
    return isNotMobile ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 });
    return isMobile ? children : null;
  };

  useEffect(() => {
    apiLocation
      .getProvinces()
      .then((response) => {
        setStateId(response.data);
        setLoadingState(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSelect = (id) => {
    setCodeState(true);
    apiLocation
      .getLocations(id)
      .then((response) => {
        setCityId(response.data);
        setLoadingCity(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const goToCheckout = () => {
    history.push(`/checkout/${id !== undefined ? id : ""}`);
  };

  // const goToSignIn = () => {
  //   history.push(`/signin/${id !== undefined ? id : ""}`);
  // };

  const createUser = (user) => {
    const convertDate = (inputFormat) => {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(
        "-"
      );
    };
    const infoOfUser = {
      name: user.name,
      lastName: user.lastname,
      primaryEmail: user.email,
      contactPhone: `54 ${user.phone}`,
      documentNumber: Number(user.dni),
      birthDate: convertDate(user.dateBirth._d), //07-12-2021
      locationId: Number(user.cityId),
      provinceId: Number(user.stateId),
      cauId: 83,
    };
    return infoOfUser;
  };

  const sortLocation = (a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  };

  const handleCopyUsername = (username) => {
    navigator.clipboard.writeText(username);
    setUsernameCopy(false);
    showMessageRegister();
  };

  const onSubmit = async (value) => {
    setDocumentNumber(value.dni);
    setLoadingCreate(true);
    setOpenModal(true);
    try {
      const response = await apiUser.createUser(createUser(value));
      if (response.status === 200) {
        setInfoUser(response.data);
        setLoadingCreate(false);
      } else {
        setLoadingCreate(false);
        setOpenModal(false);
        message.error(
          "Se produjo un error al crear el usuario, intentá más tarde por favor."
        );
      }
    } catch (e) {
      setLoadingCreate(false);
      setOpenModal(false);
      message.error(
        "Se produjo un error al crear el usuario, intentá más tarde por favor."
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOk = () => {
    setOpenModal(false);
    history.push(`/signin/${id !== undefined ? id : ""}`);
  };

  const handleLogin = () => {
    setOpenModal(false);
    login();
  };

  const ConfirmModal = ({ user, loadingCreate }) => {
    return (
      <>
        {loadingCreate ? (
          <Modal
            style={{ textAlign: "center" }}
            title={<div></div>}
            centered
            visible={openModal}
            onOk={handleOk}
            closable={false}
            width={400}
            footer={<div></div>}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Spin style={{ transform: "scale(1.5)" }} size="large" />
              <Title style={{ marginTop: "1.3em" }} level={3}>
                Estamos creando tu usuario
              </Title>
            </div>
          </Modal>
        ) : (
          <Modal
            style={{ textAlign: "center" }}
            title={
              <div>
                <Image preview={false} src={check} />
                <Title level={3}>Registro exitoso</Title>
                <Text style={{ fontWeight: "normal" }}>
                  Guardá <strong>tus credenciales</strong>, las necesitarás para
                  iniciar sesión:
                </Text>
              </div>
            }
            centered
            visible={openModal}
            onCancel={handleClose}
            onOk={handleOk}
            width={400}
            closable={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  style={{ padding: "0.5em 3em", marginBottom: "8px" }}
                  block={false}
                  type="primary"
                  onClick={handleLogin}
                  disabled={usernameCopied}
                >
                  Iniciar Sesión
                </CustomButton>
              </div>
            }
          >
            <p style={{ fontSize: "16px" }}>
              Usuario:{" "}
              <span style={{ fontWeight: "normal" }}>{user?.usrPortal}</span>
              <Popover content={content} trigger="hover" placement="right">
                <Button className="copy-button">
                  <CopyOutlined
                    onClick={() => {
                      handleCopyUsername(user?.usrPortal);
                    }}
                  />
                </Button>
              </Popover>
            </p>
            <p style={{ fontSize: "16px" }}>
              Contraseña provisoria:{" "}
              <span style={{ fontWeight: "normal" }}>{documentNumber}</span>
            </p>
          </Modal>
        )}
      </>
    );
  };
  const fechaValida = (rule, value, callback) => {
    if(value) {
      if(value._d < new Date()) callback();
      else {
        callback(rule.message);
      }
    } else callback();
  }
  const RegisterForm = () => {
    const Default = ({ children }) => {
      const isNotMobile = useMediaQuery({ minWidth: 1224 });
      return isNotMobile ? children : null;
    };

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1223 });
      return isMobile ? children : null;
    };

    return (
      <>
        <Default>
          {/* Register form */}
          <Row style={{ justifyContent: "center", marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "35px",
                color: "#006755",
                fontWeight: "bold",
              }}
            >
              {t(Signin.registerForm.registerFormTitle)}
            </Text>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Text
              style={{
                marginBottom: "2em",
                textAlign: "center",
                maxWidth: "473px",
                fontSize: "16px",
                color: "#464646",
              }}
            >
              {t(Signin.registerForm.registerFormSubtitle)}
            </Text>
          </Row>
          <Form form={form} onFinish={onSubmit}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text className={"inputTextUser"}>Nombre</Text>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true, message: "Por favor, ingrese su nombre"
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/
                      ),
                      message:
                        "El nombre es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>Apellido</Text>
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese su apellido",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/
                      ),
                      message:
                        "El apellido es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Text className={"inputTextUser"}>Correo Electronico</Text>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Por favor, ingrese su email" },
                    {
                      type: "email",
                      message: "El E-mail es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>Telefono</Text>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese su telefono",
                    },
                    {
                      pattern: new RegExp(
                        /^((\+54)?(11|\d{2})\d{6,8}|(\+54)?(2[01]|3[0-5]|6[0-9])\d{7})$/
                      ),
                      message:
                        "El Telefono es invalido. Por favor, asegurese de quitar el '0' y el '15'",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>Numero de DNI</Text>
                <Form.Item
                  name="dni"
                  rules={[
                    { required: true, message: "Por favor, ingrese su DNI" },
                    {
                      pattern: new RegExp(
                        /^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$/
                      ),
                      message: "El DNI es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>Fecha de Nacimiento</Text>
                <Form.Item
                  name="dateBirth"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecciona su fecha de nacimiento",
                    },
                    {
                      validator: fechaValida,
                      message: "La fecha es invalida",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={"Selecciona la fecha"}
                    className={"datepickProfile"}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>
                  Provincia de tu domicilio
                </Text>
                {loadingState ? (
                  <Form.Item
                    name="stateId"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecciona al menos una provincia",
                      },
                    ]}
                  >
                    <Select
                      disabled
                      placeholder="Cargando..."
                      className={"inputProfileLocation"}
                    ></Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="stateId"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecciona al menos una provincia",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      placeholder="Selecciona la provincia"
                      className={"inputProfileLocation"}
                      onChange={(e) => onSelect(e)}
                    >
                      {stateId
                        .filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex(
                              (t) => t.provinceCode === value.provinceCode
                            )
                        )
                        .sort(sortLocation)
                        .map((state) => {
                          return (
                            <Option value={state.provinceCode}>
                              {state.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Text className={"inputTextUser"}>
                  Localidad de tu domicilio
                </Text>
                <Form.Item
                  name="cityId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecciona al menos una ciudad",
                    },
                  ]}
                >
                  {codeState === false ? (
                    <Select
                      disabled
                      className={"inputProfileLocation"}
                      placeholder={"Selecciona antes una provincia"}
                    ></Select>
                  ) : loadingCity ? (
                    <Select
                      className={"inputProfileLocation"}
                      placeholder="Cargando..."
                    ></Select>
                  ) : (
                    <Select
                      placeholder="Selecciona la ciudad"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      className={"inputProfileLocation"}
                    >
                      {cityId.locations
                        .filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex(
                              (t) => t.locationCode === value.locationCode
                            )
                        )
                        .sort(sortLocation)
                        .map((city) => {
                          return (
                            <Option value={city.locationCode}>
                              {city.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col style={{ marginTop: "1em" }} span={6} offset={18}>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "172px",
                      height: "47px",
                      float: "right",
                    }}
                  >
                    {t(Signin.registerForm.submitButton)}
                  </CustomButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Default>
        <Mobile>
          {/* Register form */}
          <Row style={{ justifyContent: "center", marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "35px",
                color: "#006755",
                fontWeight: "bold",
              }}
            >
              {t(Signin.registerForm.registerFormTitle)}
            </Text>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Text
              style={{
                textAlign: "center",
                maxWidth: "473px",
                fontSize: "16px",
                color: "#464646",
              }}
            >
              {t(Signin.registerForm.registerFormSubtitle)}
            </Text>
          </Row>
          <Form form={form} onFinish={onSubmit}>
            <Row>
              <Col span={24}>
                <Text className={"inputTextUser"}>Nombre</Text>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true, message: "Por favor, ingrese su nombre"
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/
                      ),
                      message:
                        "El nombre es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>Apellido</Text>
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese su apellido",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/
                      ),
                      message:
                        "El apellido es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Text className={"inputTextUser"}>Correo Electronico</Text>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Por favor, ingrese su email" },
                    {
                      type: "email",
                      message: "El E-mail es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>Telefono</Text>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese su telefono",
                    },
                    {
                      pattern: new RegExp(
                        /^((\+54)?(11|\d{2})\d{6,8}|(\+54)?(2[01]|3[0-5]|6[0-9])\d{7})$/
                      ),
                      message:
                        "El Telefono es invalido. Por favor, asegurese de quitar el '0' y el '15'",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>Numero de DNI</Text>
                <Form.Item
                  name="dni"
                  rules={[
                    { required: true, message: "Por favor, ingrese su DNI" },
                    {
                      pattern: new RegExp(
                        /^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$/
                      ),
                      message: "El DNI es invalido",
                    },
                  ]}
                >
                  <Input className={"inputProfile"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>Fecha de Nacimiento</Text>
                <Form.Item
                  name="dateBirth"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecciona su fecha de nacimiento",
                    },
                    {
                      validator: fechaValida,
                      message: "La fecha es invalida",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={"Selecciona la fecha"}
                    className={"datepickProfile"}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>
                  Provincia de tu domicilio
                </Text>
                {loadingState ? (
                  <Form.Item
                    name="stateId"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecciona al menos una provincia",
                      },
                    ]}
                  >
                    <Select
                      disabled
                      placeholder="Cargando..."
                      className={"inputProfileLocation"}
                    ></Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="stateId"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecciona al menos una provincia",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      placeholder="Selecciona la provincia"
                      className={"inputProfileLocation"}
                      onChange={(e) => onSelect(e)}
                    >
                      {stateId
                        .filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex(
                              (t) => t.provinceCode === value.provinceCode
                            )
                        )
                        .sort(sortLocation)
                        .map((state) => {
                          return (
                            <Option value={state.provinceCode}>
                              {state.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <Text className={"inputTextUser"}>
                  Localidad de tu domicilio
                </Text>
                <Form.Item
                  name="cityId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecciona al menos una ciudad",
                    },
                  ]}
                >
                  {codeState === false ? (
                    <Select
                      disabled
                      className={"inputProfileLocation"}
                      placeholder={"Selecciona antes una provincia"}
                    ></Select>
                  ) : loadingCity ? (
                    <Select
                      className={"inputProfileLocation"}
                      placeholder="Cargando..."
                    ></Select>
                  ) : (
                    <Select
                      placeholder="Selecciona la ciudad"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      className={"inputProfileLocation"}
                    >
                      {cityId.locations
                        .filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex(
                              (t) => t.locationCode === value.locationCode
                            )
                        )
                        .sort(sortLocation)
                        .map((city) => {
                          return (
                            <Option value={city.locationCode}>
                              {city.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col style={{ marginTop: "1em" }} span={24}>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                      height: "47px",
                      float: "right",
                    }}
                  >
                    {t(Signin.registerForm.submitButton)}
                  </CustomButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Mobile>
      </>
    );
  };

  return (
    <>
      <Default>
        <BannerSignin />
        <div
          style={{
            padding: "0 90px",
            width: isBigScreen ? "1300px" : "100%",
            margin: "0 auto",
          }}
        >
          <Row style={{ alignItems: "center" }}>
            {id && (
              <Col pull={"left"}>
                <div
                  onClick={() => goToCheckout()}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowLeftOutlined />
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#006755",
                      fontWeight: "bold",
                    }}
                  >
                    {t(Signin.returnButtonText)}
                  </Text>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "20px" }}>
            <Button
              style={{
                borderRadius: "5px 0px 0px 5px",
                width: "182px",
                height: "58px",
                background: "#0000000D",
                color: "#909090",
                border: "#0000000D",
              }}
              type="primary"
              onClick={() => handleLogin()}
            >
              {t(Signin.loginButton)}
            </Button>
            <Button
              style={{
                borderRadius: "0px 5px 5px 0px",
                width: "182px",
                height: "58px",
                background: "#006755",
                border: "#006755",
              }}
              type="primary"
            >
              {t(Signin.registerButton)}
            </Button>
          </Row>
          <RegisterForm />
        </div>
        {openModal && (
          <ConfirmModal user={infoUser} loadingCreate={loadingCreate} />
        )}
      </Default>
      <Mobile>
        <BannerSignin />
        <div
          style={{
            padding: "0 7%",
            width: isBigScreen ? "1300px" : "100%",
            margin: "0 auto",
          }}
        >
          <Row style={{ alignItems: "center" }}>
            {id && (
              <Col pull={"left"}>
                <div
                  onClick={() => goToCheckout()}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowLeftOutlined />
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#006755",
                      fontWeight: "bold",
                    }}
                  >
                    {t(Signin.returnButtonText)}
                  </Text>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "20px" }}>
            <Button
              style={{
                borderRadius: "5px 0px 0px 5px",
                width: isExtraSmallScreen ? "152px" : "182px",
                height: "58px",
                background: "#0000000D",
                color: "#909090",
                border: "#0000000D",
              }}
              type="primary"
              onClick={() => handleLogin()}
            >
              {t(Signin.loginButton)}
            </Button>
            <Button
              style={{
                borderRadius: "0px 5px 5px 0px",
                width: isExtraSmallScreen ? "152px" : "182px",
                height: "58px",
                background: "#006755",
                border: "#006755",
              }}
              type="primary"
            >
              {t(Signin.registerButton)}
            </Button>
          </Row>
          <RegisterForm />
        </div>
        {openModal && (
          <ConfirmModal user={infoUser} loadingCreate={loadingCreate} />
        )}
      </Mobile>
    </>
  );
};
export default RegisterPage;
