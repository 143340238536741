import { Checkbox } from "antd";
import styled from "styled-components";

const ButtonContainer = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #209b84;
    border-color: #209b84;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #209b84;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #209b84;
  }
  .ant-checkbox-checked::after {
    border-color: #209b84;
  }
`;

export const CustomCheckbox = (props) => {
  return (
    <ButtonContainer>
      <Checkbox {...props}>{props.children}</Checkbox>
    </ButtonContainer>
  );
};
