import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Typography, Row, Collapse, Space, Spin,  } from "antd";
import { faqsTexts } from "../../i18n/locales/es/text";
import { apiFaqs } from "../../api";
import style from "./Faqs.module.scss";
import { useMediaQuery } from "react-responsive";
import { CustomButton } from "../CustomButton";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

export const Faqs = ({fq, bg}) => {
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const [faqsQuantity, setFaqsQuantity] = useState([]);

  useEffect(() => {
    apiFaqs
      .getAll()
      .then((response) => {
        setFaqs(response.data);
        fq ? setFaqsQuantity(fq) : setFaqsQuantity(response.data.length);
        console.log(fq);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Spinner = () => {
    return (
      <Space>
        <Row>
          <Col justify="center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Spin style={{ transform: "scale(1.5)" }} size="large" />
              <Title style={{ marginTop: "1.3em" }} level={3}>
                Cargando Preguntas Frecuentes
              </Title>
            </div>
          </Col>
        </Row>
      </Space>
    );
  };

  return (
    <div className={`${style.itemsContainer} ${bg && style.bgColor}`}>
      {loading && !faqs?.length ? (
        <Spinner />
      ) : (
        <>
          <Row justify="center">
            <Col style={{ marginTop: "2em" }}>
              <Text
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                {t(faqsTexts.title)}
              </Text>
              <Text
                style={{
                  marginLeft: "0.3em",
                  fontSize: "28px",
                  color: "#006755",
                  fontWeight: "bold",
                }}
              >
                {t(faqsTexts.colored)}
              </Text>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{ width: isSimilTablet ? "80%" : "90%" }}
          >
            <Col span={24} style={{ marginTop: "2em" }}>
              {faqs.map((f,i) => {
                return (
                    i < faqsQuantity && (<Collapse
                    className={style.collapseStyle}
                    expandIconPosition="right"
                    defaultActiveKey={i === 0 ? [f.faqKey] : []}
                  >
                    <Panel
                      key={f.faqKey}
                      className={style.panelStyle}
                      header={f.title}
                    >
                      <div dangerouslySetInnerHTML={{ __html: f.body }} />
                    </Panel>
                  </Collapse>)
                );
              })}
            </Col>
            {bg && (<Col className={style.cardContainerFooter}>
                <Link to="/faqs">
                  <CustomButton
                    style={{ margin: "50px 0" }}
                    block
                    type="primary"
                  >
                    Ver mas preguntas frecuentes
                  </CustomButton>
                </Link>
            </Col>)}
          </Row>
        </>
      )}
    </div>
  );
};
