import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Typography, Button, Tag, Image, Card } from "antd";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { cart } from "../../../i18n/locales/es/text";
import style from "./CartItems.module.scss";
import { apiCart } from "../../../api/cartItems";
import { useAuth } from "../../../common/auth/auth.context";
import { useCookies } from "react-cookie";
import TermsComponent from "../../../components/terms";
import Reaptcha from "reaptcha";
import { CustomButton } from "../../../components";

const { Text } = Typography;
const items = { ...cart };
const keyRecaptcha = "6LeEt04kAAAAAP3cMhTUI5svUBV3NDU2xzl8H7ua";

export const CartItems = ({ program, status, click }) => {
  const { t } = useTranslation();
  const { id, linkImage, name } = program;
  const history = useHistory();
  const recaptchaRef = React.createRef();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const [cookies] = useCookies(["programs"]);

  const handleDeleteClick = () => {
    click(id);
  };

  const handleClick = () => {
    if (status === 1) {
      return handleSeeTariffsOption();
    }

    if (status === 2) {
      return handleSubscribeOption();
    }

    redirectToNextview();
  };

  const handleSeeTariffsOption = () => {
    recaptchaRef.current.execute();
  };

  const handleSubscribeOption = async () => {
    if (await termsAccepted()) {
      return redirectToNextview();
    } else {
      showModalWithTerms();
    }
  };

  const termsAccepted = async () => {
    if (isAuthenticated) {
      return getTermsAcceptedFromDB();
    } else {
      return getTermsAcceptedFromCookies();
    }
  };

  const getTermsAcceptedFromDB = async () => {
    const { data } = await apiCart.getTermsAccepted(id);
    return data.termsAccepted;
  };

  const getTermsAcceptedFromCookies = () => {
    if (!cookies?.programs) return false;
    const currentProgram = cookies.programs.find(
      (program) => program.program.id === id
    );
    if (!currentProgram) return false;
    return currentProgram.termsAccepted;
  };

  const showModalWithTerms = () => {
    setTermsModalOpen(true);
  };

  const handleCaptchaResolved = async (value) => {
    redirectToNextview();
  };

  const redirectToNextview = () => {
    history.push(
      `${items.status[status - 1].link}/${id}/${
        items.status[status - 1].studentRecordButton
      }`,
      {
        name: name,
      }
    );
  };

  return (
    <Row className={style.itemContainer}>
      {status > 2 ? (
        <Button className={style.itemDelete} />
      ) : (
        <Button
          className={style.itemDelete}
          icon={<IoMdClose />}
          onClick={handleDeleteClick}
        />
      )}
      <Image className={style.itemImage} src={linkImage} preview={false} />
      <Col className={style.itemNameAndFolder}>
        <Link to={`/product/${id}`}>
          <Text className={style.itemName}>{t(name)}</Text>
        </Link>
        {items.status[status - 1].tag && (
          <Tag className={style.itemFolder}>
            {t(items.status[status - 1].tag)}
          </Tag>
        )}
      </Col>
      <>
        <Button className={style.itemCta} onClick={handleClick}>
          {t(items.status[status - 1].button)}
        </Button>
        <TermsComponent
          id={id}
          isVisible={termsModalOpen}
          setIsVisible={setTermsModalOpen}
        />
        <Reaptcha
          ref={recaptchaRef}
          sitekey={keyRecaptcha}
          onVerify={handleCaptchaResolved}
          size="invisible"
        />
      </>
    </Row>
  );
};

export const CartItemsMobile = ({ program, status, click }) => {
  const { t } = useTranslation();
  const { id, linkImage, name } = program;
  const history = useHistory();
  const recaptchaRef = React.createRef();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const [cookies] = useCookies(["programs"]);

  const handleDeleteClick = () => {
    click(id);
  };

  const handleClick = () => {
    if (status === 1) {
      return handleSeeTariffsOption();
    }

    if (status === 2) {
      return handleSubscribeOption();
    }

    redirectToNextview();
  };

  const handleSeeTariffsOption = () => {
    recaptchaRef.current.execute();
  };

  const handleSubscribeOption = async () => {
    if (await termsAccepted()) {
      return redirectToNextview();
    } else {
      showModalWithTerms();
    }
  };

  const termsAccepted = async () => {
    if (isAuthenticated) {
      return getTermsAcceptedFromDB();
    } else {
      return getTermsAcceptedFromCookies();
    }
  };

  const getTermsAcceptedFromDB = async () => {
    const { data } = await apiCart.getTermsAccepted(id);
    return data.termsAccepted;
  };

  const getTermsAcceptedFromCookies = () => {
    if (!cookies?.programs) return false;
    const currentProgram = cookies.programs.find(
      (program) => program.program.id === id
    );
    if (!currentProgram) return false;
    return currentProgram.termsAccepted;
  };

  const showModalWithTerms = () => {
    setTermsModalOpen(true);
  };

  const handleCaptchaResolved = async (value) => {
    redirectToNextview();
  };

  const redirectToNextview = () => {
    history.push(
      `${items.status[status - 1].link}/${id}/${
        items.status[status - 1].studentRecordButton
      }`,
      {
        name: name,
      }
    );
  };

  return (
    <Card className={style.itemContainerMobile}>
      <Row>
        <Row
          style={{
            width: "100%",
            borderBottom: "1px solid #ced4da",
            paddingBottom: "1em",
            marginBottom: "1.5em",
          }}
        >
          <Col span={12}>
            {status > 2 ? (
              <Button className={style.itemDelete} />
            ) : (
              <Button
                className={style.itemDelete}
                icon={<IoMdClose style={{ fontSize: "25px" }} />}
                onClick={handleDeleteClick}
              />
            )}
          </Col>
          <Col span={12}>
            {items.status[status - 1].tag && (
              <Tag className={style.itemFolder}>
                {t(items.status[status - 1].tag)}
              </Tag>
            )}
          </Col>
        </Row>
        <Col span={24}>
          <div className={style.itemContImage}>
            <Image
              className={style.itemImage}
              src={linkImage}
              preview={false}
            />
          </div>
        </Col>

        <Col span={24} className={style.itemNameAndFolder}>
          <Link to={`/product/${id}`}>
            <Text className={style.itemName}>{t(name)}</Text>
          </Link>
        </Col>
        <>
          <Col span={24}>
            <CustomButton block type="primary" onClick={handleClick}>
              {t(items.status[status - 1].button)}
            </CustomButton>
          </Col>
          <TermsComponent
            id={id}
            isVisible={termsModalOpen}
            setIsVisible={setTermsModalOpen}
          />
          <Reaptcha
            ref={recaptchaRef}
            sitekey={keyRecaptcha}
            onVerify={handleCaptchaResolved}
            size="invisible"
          />
        </>
      </Row>
    </Card>
  );
};
