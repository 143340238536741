import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../../../components/header";
import { Courses } from "../../../../i18n/locales/es/text";
import i from "../../../../assets/coursesBg.png";
import style from "../../index.module.scss";
import "../../courses.scss";

const { Text } = Typography;

const CoursesHeader = () => {
  const { t } = useTranslation();

  return (
    <HeaderComponent left="-60em" imgBg={i}>
      <Text className={style.title}>{t(Courses.title)}</Text>
      <br />
      <Text className={style.map}>{t(Courses.titleMap)}</Text>
    </HeaderComponent>
  );
};

export default React.memo(CoursesHeader);
