import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Home } from "../../i18n/locales/es/text";
import { Carousel, Row, Col, Typography } from "antd";

import style from "./Carousel.module.scss";
import { apiPrograms } from "../../api";
import { AbstractCardCarousel } from "../cards";
import { useCookies } from "react-cookie";

const { Title, Text } = Typography;

export const ListCarousel = ({ q, text = true, bgColor }) => {
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(["viewedStudyFields"]);
  const [muestra, setMuestra] = useState(null);

  useEffect(() => {
    if (cookies.viewedStudyFields === undefined) {
      apiPrograms
        .getProgramsAll(32)
        .then((response) => {
          setMuestra(response.data.programs);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (cookies.viewedStudyFields) {
      apiPrograms
        .getProgramsAll(32)
        .then((response) => {
          const idCorrect = response.data.programs.filter((program) => {
            return cookies.viewedStudyFields.includes(program.studyField.id);
          });
          const idGeneral = response.data.programs.filter((program) => {
            return !cookies.viewedStudyFields.includes(program.studyField.id);
          });
          setMuestra([...idCorrect, ...idGeneral]);
          setLoading(false);
        })

        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  let texts = { ...Home.Carousel };

  return (
    <Row justify="center">
      <Col style={{ background: bgColor }} className={style.interestsContainer}>
        {text ? (
          <Title level={3} className={style.title}>
            {t(texts.may)}{" "}
            <Text className={style.interest}>{t(texts.interestYou)}</Text>
          </Title>
        ) : null}
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <Carousel className={style.carousel} dots={{ className: style.dots }}>
            {muestra.length >= 0 && (
              <div>
                <div className={style.contentStyleCard}>
                  {muestra.slice(0, q).map((program) => {
                    return (
                      <AbstractCardCarousel key={program.id} {...program} />
                    );
                  })}
                </div>
              </div>
            )}

            {muestra.length > q && (
              <div>
                <div className={style.contentStyleCard}>
                  {muestra.slice(q, q * 2).map((program) => {
                    return (
                      <AbstractCardCarousel key={program.id} {...program} />
                    );
                  })}
                </div>
              </div>
            )}

            {muestra.length > q * 2 && (
              <div>
                <div className={style.contentStyleCard}>
                  {muestra.slice(q * 2, q * 3).map((program) => {
                    return (
                      <AbstractCardCarousel key={program.id} {...program} />
                    );
                  })}
                </div>
              </div>
            )}
            {muestra.length > q * 3 && (
              <div>
                <div className={style.contentStyleCard}>
                  {muestra.slice(q * 3, q * 4).map((program) => {
                    return (
                      <AbstractCardCarousel key={program.id} {...program} />
                    );
                  })}
                </div>
              </div>
            )}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};
