import { Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { CustomInputSearch } from "./CustomInputSearch";
import React from "react";
import { useHistory } from "react-router-dom";
import { Home } from "../../i18n/locales/es/text";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;

export const SearchInput = ({ setshowsearch }) => {
  const { t } = useTranslation();
  let history = useHistory();

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1224 })
    return isNotMobile ? children : null
  }
  
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1223 })
    return isMobile ? children : null
  }

  const onSearch = (e) => {
    history.push({
      pathname: "/courses",
      search: "?" + new URLSearchParams({ name: e }).toString(),
    });
    setshowsearch(false);
  };

  const onSelected = (_,product) => {
    if(product) {
      history.push({
        pathname: "/product/" + product.id
      });
      setshowsearch(false);
    }
  };

  const handleKeyPress = (event) => {
    if(event.code === 'Escape') setshowsearch(false);
  };

  return (
    <>
      <Default>
        <Col span={8}>
          <Title style={{ color: "white", textAlign: "center" }} level={3}>
            {t(Home.Header.getsearch)}
          </Title>
          <CustomInputSearch
            placeholder={t(Home.Header.searchTitle)}
            allowClear
            enterButton={t(Home.Header.searchBtn)}
            size="large"
            onSearch={onSearch}
            onSelect={onSelected}
            onKeyDown={handleKeyPress}
          />
        </Col>
      </Default>
      <Mobile>
        <Col span={18}>
          <Title style={{ color: "white", textAlign: "center" }} level={3}>
            {t(Home.Header.getsearch)}
          </Title>
          <CustomInputSearch
            placeholder={t(Home.Header.searchTitle)}
            allowClear
            enterButton={t(Home.Header.searchBtn)}
            size="large"
            onSearch={onSearch}
          />
        </Col>
      </Mobile>
    </>
  );
};
