import React, { useEffect, useState } from "react";
import { Card, Typography, Row, Col, Skeleton, Divider, Tooltip } from "antd";
import { CalendarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";

import { Checkout } from "../../../../i18n/locales/es/text";
import { CustomButton } from "../../../../components/CustomButton";
import { useAuth } from "../../../../common/auth/auth.context";
import { apiEnrollment } from "../../../../api/enrollment";

import "./inscription-detail-card.scss";
import "../../checkout.scss";
import { getErrorMessage } from "../../../../api/api";
import {
  NOTIFICATION_TYPES,
  showNotification,
} from "../../../../common/notifications";
import { useCookies } from "react-cookie";
import { priceHelper } from "../../../../helpers/priceHelper";
import { apiCart } from "../../../../api/cartItems";
import { useDispatch } from "react-redux";
import { setCartItemsLenght } from "../../../../redux/cart/action";
import { showMessageAddedToCart } from "../messageCheckout";
import TermsComponent from "../../../../components/terms";
import { useMediaQuery } from "react-responsive";

const { Text } = Typography;

export const InscriptionDetailCard = ({
  program,
  selectedAttendance,
  selectedCau,
  selectedTurn,
  priceData,
  setPriceData,
  flexCourses,
  isFlex,
  coupon
}) => {
  const { isAuthenticated } = useAuth();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [cookies, setCookies, removeCookie] = useCookies(["programs"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const { id } = useParams();
  const { t } = useTranslation();

  const selectedCreditCardMethod = false;

  useEffect(() => {
    if (!program || !selectedTurn || !selectedAttendance || !selectedCau) {
      setPriceData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program, selectedAttendance, selectedCau, selectedTurn]);

  useEffect(() => {
    if (program && selectedTurn && selectedAttendance && selectedCau) {
      setIsFetching(true);
      apiEnrollment
        .getPrices(
          program.idCareer,
          selectedCau.idCau,
          selectedCau.strapiCode,
          selectedAttendance.idAcademicOffer,
          selectedTurn.id,
          selectedTurn.idCourseMode,
          null,
          coupon || null
        )
        .then((response) => {
          setIsFetching(false);
          setPriceData(response.data);
          addToSimulated(response.data.simulationData);
        })
        .catch((error) => {
          showNotification(NOTIFICATION_TYPES.ERROR, getErrorMessage(error));
        });
    } else if (isFlex && program && flexCourses) {
      setIsFetching(true);
      apiEnrollment
        .getPrices(
          program.idCareer,
          flexCourses.idCAU,
          flexCourses.cauStrapiCode,
          flexCourses.idAttendanceMode,
          flexCourses.idCourseModeDB,
          flexCourses.idCourseMode,
          null,
          coupon || null
        )
        .then((response) => {
          setIsFetching(false);
          setPriceData(response.data);
          addToSimulated(response.data.simulationData);
        })
        .catch((error) => {
          setIsFetching(false);
          showNotification(NOTIFICATION_TYPES.ERROR, Checkout.inscriptionDetailCard.flexMessageError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurn, isFlex, program, flexCourses, coupon]);

  //Agregar entidad enrollment
  const addToSimulated = async (enrollmentData) => {
    const data = {
      program: {
        id: program.id,
        linkImage1: program.linkImage1 || program.linkImage2,
        name: program.name,
      },
      status: 2,
      enrollment: enrollmentData || null,
      termsAccepted: false,
    };

    if (isAuthenticated) {
      let { data: cartPrograms } = await apiCart.getItems();
      let programIndex;
      if(cartPrograms)
        programIndex = cartPrograms.findIndex((cp) => cp.program.id === id);

      await apiCart.sendSimulationsToDB([
        {
          programId: id,
          enrollment: enrollmentData,
          status: 2,
        },
      ]);

      const savedItems = await apiCart.getItems();
      const { data: dataFetch } = savedItems;
      dispatch(setCartItemsLenght(dataFetch.length));
      programIndex < 0 && showMessageAddedToCart();
    } else {
      if (cookies.programs) {
        let cookiePrograms = [...cookies.programs];
        let programIndex = cookiePrograms.findIndex(
          (cp) => cp.program.id === id
        );
        if (programIndex >= 0) {
          data.termsAccepted = cookiePrograms[programIndex]?.termsAccepted;
          cookiePrograms.splice(programIndex, 1, data);
        } else {
          cookiePrograms.push(data);
          showMessageAddedToCart();
        }
        removeCookie("programs");
        setCookies("programs", cookiePrograms, { path: "/" });
        dispatch(setCartItemsLenght(cookiePrograms.length));
      } else {
        removeCookie("programs");
        setCookies("programs", [data], { path: "/" });
        dispatch(setCartItemsLenght(data.length));
        showMessageAddedToCart();
      }
    }
  };

  const handleInscription = async () => {
    if (await termsAccepted()) {
      if (isAuthenticated) {
        return history.push(`/completeInscription/${id}`);
      } else {
        return history.push(`/register/${id}`);
      }
    } else {
      showModalWithTerms();
    }
  };

  const termsAccepted = async () => {
    if (isAuthenticated) {
      return getTermsAcceptedFromDB();
    } else {
      return getTermsAcceptedFromCookies();
    }
  };

  const getTermsAcceptedFromDB = async () => {
    const { data } = await apiCart.getTermsAccepted(id);
    return data.termsAccepted;
  };

  const getTermsAcceptedFromCookies = () => {
    if (!cookies?.programs) return false;
    const currentProgram = cookies.programs.find(
      (program) => program.program.id === id
    );
    if (!currentProgram) return false;
    return currentProgram.termsAccepted;
  };

  const showModalWithTerms = () => {
    setTermsModalOpen(true);
  };

  const { name: programName } = program;

  const handleFormWizard = () => {
    return priceData && !isFetching ? (
      priceData.tickets.map((ticket, index) => (
        <Card className="subcard" key={ticket?.id}>
          <Row>
            <Col span={12}>
              <Text className="subcardTitle">{ticket.description}</Text>
            </Col>
            <Col span={12}>
              <Text className="price">{priceHelper(ticket.listPrice)}</Text>
            </Col>
          </Row>
          <Row className="textMargin">
            {ticket.discounts.map((discount) => (
              <>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text style={{ fontSize: "14px" }}>
                    <ul>
                      <li>{discount.name}</li>
                    </ul>
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text style={{ fontSize: "14px" }}>
                    {`- ${priceHelper(discount.amount)}`}
                  </Text>
                </Col>
              </>
            ))}
            {ticket.scolarships ? (
              ticket.scolarships.map((scolarship) => (
                <>
                  <Col span={12} style={{ textAlign: "left" }}>
                    <Text style={{ fontSize: "14px" }}>
                      <ul>
                        <li>{scolarship.name}</li>
                      </ul>
                    </Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text style={{ fontSize: "14px" }}>
                      {`- $ ${scolarship.amount}`}
                    </Text>
                  </Col>
                </>
              ))
            ) : (
              <></>
            )}
          </Row>
          <Divider></Divider>
          <Row className="textMargin">
            <Col span={12}>
              <Text className="textCardBold">
                {`Total ${ticket.description}`}
              </Text>
            </Col>
            <Col span={12}>
              <Text className="price">{priceHelper(ticket.price)}</Text>
            </Col>
          </Row>
        </Card>
      ))
    ) : isFetching ? (
      <>
        <Skeleton active />
      </>
    ) : null;
  };

  return (
    <Card className="checkout-card">
      <Row>
        <Text className="titleCard">
          {t(Checkout.inscriptionDetailCard.title)}
        </Text>
      </Row>
      <Row>
        <Text className="subtitle">
          {t(Checkout.inscriptionDetailCard.careerDesc)}
        </Text>
      </Row>
      <Row>
        <Text className="careerTitle">{programName}</Text>
      </Row>
      <Row align="middle" style={{ margin: "10px 0" }}>
        <CalendarOutlined style={{ color: "#00A98F", fontSize: "15pt" }} />
        <Text
          className="careerTitleDate"
          style={{ marginLeft: "5px", color: "black", marginTop: "0px" }}
        >
          Fecha de inicio:
        </Text>
        <Text className="careerTitleDate" style={{ marginTop: "0px" }}>
          {priceData ? moment(priceData.startDate).format("DD/MM/YYYY") : "-"}
        </Text>
      </Row>
      <Row>
        <Text className="subtitle">
          {t(Checkout.inscriptionDetailCard.desglosePrice)}
        </Text>
      </Row>
      <Row>
        <Text className="generalMargin">
          • {t(Checkout.inscriptionDetailCard.factor1)}
        </Text>
        <Text className="generalMargin">
          • {t(Checkout.inscriptionDetailCard.factor2)}
          <Link
            to={{
              pathname: "https://contenidos.21.edu.ar/microsites/reglamento/index.php?put=4-1-normas-arancelarias",
            }}
            target="_blank"
            style={{
              textDecoration: "underline",
              color: "#00A98F",
              marginLeft: "4px",
            }}
          >
            {t(Checkout.inscriptionDetailCard.factorLink)}
          </Link>
        </Text>
      </Row>
      {handleFormWizard()}
      <Row className="generalMargin">
        <Col span={12}>
          <Text
            style={{
              opacity: 1,
              color: "#000000",
              fontSize: "18px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {t(Checkout.inscriptionDetailCard.total)}
          </Text>
        </Col>
        <Col span={12}>
          <Text
            style={{
              opacity: 1,
              color: "#000000",
              fontSize: "18px",
              float: "right",
              fontWeight: "bold",
            }}
          >
            {priceData ? priceHelper(priceData.total) : "$" + 0}
          </Text>
        </Col>
      </Row>
      {selectedCreditCardMethod && (
        <Row className="generalMargin">
          <Col span={12}>
            <Text
              style={{
                opacity: 1,
                color: "#000000",
                fontSize: "18px",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {t(Checkout.inscriptionDetailCard.share)}
            </Text>
          </Col>
          <Col span={12}>
            <Text
              style={{
                opacity: 1,
                color: "#000000",
                fontSize: "18px",
                float: "right",
                fontWeight: "bold",
              }}
            >
              6 {t(Checkout.inscriptionDetailCard.shareAccount)} $3.333
            </Text>
          </Col>
        </Row>
      )}

      <CustomButton
        onClick={() => handleInscription()}
        disabled={isFetching || !priceData}
        type="primary"
        style={{ marginTop: "50px" }}
      >
        <Text className="inscriptionButtonText">
          {t(Checkout.inscriptionDetailCard.inscription)}
        </Text>
      </CustomButton>
      <Tooltip
        color={"#fff"}
        placement="bottom"
        title={
          <div className="content-tooltip-pay-method">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "0.5em 0",
              }}
            >
              <div className="dot-enum-method-pay"></div>
              <Text style={{ fontWeight: "normal", fontSize: "16px" }}>
                Tarjeta de crédito en cuotas
              </Text>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "0.5em 0",
              }}
            >
              <div className="dot-enum-method-pay"></div>
              <Text style={{ fontWeight: "normal", fontSize: "16px" }}>
                Tarjeta de débito en un pago
              </Text>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "0.5em 0",
              }}
            >
              <div className="dot-enum-method-pay"></div>
              <Text style={{ fontWeight: "normal", fontSize: "16px" }}>
                Efectivo en un pago
              </Text>
            </div>
          </div>
        }
      >
        <div className="inscription-pay-method">
          <Text
            className
            style={{
              color: "#006755",
              fontSize: isSimilTablet ? "18px" : "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ textDecoration: "underline ", marginRight: "5px" }}>
              Ver métodos de pago
            </span>
            <InfoCircleOutlined />
          </Text>
        </div>
      </Tooltip>
      <TermsComponent
        id={id}
        isVisible={termsModalOpen}
        setIsVisible={setTermsModalOpen}
      />
    </Card>
  );
};
export default InscriptionDetailCard;
