import React, { useEffect, useState } from "react";
import { Badge, Col, Drawer, Image, Layout, Pagination, Row, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { AbstractCard, CustomButton } from "../../components";
import { useLocation } from "react-router-dom";
import { apiPrograms } from "../../api";
import { useMediaQuery } from "react-responsive";
import filter from "../../assets/filter.svg";
import style from "./index.module.scss";
import "./courses.scss";
import CoursesHeader from "./components/header";
import CoursesOrder from "./components/order";
import CoursesNoResult from "./components/no-results";
import CoursesSidebar, { CoursesSidebarMobile } from "./components/sidebar";

const { Content } = Layout;

export const COURSES_PAGE_SIZE = 6;

const CoursesPage = () => {
  const [page, setPage] = useState(1);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1544px)" });
  const isSimilTablet = useMediaQuery({
    query: "(min-width: 1030px)",
  });
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(true);
  const [searchResult, setSearchResult] = useState();
  const [result, setResult] = useState();
  const [programsCount, setProgramsCount] = useState(0);
  const [vis, setVis] = useState(false);
  const [programsFilteredByNameCount, setProgramsFilteredByNameCount] =
    useState(0);

  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const nameSearch = query.get("name");
  const filterProgramType = query.get("program-type");
  const filterArea = query.get("study-area");
  const filterModality = query.get("modality");

  const [apt, setApt] = useState(filterProgramType || null);
  const [fieldStudy, setFieldStudy] = useState(filterArea || null);
  const [modalities, setModalities] = useState(filterModality || null);

  const onChangePage = (pageState) => {
    setPage(pageState);
  };

  useEffect(() => {
    window.scrollTo(0, 450);
  }, [page]);

  useEffect(() => {
    apiPrograms
      .getProgramsFilter(apt, modalities, fieldStudy, page, COURSES_PAGE_SIZE)
      .then((response) => {
        setResult(response.data.programs);
        setProgramsCount(response.data.itemCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apt, modalities, fieldStudy, page]);

  useEffect(() => {
    if (nameSearch !== null) {
      apiPrograms
        .getProgramsName(nameSearch, page, COURSES_PAGE_SIZE)
        .then((response) => {
          setSearchResult(response.data.programs);
          setProgramsFilteredByNameCount(response.data.itemCount);
          setLoadingSearch(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [nameSearch, page]);

  return (
    <>
      <CoursesHeader />
      <div
        style={
          isSimilTablet
            ? {
                padding: isBigScreen ? null : "0 90px",
                width: isBigScreen ? "1400px" : "100%",
                margin: "auto",
              }
            : {
                padding: "0 2.2em",
                width: isBigScreen ? "1400px" : "100%",
                margin: "auto",
              }
        }
      >
        {isSimilTablet ? (
          <CoursesOrder
            resultSetCount={
              nameSearch === null ? programsCount : programsFilteredByNameCount
            }
            loading={nameSearch === null ? loading : loadingSearch}
          />
        ) : (
          <CustomButton
            onClick={() => setVis(true)}
            style={{ width: "50px", height: "50px", marginTop: "2.2em" }}
            type="link"
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Image style={{ width: "30px" }} preview={false} src={filter} />
            </div>
          </CustomButton>
        )}
      </div>

      <Layout
        style={
          isSimilTablet
            ? {
                padding: isBigScreen ? null : "0 90px",
                width: isBigScreen ? "1400px" : "100%",
                margin: isSimilTablet ? "50px auto" : "10px auto",
              }
            : {
                padding: isBigScreen ? null : "0 2em",
                width: isBigScreen ? "1400px" : "100%",
                margin: isSimilTablet ? "50px auto" : "10px auto",
              }
        }
        hasSider={true}
        className={style.collapseContainer}
      >
        {nameSearch === null ? (
          <>
            {isSimilTablet ? (
              <Col flex="220px">
                <CoursesSidebar
                  preSelectedProgramType={filterProgramType}
                  preSelectedStudyField={filterArea}
                  preSelectedModality={filterModality}
                  setApt={setApt}
                  setModalities={setModalities}
                  setFieldStudy={setFieldStudy}
                />
              </Col>
            ) : (
              <Drawer
                onClose={() => setVis(false)}
                placement={"left"}
                width={300}
                visible={vis}
              >
                <div style={{ marginTop: "2em" }}>
                  <CoursesSidebarMobile
                    preSelectedProgramType={filterProgramType}
                    preSelectedStudyField={filterArea}
                    preSelectedModality={filterModality}
                    setApt={setApt}
                    setModalities={setModalities}
                    setFieldStudy={setFieldStudy}
                  />
                </div>
              </Drawer>
            )}

            {loading ? (
              <Spin />
            ) : result.length > 0 ? (
              <Col flex="auto">
                <Content className={style.cardsContainer}>
                  {result.map((data) => {
                    return <AbstractCard key={data.id} {...data} />;
                  })}
                </Content>

                <Row justify="center" className={style.pagination}>
                  <Pagination
                    current={page}
                    onChange={onChangePage}
                    total={programsCount}
                    pageSize={COURSES_PAGE_SIZE}
                    itemRender={(number, type, originalElement) => (
                      <>
                        {type === "page" ? (
                          <>
                            {number === page ? (
                              <Badge
                                count={number}
                                style={{ background: "#006755" }}
                              />
                            ) : (
                              number
                            )}
                          </>
                        ) : (
                          <>
                            {type === "prev" ? (
                              <LeftOutlined />
                            ) : (
                              type === "next" && <RightOutlined />
                            )}
                          </>
                        )}
                      </>
                    )}
                  />
                </Row>
              </Col>
            ) : (
              <CoursesNoResult />
            )}
          </>
        ) : (
          <>
            {loadingSearch ? (
              <Spin />
            ) : searchResult.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: isBigScreen ? "1200px" : "100%" }}>
                  <Content
                    style={{
                      display: "grid",
                      gridTemplateColumns: isSimilTablet && `repeat(3, 1fr)`,
                      columnGap: "10px",
                      rowGap: "10px !important",
                    }}
                  >
                    {searchResult.map((data) => {
                      return <AbstractCard key={data.id} {...data} />;
                    })}
                  </Content>
                  <Row justify="center" className={style.pagination}>
                    <Pagination
                      current={page}
                      onChange={onChangePage}
                      total={programsFilteredByNameCount}
                      pageSize={COURSES_PAGE_SIZE}
                      itemRender={(number, type, originalElement) => (
                        <>
                          {type === "page" ? (
                            <>
                              {number === page ? (
                                <Badge
                                  count={number}
                                  style={{ background: "#006755" }}
                                />
                              ) : (
                                number
                              )}
                            </>
                          ) : (
                            <>
                              {type === "prev" ? (
                                <LeftOutlined />
                              ) : (
                                type === "next" && <RightOutlined />
                              )}
                            </>
                          )}
                        </>
                      )}
                    />
                  </Row>
                </div>
              </div>
            ) : (
              <CoursesNoResult />
            )}
          </>
        )}
      </Layout>
    </>
  );
};

export default CoursesPage;
