import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";
import { message } from "antd";

const apiUrl = process.env.REACT_APP_BASE_URL_BACKEND;

const AuthContext = React.createContext();

const REDIRECT_TYPE = "opaqueredirect";

export function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, , removeCookie] = useCookies();

  const login = () => {
    fetch(`${apiUrl}/auth`, {
      redirect: "manual",
    }).then((res) => {
      if (res.type === REDIRECT_TYPE) {
        window.location.href = res.url;
      } else {
        return res;
      }
    });
  };

  useEffect(() => {
    let timer;

    try {
      if (cookies["accessToken"] && !isExpired()) {
        const accessToken = cookies["accessToken"];
        const decodedToken = jwtDecode(accessToken);

        const now = new Date().getTime();
        const timeout = decodedToken.expires - now;

        if (timeout > 0) {
          timer = setTimeout(() => {
            logout();
            message.error("La sesión expiró", 2, () => {
              console.log("error x timeout");
              window.location = "/";
            });
          }, timeout);
        } else {
          logout();
        }
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.log("Invalid Token");
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const getUser = () => {
    const accessToken = cookies["accessToken"];
    if (!accessToken) return {};

    const user = jwtDecode(accessToken);
    return user;
  };

  const logout = () => {
    removeCookie("accessToken");
  };

  const isExpired = () => {
    const decodedToken = getUser();

    if (!decodedToken.expires) {
      return true;
    }

    const expiresDateTimestamp = decodedToken.expires;
    const expiresDate = new Date(parseInt(expiresDateTimestamp));
    const currentDate = new Date();

    return expiresDate < currentDate;
  };

  const value = {
    isAuthenticated,
    login,
    logout,
    getUser,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be inside AuthContext Provider");
  }

  return context;
}
