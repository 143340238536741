import api from './api';

const prefix = 'faqs';

export const apiFaqs = {
  getAll: function () {
    return api.get(
      `${prefix}`
    );
  },
};
