import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import CartReducer from "../cart/reducer";
import { searchReducer } from "../search/searchReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    Cart: CartReducer,
    search: searchReducer,
  });

export default createRootReducer;
