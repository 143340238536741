import api from "./api";

const prefix = "payment";

export const apiPayment = {
  getPaymentLinks: (username, studentRecord) => {
    return api.get(`${prefix}`,{
        params: {
            username,
            studentRecord
        }
    });
  },
}
