export const priceHelper = (
  number,
  type = "es-AR",
  currency = "ARS",
  useGrouping = true,
  maxDigit = 3
) => {
  const formatting = new Intl.NumberFormat(type, {
    style: "currency",
    currency: currency,
    useGrouping: useGrouping,
  });

  return formatting.format(number);
};
