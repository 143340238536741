import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  .ant-btn-primary {
    font-size: 14px;
    background-color: #006755;
    border-color: #006755;
    height: 40px;
    width: 100%;
    border-radius: 5px;
  }

  .ant-btn-text {
    font-size: 14px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
  }

  .ant-btn-primary[disabled] {
    font-size: 14px;
    background-color: #ced4da;
    color: #b5b5b5;
    height: 40px;
    width: 100%;
    border-color: #ced4da;
    border-radius: 5px;
  }

  .ant-btn-link {
    font-size: 14px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border-color: #006755;
    color: #006755;
  }

  .ant-btn-link[disabled] {
    font-size: 14px;
    background-color: #ced4da;
    color: #b5b5b5;
    height: 40px;
    width: 100%;
    border-color: #ced4da;
    border-radius: 5px;
  }
`;
export const CustomButton = (props) => {
  return (
    <ButtonContainer>
      <Button {...props}>{props.children}</Button>
    </ButtonContainer>
  );
};
