import api from './api';

const prefix = 'flex_courses';

export const apiFlex = {
  get: function () {
    return api.get(
      `${prefix}`
    );
  }
};
