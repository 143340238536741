import { Pagination } from "antd";
import styled from "styled-components";

const CustomContainer = styled.div`
  .ant-pagination-item-active {
    background-color: #209b84;
    border-color: #209b84;
    border-radius: 50%;
  }

  .ant-pagination-item-active a {
    color: #fff;
  }

  .ant-pagination-item-active a {
    color: #fff !important;
  }

  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: #209b84;
  }
`;

export const CustomPaginator = (props) => {
  return (
    <CustomContainer>
      <Pagination {...props} />
    </CustomContainer>
  );
};
